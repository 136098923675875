import React from "react";
import FormInput from "../Common/FormInput";
import VehicleInfoSection from "../Common/Sections/VehicleInfoSection";
import FormSelectInput from "../Common/FormSelectInput";
const LocksmithServiceSection = ({ formik, serviceName }) => {
  return (
    <>
      <div className="flex flex-col lg:flex-row gap-4 mt-2">
        <div className="w-full bg-neutral-50">
          <div className="flex flex-col p-2">
            <h6 className="font-semibold w-full px-1 py-0 text-base">
              What type of Locksmith Service?
            </h6>
            <div className="grid grid-cols-3 gap-x-3">
              <div className="flex items-center space-x-4 px-2 py-2 col-span-">
                <label className="flex items-center cursor-pointer">
                  <input
                    id="extra_fields.locksmith_type"
                    type="radio"
                    name="locksmith_type"
                    value="car_locksmith"
                    className="mr-2 cursor-pointer"
                    checked={
                      formik?.values?.extra_fields?.locksmith_type ===
                      "car_locksmith"
                    }
                    onChange={(e) =>
                      formik?.setFieldValue(
                        "extra_fields.locksmith_type",
                        e.target.value
                      )
                    }
                  />
                  Car Locksmith
                </label>
                <label className="flex items-center cursor-pointer">
                  <input
                    id="extra_fields.locksmith_type"
                    type="radio"
                    name="locksmith_type"
                    value="residential_locksmith"
                    className="mr-2 cursor-pointer"
                    checked={
                      formik?.values?.extra_fields?.locksmith_type ===
                      "residential_locksmith"
                    }
                    onChange={(e) =>
                      formik?.setFieldValue(
                        "extra_fields.locksmith_type",
                        e.target.value
                      )
                    }
                  />
                  Residential
                </label>
                <label className="flex items-center cursor-pointer">
                  <input
                    id="extra_fields.locksmith_type"
                    type="radio"
                    name="locksmith_type"
                    value="other_locksmith"
                    className="mr-2 cursor-pointer"
                    checked={
                      formik?.values?.extra_fields?.locksmith_type ===
                      "other_locksmith"
                    }
                    onChange={(e) =>
                      formik?.setFieldValue(
                        "extra_fields.locksmith_type",
                        e.target.value
                      )
                    }
                  />
                  Others
                </label>
              </div>
              <FormInput
                name="extra_fields.locksmith_specify"
                type="text"
                placeholder="Specify"
                formik={formik}
                value={formik.values?.extra_fields?.locksmith_specify}
                label="Specify"
              />
              <FormSelectInput
                name="extra_fields.has_proof"
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                convertor={(value) => (value ? value === "true" : "")}
                formik={formik}
                valueProp="value"
                labelProp="label"
                label="Has proof of ownership?"
                isCustomOption
                value={formik.values?.extra_fields?.has_proof}
              />
            </div>
          </div>
        </div>
      </div>
      {formik?.values?.extra_fields?.locksmith_type === "car_locksmith" ? (
        <VehicleInfoSection
          formik={formik}
          fieldsToShow={
            formik?.values?.extra_fields?.locksmith_type === "car_locksmith"
              ? ["car_year", "car_make", "car_model", "is_neutral", "car_color"]
              : []
          }
          serviceName={serviceName}
        />
      ) : null}
    </>
  );
};

export default LocksmithServiceSection;
