import React from "react";
import { useDispatch } from "react-redux";
import {
  get_conversation,
  markAsReadMsg,
  send_message,
} from "../../../features/smsCenterSlice";
import Button from "../../atoms/Button";

function CustomMsgModal({
  onClosemodal,
  newMsg,
  setNewMsg,
  setUserType,
  setUserId,
}) {
  const dispatch = useDispatch();
  const [msg, setMsg] = React.useState();
  React.useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClosemodal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [customPhone, setCustomPhone] = React.useState();
  const handleMessage = async (event) => {
    event.preventDefault();
    try {
      const res = await dispatch(
        send_message({
          message: msg,
          custom_phone: customPhone,
          user_type: "Custom",
        })
      );
      if (res.payload?.status === 201) {
        setMsg("");
        if (newMsg) {
          dispatch(
            get_conversation({ custom_phone: customPhone, user_type: "Custom" })
          );
          setUserId(res.payload.data);
          setNewMsg(false);
          setUserType("");
        }
        onClosemodal();
        dispatch(markAsReadMsg());
      }
    } catch (err) {}
  };
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
      <div className="container-fluid mt-2 text-center ">
        <div className="inline-block bg-white w-2/4 my-0 mx-auto p-5 bg-red-600 rounded  max-h-[87vh]">
          <form
            onSubmit={handleMessage}
            className="flex flex-col justify-between w-full bottom-0 bg-[#f8f8f8] p-3.7 float-right "
          >
            <input
              className="border w-full py-1.5 !px-3 !mb-2 rounded !focus:outline-none"
              onChange={(e) => setCustomPhone(e.target.value)}
              value={customPhone}
              placeholder="Enter Number"
            />
            <textarea
              className="border w-full py-1.5 !px-3 rounded !focus:outline-none"
              onChange={(e) => setMsg(e.target.value)}
              value={msg}
              placeholder="Enter Message"
            />
          </form>

          <Button
            text="Cancel"
            variant="btn_cancel"
            className="ml-3"
            onClick={onClosemodal}
          />
          <Button
            text="Send"
            variant="btn_submit"
            className="ml-3"
            onClick={handleMessage}
            type="submit"
          />
        </div>
      </div>
    </div>
  );
}

export default CustomMsgModal;
