import React, { useEffect, useState } from "react";
import { DotsLoader } from "../../components";
import { FaSearch } from "react-icons/fa";
import { NumericFormat } from "react-number-format";
import { Button as MUIButton } from "@material-ui/core";
import PageHeader from "../../components/molecules/PageHeader";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";
import { get_lead_source_web_query } from "../../features/reportsSlice";
import { get_industry_drd } from "../../features/IndustrySlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";

const LeadWebQueryReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, webQueryReport } = useSelector((state) => state.reports);
    const { industryDrd } = useSelector((state) => state.industry);
    const [searchText, setSearchText] = useState("");
    const [selectedData, setSelectedData] = useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
      pageSize: 100,
      page: 1,
    });

    const getRowHeight = (params) => {
        const rowHeight = 30; // minimum height of the row
        const data = params?.model;
        const substring = data?.message?.substring(0, 100);
        const numberOfLines = substring ? Math.ceil(substring?.length / 30) : 0;
        const addressHeight = rowHeight + numberOfLines * 30 + 0;
        let height = 0;
        let umt_height = 0;
        if (data?.records?.zip || data?.records?.city || data?.records?.state) {
          height = 3 * 25;
        }
        if (
          data?.records?.utm_campaign ||
          data?.records?.utm_content ||
          data?.records?.utm_medium ||
          data?.records?.utm_source
        ) {
          umt_height = 4 * 25;
        }
        return Math.max(rowHeight, addressHeight, height, umt_height);
      };
      const handleNewFilter = () => {
        setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
        dispatch(
          get_lead_source_web_query({
            ...sortingModel,
            filters: queryOptions,
            page: 1,
            size: paginationModel.pageSize,
          })
        );
      };
    const handleSortModelChange = (params) => {
        setSortingModel({
          sort_field: params[0]?.field,
          sort_by: params[0]?.sort || "default",
        });
        dispatch(
          get_lead_source_web_query({
            filters: queryOptions,
            page: paginationModel.page,
            size: paginationModel.pageSize,
            sort_field: params[0]?.field,
            sort_by: params[0]?.sort || "default",
          })
        );
      };
    const currentTime = new Date().getTime(); // Get current timestamp in milliseconds
    const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const startTime = currentTime - twentyFourHours;
    const [queryOptions, setQueryOptions] = React.useState({
        groupOp: "AND",
        rules: [
          {
            field: "createdAt",
            op: "gte",
            data: startTime,
          },
        ],
      });
    const [sortingModel, setSortingModel] = React.useState({
        sort_field: null,
        sort_by: null,
      });
    React.useEffect(() => {
        const payload = {
          ...paginationModel,
          ...sortingModel,
          size: paginationModel.pageSize,
        };
    
        dispatch(get_lead_source_web_query(payload));
        dispatch(get_industry_drd());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function CustomToolbar({ setFilterButtonEl }) {
        return (
          <GridToolbarContainer>
            {selectedData?.length ? (
              <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
                {`${selectedData?.length} items are selected `}
                <span
                  className="text-blue-600 hover:underline cursor-pointer ml-2"
                  onClick={() => setSelectedData([])}
                >
                  Clear
                </span>
              </span>
            ) : null}
            <GridToolbarColumnsButton className="!text-[#042a42]" />
            <GridToolbarDensitySelector className="!text-[#042a42]" />
            <GridToolbarFilterButton
              ref={setFilterButtonEl}
              className="!text-[#042a42]"
            />
            <MUIButton
              variant="text"
              onClick={handleNewFilter}
              startIcon={<FaSearch size={16} />}
              sx={{ fontSize: "0.8125rem" }}
              className={"!text-[#042a42]"}
            >
              Apply filter
            </MUIButton>
          </GridToolbarContainer>
        );
      }
    const onFilterChange = React.useCallback((filterModel) => {
        let ruless = [];
        if (filterModel?.items?.length === 0) {
          setPaginationModel({ ...paginationModel, page: 1 });
          dispatch(
            get_lead_source_web_query({
              ...sortingModel,
              page: 1,
              size: paginationModel.pageSize,
            })
          );
        }
        // eslint-disable-next-line array-callback-return
        filterModel.items?.map((rule) => {
          ruless = [
            ...ruless,
            {
              field: `${rule.field}`,
              op:
                rule.field === "tlds"
                  ? "cn"
                  : rule.operator === "contains"
                  ? "cn"
                  : rule.operator === "equals"
                  ? "eq"
                  : rule.operator === "is"
                  ? "eq"
                  : rule.operator === "="
                  ? "eq"
                  : rule.operator === "!="
                  ? "not"
                  : rule.operator === ">"
                  ? "gt"
                  : rule.operator === ">="
                  ? "gte"
                  : rule.operator === "<"
                  ? "lt"
                  : rule.operator === "<="
                  ? "lte"
                  : rule.operator === "onOrBefore"
                  ? "lte"
                  : rule.operator === "before"
                  ? "lt"
                  : rule.operator === "onOrAfter"
                  ? "gte"
                  : rule.operator === "after"
                  ? "gt"
                  : rule.operator,
              data:
                rule.field === "createdAt" || rule.field === "message_date"
                  ? new Date(rule.value).getTime()
                  : rule.value
                  ? rule.value
                  : rule.value === 0
                  ? rule.value
                  : null,
            },
          ];
        });
        setQueryOptions({
          groupOp: filterModel.logicOperator.toUpperCase(),
          rules: ruless,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const handlePageSizeChange = (newPageSize) => {
        setPaginationModel({ ...paginationModel, pageSize: newPageSize });
      };
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false, width: 60 },
    {
      field: "createdBy",
      headerName: "Created By",
      type: "singleSelect",
      flex: 1,
      minWidth: 120,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => `${value.name || ""} `,
      valueOptions: webQueryReport?.records,
      valueGetter: (params) => params.row?.createdBy,
      valueFormatter: (params) => params.value?.createdBy,
      renderCell: (params) => (
        <span className="font-bol">{params?.row?.createdBy}</span>
      ),
    },
    {
      headerName: "Industry",
      field: "industry_name",
      flex: 1,
      type: "singleSelect",
      minWidth: 150,
      getOptionValue: (value) => value?.name,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      renderCell: (params) => params.value.industry_name,
      valueGetter: (params) => params.row.industry_name,
      valueFormatter: (params) => params.value.industry_name,
    },
    {
      headerName: "Contact Name",
      field: "contact_name",
      flex: 1,
      filterable: true,
      minWidth: 180,
    },
    { headerName: "Email", field: "email", flex: 1, minWidth: 200 },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 150 },
    {
      headerName: "Location",
      field: "location",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const { zip = null, state = null, city = null } = params.row.records;
        return (
          <div className="flex flex-col">
            {zip && <div>Zip: {zip}</div>}
            {city && <div>City: {city}</div>}
            {state && state!=="undefined" && <div>State: {state}</div>}
          </div>
        );
      },
    },
    {
      headerName: "Message",
      field: "message",
      minWidth: 200,
      wrap: true,
      renderCell: (params) => {
        const substring = params?.row?.message?.substring(0, 100);
        return (
          <div>
            <span>{substring}</span>
            {params.row.message?.length > 100 ? (
              <>
                <span>...</span>
              </>
            ) : null}
          </div>
        );
      },
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Message Date",
      field: "message_date",
      flex: 1,
      type: "dateTime",
      minWidth: 150,
    },
    {
      headerName: "Message Date",
      field: "format_message_date",
      flex: 1,
      filterable: false,
      minWidth: 220,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      type: "dateTime",
      minWidth: 150,
    },
    {
      headerName: "Created At",
      field: "formatCreatedAt",
      flex: 1,
      filterable: false,
      minWidth: 220,
    }
  ];

  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    const payload = {
      ...sortingModel,
      page: +params.page + 1,
      size: params.pageSize,
    };

    dispatch(get_lead_source_web_query(payload));
  };

  const filterIP = webQueryReport?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
    return (
        <>
            <PageHeader
                route="Setting > Industries"
                heading="Web Query Report"
                onClick={() => navigate("/leadsource/add")}
            />
            {/* Loader */}
            {isLoading && <DotsLoader />}
            {/* Count */}
            <div className="pt-3">
                <div className="grid grid-cols-3 gap-4">
                    <div className="flex mb-2 flex-col justify-between items-center text-ml-3 p-4 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
                        <span className="text-sm font-pop">Total Count</span>
                        <span className="text-base font-medium font-pop">
                            {webQueryReport?.summary?.form_count && (<NumericFormat
                                displayType="text"
                                value={1}
                                prefix={webQueryReport?.summary?.form_count.toString()}
                                thousandSeparator={true}
                            />)}
                        </span>
                    </div>
                    <div className="flex mb-2 flex-col justify-between items-center text-ml-3 p-4 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
                        <span className="text-sm font-pop">Lead Count</span>
                        <span className="text-base font-medium font-pop">
                            {webQueryReport?.summary?.lead_count && (<NumericFormat
                                displayType="text"
                                value={1}
                                prefix={webQueryReport?.summary?.lead_count.toString()}
                                thousandSeparator={true}
                            />)}
                        </span>
                    </div>
                    <div className="flex mb-2 flex-col justify-between items-center text-ml-3 p-4 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
                        <span className="text-sm font-pop">Job Count</span>
                        <span className="text-base font-medium font-pop">
                            {webQueryReport?.summary?.job_count && (<NumericFormat
                                displayType="text"
                                value={1}
                                prefix={webQueryReport?.summary?.job_count.toString()}
                                thousandSeparator={true}
                            />)}
                        </span>
                    </div>
                </div>
            </div>
            
            {/* List */}
            <div className="bg-white my-3 border rounded">
                <MUIDataTable
                    columnDefs={columnDefs}
                    items={filterIP?.map((record, index) => {
                        const {
                            contact_name,
                            email,
                            phone,
                            message,
                            createdAt,
                            industry_name,
                            message_date,
                            createdBy,
                        } = record;
                        let counter = index + 1;
                        return {
                            records: record,
                            counter,
                            message_date: new Date(message_date),
                            industry_name,
                            contact_name,
                            email,
                            phone,
                            message,
                            createdBy: createdBy?.name || "",
                            createdAt: new Date(createdAt),
                            format_message_date: message_date
                                ? dayjs(message_date).format("ddd, MMM D, YYYY h:mm A")
                                : "",
                            formatCreatedAt: dayjs(createdAt).format(
                                "ddd, MMM D, YYYY h:mm A"
                            ),
                        };
                    })}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    isLoading={isLoading}
                    onPaginationModelChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    paginationModel={paginationModel}
                    totalItems={webQueryReport?.totalItems}
                    gridOptions={getRowHeight}
                    onFilterModelChange={onFilterChange}
                    CustomToolbar={CustomToolbar}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    columnVisibilityModel={{ createdAt: false, message_date: false }}
                />
            </div>

        </>
    )
};

export default LeadWebQueryReport;