import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LuFileText } from "react-icons/lu";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { Button, FormSelectInput } from "../../../components";
import dayjs from "dayjs";
export default function AddUpdateGoogleEventModal({
  editingRecord,
  cancelFormHandler,
  fetchChildsList,
  setIsLoading,
  taskList,
}) {
  console.log("👊 ~ taskList:", taskList);
  const optionsRef = React.useRef();
  // const handleHideDropdown = (event) => {
  //   if (event.key === "Escape") {
  //     cancelFormHandler();
  //   }
  // };
  // const handleClickOutside = (event) => {
  //   if (optionsRef.current && !optionsRef.current.contains(event.target)) {
  //     cancelFormHandler();
  //   }
  // };
  // document.addEventListener("keydown", handleHideDropdown, true);
  // document.addEventListener("click", handleClickOutside, true);
  const [payload, setPayload] = useState(editingRecord);
  const handleSubmit = async () => {
    setIsLoading(true);
    const msg = payload?.task_id ? "updated" : "added";
    const action = payload?.task_id
      ? api.put(
          `/api/google_task/task/${payload?.task_list_id}/${payload?.task_id}`,
          {
            title: payload?.title,
            notes: payload?.notes,
            dueDate: payload?.dueDate,
          }
        )
      : api.post(`/api/google_task/task/${payload?.task_list_id}`, payload);
    try {
      const response = await action;
      if (response?.status === 200 || response?.status === 201) {
        await fetchChildsList({ task_id: payload?.task_list_id });
        setPayload(null);
        toast.success(`Task ${msg} successfully`);
        cancelFormHandler();
      } else {
        toast.error(response?.data?.message || `Failed to ${msg} task`);
      }
    } catch (error) {
      console.error("Error adding task:", error);
      toast.error(error?.response?.data?.message || `Failed to ${msg} task`);
    }
    setIsLoading(false);
  };
  return (
    <div className="absolute top-0 bottom-0 right-0 left-0 bg-black/60 z-[1000] flex justify-center items-center">
      <div
        className="bg-white rounded-lg shadow-2xl md:w-[40%] border"
        ref={optionsRef}
      >
        <header
          className={`bg-[#f7f7f7] px-4 py-2 flex justify-between rounded-t-lg items-center border-b border-[#bbb2b2]`}
        >
          <span className="material-icons-outlined text-black">
            {payload?.main_data ? "Update Task" : "Add Task"}
          </span>
          <div>
            <button onClick={cancelFormHandler}>
              <span className="material-icons-outlined text-menu">
                <AiOutlineCloseCircle size={25} />
              </span>
            </button>
          </div>
        </header>
        <div className="p-3 flex">
          <div className="w-full">
            <input
              className="border w-full mb-3 h-10 px-2 rounded"
              placeholder="Add Title"
              onChange={(e) => {
                setPayload({ ...payload, title: e?.target.value });
              }}
              value={payload.title}
            />
            <div className="flex gap-x-2">
              <div className="flex flex-col justify-center w-full mb-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    value={payload?.dueDate}
                    onChange={(val) => {
                      console.log("👊 ~ val:", val);
                      setPayload({
                        ...payload,
                        dueDate: dayjs(val).toISOString(),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Date"
                        className="bg-[#f3f3f3]"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="relative w-full !mb-3">
              <LuFileText className="absolute left-2 top-2 text-gray-500" />
              <textarea
                className="border bg-[#f1f3f] p-1 !pl-8 w-full" // Add padding-left to accommodate the icon
                placeholder="Add Description"
                rows={3}
                onChange={(e) => {
                  setPayload({
                    ...payload,
                    notes: e.target.value,
                  });
                }}
                value={payload.notes}
              />
            </div>
            <FormSelectInput
              name="task_list_id"
              label="Select task list"
              onChange={(value) => {
                setPayload({
                  ...payload,
                  task_list_id: value,
                });
              }}
              options={taskList}
              valueProp="id"
              labelProp="title"
              value={payload?.task_list_id}
            />
          </div>
        </div>
        <footer className="flex justify-end border-t !p-3 !mt-">
          <Button text="Save" onClick={handleSubmit} variant="btn_submit" />
        </footer>
      </div>
    </div>
  );
}
