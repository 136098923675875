import React from "react";
import SmallCalendar from "./SmallCalendar";
export default function Sidebar() {
  return (
    <aside className="border !p-5 w-full md:w-64">
      {/* {userPermission("Write Calendar") ? <CreateEventButton /> : null} */}
      <SmallCalendar />
      {/* <Labels /> */}
    </aside>
  );
}
