import React, { useEffect, useState } from "react";
import FormInput from "../Common/FormInput";
import FormSelectInput from "../Common/FormSelectInput";
import VehicleInfoSection from "../Common/Sections/VehicleInfoSection";
import VehicleTrailerDetailsSection from "../Common/Sections/VehicleTrailerDetailsSection";
const TwoWayTowSection = ({ formik, serviceName }) => {
  const currentYear = new Date().getFullYear();
  const [yearsOption, setYearsOption] = useState([]);

  useEffect(() => {
    let years = [];
    for (let i = 1970; i <= currentYear; i++) {
      years.push({ label: i, value: i });
    }
    setYearsOption(years);
  }, [currentYear]);
  useEffect(() => {
    if (formik.values?.extra_fields?.is_replacement_vehicle_same) {
      formik.setFieldValue(
        `extra_fields.replacement_car_year`,
        formik.values?.extra_fields?.car_year
      );
      formik.setFieldValue(
        `extra_fields.replacement_car_make`,
        formik.values?.extra_fields?.car_make
      );
      formik.setFieldValue(
        `extra_fields.replacement_car_model`,
        formik.values?.extra_fields?.car_model
      );
      formik.setFieldValue(
        `extra_fields.replacement_car_color`,
        formik.values?.extra_fields?.car_color
      );
    } else {
      formik.setFieldValue(`extra_fields.replacement_car_year`, null);
      formik.setFieldValue(`extra_fields.replacement_car_make`, null);
      formik.setFieldValue(`extra_fields.replacement_car_model`, null);
      formik.setFieldValue(`extra_fields.replacement_car_color`, null);
    }
  }, [formik.values?.extra_fields?.is_replacement_vehicle_same]);

  return (
    <>
      <VehicleInfoSection
        serviceName={serviceName}
        formik={formik}
        fieldsToShow={[
          "car_year",
          "car_make",
          "car_model",
          "is_neutral",
          "car_color",
          "drivetrain",
          "duty_type",
          "is_tires_ok",
          "car_type",
          "tow_type",
          "is_in_parking",
          ...(formik?.values?.extra_fields?.is_in_parking
            ? ["height_clearance", "floor_level"]
            : []),
        ]}
      />
      <VehicleTrailerDetailsSection
        formik={formik}
        fieldsToShow={[
          ...(formik.values.extra_fields?.duty_type &&
          formik.values.extra_fields?.duty_type !== "light_duty"
            ? [
                "is_trailer",
                "weight",
                "height",
                "length",
                "width",
                "axles_count",
                "is_dually",
                "loaded_with",
                "brakes_be_release",
              ]
            : []),
          formik.values?.extra_fields?.tow_type === "secondary"
            ? "payout_section"
            : null,
        ].filter(Boolean)}
      />

      <div className="mt-2 border mb-4 px-2">
        <div className="flex flex-col w-1/4 mt-4">
          <label
            className="font-semibold text-xs text-gray-500 mb-1"
            htmlFor="year"
          >
            Is the replacement of the vehicle the same?
          </label>
          <FormSelectInput
            name="extra_fields.is_replacement_vehicle_same"
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            convertor={(value) => (value ? value === "true" : "")}
            formik={formik}
            isCustomOption
            value={formik.values?.extra_fields?.is_replacement_vehicle_same}
            valueProp="value"
            labelProp="label"
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mb-4 mt-3">
          <FormSelectInput
            name="extra_fields.replacement_car_year"
            options={yearsOption}
            valueProp="value"
            labelProp="label"
            label="Year"
            isCustomOption="Select an year"
            formik={formik}
            value={formik.values?.extra_fields?.replacement_car_year}
          />
          <FormInput
            name="extra_fields.replacement_car_make"
            type="text"
            label="Make"
            formik={formik}
            value={formik.values?.extra_fields?.replacement_car_make}
          />
          <FormInput
            name="extra_fields.replacement_car_model"
            type="text"
            label="Model"
            formik={formik}
            value={formik.values?.extra_fields?.replacement_car_model}
          />
          <FormInput
            name="extra_fields.replacement_car_color"
            type="text"
            label="What's the color of the vehicle?"
            // placeholder={"Enter the color"}
            formik={formik}
            value={formik.values?.extra_fields?.replacement_car_color}
          />
          <FormInput
            name="extra_fields.replacement_license_number"
            type="text"
            label="License Plate Number"
            formik={formik}
            value={formik.values?.extra_fields?.replacement_license_number}
          />
        </div>
      </div>
    </>
  );
};

export default TwoWayTowSection;
