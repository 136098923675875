import React, { useState } from "react";
import { VscCallOutgoing } from "react-icons/vsc";
import { FaTimes } from "react-icons/fa";
import { Loader } from "../../components";
import { AiOutlineFileAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  call_by_link,
  set_conversation_detail,
} from "../../features/dialerSlice";
import { toast } from "react-toastify";
import { create_lead_initial } from "../../features/leadsSlice";
import { useNavigate } from "react-router-dom";
const CustomModal = ({ onClose, conversation_detail }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dialer = useSelector((state) => state.dialer);
  const [isLoader] = useState(false);
  const callOn = async () => {
    const number = conversation_detail?.phone;
    if (dialer?.status === "PAUSE") {
      try {
        const res = await dispatch(call_by_link(number));
        if (
          res?.payload?.status === 200 &&
          !res.payload?.data?.includes("ERROR")
        ) {
          toast.success("Call Successfully");
          dispatch(set_conversation_detail(conversation_detail?.records));
        } else {
          toast.error(res.payload?.data || "Call Failed");
        }
      } catch (err) {
        toast.error(err?.response?.payload?.data || "Call Failed");
        console.error("🚀 ~ file: index.jsx:68 ~ callOn ~ err:", err);
      }
    } else if (dialer?.status === "") {
      toast.error("Please login to dialer");
    } else {
      toast.error("Please Pasue your dialer before call");
    }
  };

  const convert_to_lead = async () => {
    try {
      const { phone, industry_id, contact_name, email, _id, domain } =
        conversation_detail?.records;
      const res = await dispatch(
        create_lead_initial({
          industry_id: industry_id ? industry_id : "6366126737c4d7c72204c6c0",
          phone:
            phone?.length === 10 && !phone?.includes("+")
              ? `+1${phone}`
              : phone?.length === 11 &&
                !phone?.includes("+") &&
                phone?.startsWith("1")
              ? `+${phone}`
              : phone,
          call_payload_domain: domain,
          dialer_lead_id: "",
          vicidial_id: "",
          call_type: "Conversation",
          conversation_id: _id,
        })
      );
      if (res?.payload?.status === 200) {
        navigate(`/leads/update/${res?.payload?.data?._id}`, {
          state: {
            name: contact_name,
            email: email,
          },
        });
      } else {
        toast.error("Failed to create lead");
      }
    } catch (error) {
      console.error("🚀 ~ file: index.jsx:475 ~ handleSMS ~ error:", error);
      toast.error("Failed to create lead");
    }
  };
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white rounded-b-lg shadow-lg relative min-w-[500px] max-w-[90%] px-2">
        <div className="flex justify-end items-center mb-">
          <button className="hover:text-red-500" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className=" px-6">
          <h2 className="text-lg font-semibold mb-4">
            Create a lead and initiate a call, or create a lead only.
          </h2>
          <div className="flex justify-end !my-4 ">
            {isLoader ? (
              <Loader />
            ) : (
              <div className="flex w-full justify-center">
                <div className="flex gap-x-3">
                  <button
                    title="Notify by SMS"
                    className="flex gap-x-1.5 items-center bg-slate-500 hover:bg-slate-600 text-white p-2 rounded-md border font-semibold"
                    type="button"
                    onClick={callOn}
                  >
                    <VscCallOutgoing />
                    Create Lead & Call
                  </button>
                  <button
                    title="Notify by Email"
                    className="flex gap-x-1 items-center bg-slate-500 hover:bg-slate-600 text-white p-2 rounded-md border font-semibold"
                    type="button"
                    onClick={convert_to_lead}
                  >
                    <AiOutlineFileAdd />
                    Create Lead Only
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
