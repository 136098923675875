import React from "react";
import { FormInput, DotsLoader } from "../../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaInfoCircle } from "react-icons/fa";
import CustomModal from "../../../components/molecules/Modal/customModal";
import api from "../../../services/api";
import { toast } from "react-toastify";
const UnregisterDids = ({
  onCancelForm,
  modalTitle,
  selectedData,
  methodType,
  onFormSubmit,
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      const selectedFields = selectedData.map((item) =>
        methodType === "purchase_dids"
          ? {
              did: item?.did,
              description: item?.description,
              callCenter_status: item?.callCenter_status,
            }
          : {
              did_pattern: item?.did_pattern?.startsWith("1")
                ? item?.did_pattern.slice(1)
                : item?.did_pattern,
              user_group: item?.user_group,
              did_description: item?.did_description,
              did_route: item?.did_route,
              menu_name: item?.menu_name,
              inbound_group_name: item?.inbound_group_name,
            }
      );

      const payload = {
        dids: selectedData?.map((item) =>
          (methodType === "purchase_dids" ? item?.did : item?.did_pattern)
            ?.toString()
            .startsWith("1")
            ? (methodType === "purchase_dids" ? item?.did : item?.did_pattern)
                ?.toString()
                .slice(1)
            : methodType === "purchase_dids"
            ? item?.did
            : item?.did_pattern
        ),
        to_email: values?.email || "",
        dids_info: selectedFields,
      };
      setLoading(true);
      try {
        const res = await api.post(
          "/api/cc/dids/unregister_selected_dids",
          payload
        );
        if (res.status === 200) {
          onFormSubmit();
          onCancelForm();
          toast.success("DID successfully unregistered.");
        } else {
          toast.error("DID could not be unregistered!");
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error("DID could not be unregistered!");
        console.log(err);
      }
    },
  });

  return (
    <>
      {isLoading ? <DotsLoader /> : null}
      <CustomModal
        handleModal={onCancelForm}
        cancelButtonRef={onCancelForm}
        className="max-w-2xl"
        modalType={false}
        title={modalTitle}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
      >
        <div
          class="flex items-center p-2 mb-3 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
          role="alert"
        >
          <FaInfoCircle />
          <div>
            <span class="font-medium text-sm ml-2">
              You have selected {selectedData?.map((item) => item.dids)?.length}{" "}
              DIDs to Unregister
            </span>
          </div>
        </div>

        <form>
          <div className="grid md:grid-cols-1 gap-4 w-4/6">
            <FormInput name="email" label="Enter Email" formik={formik} />
          </div>

          <div className="flex p-0 mt-2">
            <div
              className={`flex-1 bg-gray-100 p-4 shadow w-full}`}
              style={{ maxHeight: "calc(60vh - 2rem)" }}
            >
              <h2 className="text-lg font-semibold mb-2 sticky top-0 bg-gray-100">
                Selected DIDs
              </h2>
              <div
                className="overflow-y-auto"
                style={{ maxHeight: "calc(40vh - 2rem - 2.5rem)" }}
              >
                {selectedData?.map((item, index) => (
                  <div key={index} className="mb-1 text-blue-600">
                    {methodType === "purchase_dids"
                      ? item?.did
                      : item?.did_pattern}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
          class="flex items-center p-2 mb-3 mt-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
          role="alert"
        >
          <FaInfoCircle />
          <div>
            <span class="font-medium text-sm ml-2">
            DIDs will be deleted from both the Server Provider and the Call Center.
            </span>
          </div>
        </div>
          <div className="mt-3 flex justify-end">
            <button
              type="button"
              onClick={formik.handleSubmit}
              className="bg-primary-100 text-white text-sm rounded px-4 py-2 font-semibold"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={onCancelForm}
              className="bg-black text-white text-sm rounded px-4 py-2 font-semibold ml-2"
            >
              Cancel
            </button>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default UnregisterDids;
