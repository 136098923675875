import React, { useEffect, useState } from "react";
import { DotsLoader } from "../../components";
import { FaChevronUp } from "react-icons/fa";
import api from "../../services/api";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const LeadSource = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [record, setRecord] = useState([]);
  
  const getSERPReport = async () => {
    setRecord([]);
    setIsLoading(true);
    try {
      const res = await api.post("api/lead_source", {});
      if (res.status === 200) {
        setRecord(res.data.records);
      }
      setIsLoading(false);
    } catch (err) {
      console.log("🚀 ~ getSERPReport ~ err:", err);
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    getSERPReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [visibleRecords, setVisibleRecords] = useState(new Set([0]));
  const isOpen = (id) => visibleRecords.has(id);
  const toggleListVisibility = (id) => {
    setVisibleRecords((prevVisibleRecords) => {
      const newVisibleRecords = new Set(prevVisibleRecords);
      if (newVisibleRecords.has(id)) {
        newVisibleRecords.delete(id);
      } else {
        newVisibleRecords.add(id);
      }
      return newVisibleRecords;
    });
  };
  return (
    <>
      <PageHeader
        route="Setting > Industries"
        heading="Leads Source Listing"
        onClick={() => navigate("/leadsource/add")}
        isAllowed={userPermission("Add Lead")}
      />
      {isLoading ? <DotsLoader /> : null}

      <div >
        {record?.length ? (
        record?.map((record, index) => {
          const {
            title,
            alternate_title,
            isActive,
            email,
            createdAt,
            leadSourceSettingIds
          } = record;
          return (
            <div
              className={` bg-white dark:bg-gray-800 last:border-none dark:border-white/20 !py-0 mb-2 shadow-sm border border-neutral-200 mt-2 `}
            >
              <div
                className={`px-6 !py-3 flex bg-white border-b border-neutral-200 items-center justify-between flex-wrap gap-2  `}
                onClick={() => toggleListVisibility(index)}
              >
                <div className="flex items-center justify-between flex-wrap text-sm gap-3 !text-black">
                  <p className="font-bold text-black w-12">
                    {index + 1}.
                  </p>
                  <div className="w-20">
                    <b className="text-black">
                      {"Title"}:
                    </b>{" "}
                    <span>{title}</span>
                  </div>
                  <div className="w-48">
                    <b className="text-black">
                      {"Alternate Title"}:
                    </b>{" "}
                    <span>{alternate_title}</span>
                  </div>

                  <div className="w-66">
                    <b className="text-black">
                      {"Email"}:
                    </b>{" "}
                    <span>{email}</span>
                  </div>
                </div>
                <div className="flex items-center justify-end flex-wrap gap-2 lg:gap-3 text-sm ml-auto">
                  <div className="w-46">
                  <b className="text-black">
                      {"Date"}:
                    </b>{" "}
                    <span>{dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A")}</span>
                  </div>
                  <div className="w-36">
                  <b className="text-black">
                      {"Active"}:
                    </b>{" "}
                    <span>{isActive?"Yes":"No"}</span>
                  </div>

                  <button>
                    <FaChevronUp
                      className={`w-5 transition-transform transform text-gray-400 ${
                        isOpen(index) && "rotate-180"
                      }`}
                    />
                  </button>
                </div>
              </div>

             {leadSourceSettingIds?.map((lead, leadIndex) => {
              return (<div
                className={`px-6 overflow-hidden transition-max-height duration-500 ease-in-out ${
                  isOpen(index) ? "max-h-screen" : "max-h-0"
                }`}
              >
                <div className="grid grid-cols-1 text-sm md:grid-cols-2 lg:grid-cols-1 gap-x-1 gap-y-2 border- dark:border-white/20 pt-1 mt-1 pb-2">
                  <div className="flex items-center">
                    <div className="w-40">
                      <b className="!text-black text-xs">{"Type"}:</b>
                      <span className="text-xs ml-1">
                        {lead.type}
                      </span>
                    </div>
                    {lead.type === "InboundCall"&&(<div className="w-40">
                      <b className="!text-black text-xs">{"Phone"}:</b>
                      <span className="text-xs ml-1">
                        {lead.inboundPhoneNumbers.join(", ")}
                      </span>
                    </div>)}
                    <div className="w-40">
                      <b className="!text-black text-xs">{"Active"}:</b>
                      <span className="text-xs ml-1">
                        {lead.isActive?"Yes":"No"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
             )
             })}
            </div>
          );
        })
      ) : (
        <div className="w-full flex justify-center">
          <strong>No record found</strong>
        </div>
      )}
      </div>
    </>
  );
};

export default LeadSource;
