import React from "react";
import VehicleInfoSection from "../Common/Sections/VehicleInfoSection";
const LocakOutService = ({ formik, serviceName }) => {
  return (
    <>
      <VehicleInfoSection
        formik={formik}
        fieldsToShow={[
          "car_year",
          "car_make",
          "car_model",
          "is_neutral",
          "car_color",
        ]}
        serviceName={serviceName}
      />
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full bg-neutral-50">
          <div className="flex flex-col p-2">
            <h6 className="font-semibold w-full px-1 py-0 text-base">
              Is it a door lockout or trunk lockout?
            </h6>
            <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-2 lg:space-y-0 lg:space-x-4 mb-4">
              <div className="flex items-center space-x-4 px-2 py-2 border rounded-md border-gray-300">
                <label className="flex items-center">
                  <input
                    id="extra_fields.lockout_type"
                    type="radio"
                    name="extra_fields.lockout_type"
                    value="door_lockout"
                    className="mr-2 cursor-pointer"
                    checked={
                      formik?.values?.extra_fields?.lockout_type ===
                      "door_lockout"
                    }
                    onChange={(e) =>
                      formik?.setFieldValue(
                        "extra_fields.lockout_type",
                        e.target.value
                      )
                    }
                  />
                  Door Lockout
                </label>
                <label className="flex items-center">
                  <input
                    id="extra_fields.lockout_type"
                    type="radio"
                    name="lockout_type"
                    value="trunk_lockout"
                    className="mr-2 cursor-pointer"
                    checked={
                      formik?.values?.extra_fields?.lockout_type ===
                      "trunk_lockout"
                    }
                    onChange={(e) =>
                      formik?.setFieldValue(
                        "extra_fields.lockout_type",
                        e.target.value
                      )
                    }
                  />
                  Trunk Lockout
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocakOutService;
