import React from "react";
import { LoadScriptNext } from "@react-google-maps/api";
const libraries = ["places", "drawing", "geometry"];
function GoogleScript({ children }) {
  const googleKey = localStorage.getItem("google_api_key");
  return (
    <LoadScriptNext
      googleMapsApiKey={googleKey}
      libraries={libraries}
      loadingElement={<div>Loading...</div>}
    >
      {children}
    </LoadScriptNext>
  );
}

export default GoogleScript;
