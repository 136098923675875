import React from "react";
import { DotsLoader, FormInput, Modal } from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import { create_api_users } from "../../features/apiUserSlice";
const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.apiUsers);
  const [errors, setErrors] = React.useState([]);
  let initialValues = {
    name: "",
    email: "",
  };

  if (editingRecord) {
    const { _id, name, email } = editingRecord;
    initialValues = {
      id: _id,
      name,
      email,
    };
  }
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        const res = await dispatch(create_api_users(values));
        if (res?.payload?.status === 200 || res?.payload?.status === 201) {
          toast.success("API user created successfully");
          onCancelForm();
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "API user",
            msg: "created",
          });
        }
      } catch (error) {
        toast.error("API user couldn't be created");
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <div className="mb-3 mt-4">
            <FormInput
              errors={errors}
              name="name"
              label="Name"
              formik={formik}
            />
          </div>
          <div>
            <FormInput
              errors={errors}
              name="email"
              label="Email"
              formik={formik}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
