import React from "react";
import VehicleInfoSection from "../Common/Sections/VehicleInfoSection";
import VehicleTrailerDetailsSection from "../Common/Sections/VehicleTrailerDetailsSection";
const TireReplacementSection = ({ formik, serviceName }) => {
  return (
    <>
      <VehicleInfoSection
        serviceName={serviceName}
        formik={formik}
        fieldsToShow={[
          "car_year",
          "car_make",
          "car_model",
          "is_neutral",
          "car_color",
          "duty_type",
          "which_tire_to_be_change",
          "tire_size",
          "used_or_new_tire",
        ]}
      />
      <VehicleTrailerDetailsSection
        formik={formik}
        fieldsToShow={[
          ...(formik.values.extra_fields?.duty_type &&
          formik.values.extra_fields?.duty_type !== "light_duty"
            ? [
                "is_trailer",
                "weight",
                "height",
                "length",
                "width",
                "axles_count",
                "is_dually",
                "loaded_with",
                "brakes_be_release"
              ]
            : []),
        ]}
      />
    </>
  );
};

export default TireReplacementSection;
