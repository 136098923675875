import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import GoogleConsoleForm from "./GoogleConsoleForm";
import userPermission from "../../util/userPermission";
import {
  delete_gmail_accounts,
  get_gmail_accounts,
} from "../../features/gmailAccountSlice";
import { Button, DotsLoader, FormInput } from "../../components";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import api from "../../services/api";
import dayjs from "dayjs";
import EmailDetail from "./EmailDetailModal";
const GmailAccounts = () => {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const dispatch = useDispatch();
  const { isLoading, emailAccounts } = useSelector(
    (state) => state.gmailAccounts
  );
  const getTimezoneString = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  };
  const initalData = {
    subjectText: null,
    fromEmail: null,
    numberOfMails: null,
    afterDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
    beforeDate: dayjs(new Date()).format("YYYY-MM-DD"),
    timeZone: getTimezoneString(),
  };
  const [emailList, setEmailList] = React.useState([]);
  const [selectedPayload, setSelectedPayload] = React.useState(initalData);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedPayload({ ...selectedPayload, [name]: value });
  };
  const [isEditing, setIsEditing] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedEmailAccount, setSelectedEmailAccount] = React.useState(null);
  const [selectedEmail, setSelectedEmail] = React.useState(null);

  const [editingRecord, setEditingRecord] = useState(null);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_gmail_accounts());
  }, [dispatch]);

  const deleteRecordHandler = async (record_id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      if (record_id === selectedEmail?._id) {
        setSelectedEmail(null);
      }
      try {
        const res = await dispatch(delete_gmail_accounts(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Email deleted successfully");
          dispatch(get_gmail_accounts());
        } else {
          errorMessage({
            payload: res.payload,
            action: "Email",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Google Search Console Account",
          msg: "deleted",
        });
      }
    }
  };
  const isSelected = (id) => {
    if (selectedEmailAccount) {
      if (selectedEmailAccount?._id === id) {
        return true;
      }
    }
    return false;
  };

  const get_google_mails = async (filter) => {
    setIsLoader(true);
    try {
      const res = await api.post(
        "/api/google_email/email_specific_list",
        filter
      );
      if (res.status === 200) {
        setEmailList(Array.isArray(res.data) ? res.data : []);
      }
      setIsLoader(false);
    } catch (err) {
      console.log(err);
      setIsLoader(false);
    }
  };
  const inboxDetails = (data) => {
    if (data && !data.isRead) {
      const payload = {
        message_id: data.message_id,
        id: selectedEmailAccount?._id,
      };
      try {
        api.post("/api/google_email/mark_specific_email_as_read", payload);
      } catch (err) {
        console.log(err);
      }
    }
    setSelectedEmail(data);
  };
  const onReset = () => {
    setSelectedPayload({ ...initalData, id: selectedEmailAccount?._id });
    get_google_mails({ ...initalData, id: selectedEmailAccount?._id });
  };
  function decodeBase64UrlSafe(base64String) {
    let normalizedBase64 = base64String.replace(/-/g, "+").replace(/_/g, "/");
    while (normalizedBase64.length % 4 !== 0) {
      normalizedBase64 += "=";
    }
    try {
      const byteArray = Uint8Array.from(atob(normalizedBase64), (c) =>
        c.charCodeAt(0)
      );
      return new TextDecoder("utf-8").decode(byteArray);
    } catch (e) {
      console.error("Invalid Base64 input:", e);
      return null;
    }
  }
  const renderContent = (content) => {
    if (!content) return "";
    content = content.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "");
    content = content.replace(/<script[^>]*>[\s\S]*?<\/script>/gi, "");
    content = content.replace(/<[^>]+>/g, "");
    return content.trim();
  };
  const syncWithGoogle = async () => {
    if (!selectedEmailAccount) return toast.error("Please select the email.");
    if (selectedEmailAccount?.synced_status !== "PENDING")
      return toast.error("Your account already synced with google.");
    try {
      window.location.href = `https://api.logicalcrm.com/api/open/auth_specific?merchant_id=${user?.current_merchant_id}`;
    } catch (err) {
      console.log("Error while syncing with google", err);
    }
  };
  return (
    <>
      <PageHeader
        heading="Gmail Account List"
        onClick={() => openFormHandler(0)}
        isAllowed={userPermission("Add Allowed IP")}
      />
      {isLoader && <DotsLoader />}
      {isEditing && (
        <GoogleConsoleForm
          editingRecord={editingRecord}
          modalTitle="Add Email"
          onCancelForm={cancelFormHandler}
        />
      )}
      {selectedEmail && (
        <EmailDetail
          selectedEmail={selectedEmail}
          modalTitle="Mail Content"
          onCancelForm={() => setSelectedEmail(null)}
        />
      )}
      {isLoading ? <DotsLoader /> : null}
      <div className="hidden md:flex flex-wrap justify-start mt-3 gap-2">
        {emailAccounts?.map((data) => {
          const { _id, email } = data;
          return (
            <li
              className={`flex items-center mb-2 list-none active rounded border border-[#e3e3e3] cursor-pointer `}
              key={_id}
            >
              <button
                onClick={() => {
                  setSelectedEmailAccount(data);
                  if (data?.synced_status === "SYNCED") {
                    get_google_mails({
                      ...selectedPayload,
                      id: data?._id,
                    });
                  }
                }}
              >
                <div
                  className={`flex flex-col items-center w-full p-1.25  ${
                    isSelected(_id)
                      ? "bg-client-100 text-white"
                      : "bg-white text-black"
                  }`}
                >
                  <span className="text-xs font-pop">{email}</span>
                </div>
              </button>
              <div className="flex flex-row ml-1 gap-x-0.5">
                <FaEdit
                  onClick={() => openFormHandler(data)}
                  className="hover:text-[#34343460] coursor-pointer"
                  title="Update Email Account"
                  size={15}
                />
                <FaTrashAlt
                  onClick={() => deleteRecordHandler(data?._id)}
                  className="hover:text-[#34343460] coursor-pointer"
                  title="Delete Email Account"
                  size={15}
                />
              </div>
            </li>
          );
        })}
      </div>
      <div className="bg-white my-3 border rounded">
        {selectedEmailAccount ? (
          selectedEmailAccount?.synced_status === "PENDING" ? (
            <div className="flex w-full items-center justify-center !py-4">
              <Button
                text="Sync with google"
                variant="btn_cancel"
                onClick={syncWithGoogle}
              />
            </div>
          ) : (
            <div className="h-ful !p-4 bg-neutral-100 overflow-x-auto">
              <table className="table-auto text-black w-full">
                <tr>
                  <td colSpan="" className="font-semibold border-0 px-0">
                    <div className="flex justify-between">
                      <div className="grid grid-cols-7 gap-x-2 flex items-center">
                        <FormInput
                          placeholder={"Subject Text"}
                          onChange={handleChange}
                          name="subjectText"
                          value={selectedPayload?.subjectText}
                        />
                        <FormInput
                          placeholder={"From Email"}
                          onChange={handleChange}
                          name="fromEmail"
                          value={selectedPayload?.fromEmail}
                        />
                        <FormInput
                          placeholder={"No. of Mails"}
                          onChange={handleChange}
                          name="numberOfMails"
                          value={selectedPayload?.numberOfMails}
                        />
                        <FormInput
                          placeholder={"After Date"}
                          onChange={handleChange}
                          name="afterDate"
                          value={selectedPayload?.afterDate}
                          type={"date"}
                        />
                        <FormInput
                          placeholder={"Before Date"}
                          onChange={handleChange}
                          name="beforeDate"
                          value={selectedPayload?.beforeDate}
                          type={"date"}
                        />
                        <div className="col-span-2">
                          <Button
                            text={"Search"}
                            className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
                            onClick={() =>
                              get_google_mails({
                                ...selectedPayload,
                                id: selectedEmailAccount?._id,
                              })
                            }
                          />
                          <Button
                            text="Reset"
                            className="py-1.5 px-3 align-middle bg-menu text-white"
                            onClick={onReset}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                {emailList?.length === 0 ? (
                  <tr className="text-center border-0">
                    <td className="text-center border-0">
                      <strong>No Record found</strong>
                    </td>
                  </tr>
                ) : null}
                {emailList?.map((item) => {
                  return emailList?.length ? (
                    <tr
                      className={`overflow-x-auto text-black flex cursor-pointer items-center hover:bg-whiten dark:hover:bg-boxdark-2 border-b border-neutral-300 shadow-sm ${
                        item?.isRead ? "bg-white" : "bg-neutral-200"
                      }`}
                      onClick={() => {
                        inboxDetails(item);
                      }}
                    >
                      <td className=" py-2 pl-4 pr-4 lg:pl-10 border-0 w-fit">
                        <div className="w-[200px] flex cursor-pointer select-none items-center text-sm font-medium sm:text-base">
                          <span className="font-semibold ml-3 text-sm truncate text-black">
                            {item.from}
                          </span>
                        </div>
                      </td>
                      <td className="w-[calc(100vw-800px)] text-sm truncate flex py-2 pl-4 pr-1 lg:pl-10 border-0 text-black">
                        <span className="truncat font-medium text-nowrap mr-1">
                          {item?.subject}
                          {" - "}
                        </span>
                        <p className="truncate text-blac text-sm max">
                          {renderContent(
                            decodeBase64UrlSafe(item?.mailcontent || "")
                          )}
                        </p>
                      </td>
                      <td className="min-w-[100px] py-2 pl-4 pr-4 lg:pr-10 border-0">
                        <p className="text-center text-xs xl:text-sm truncate">
                          {dayjs(item?.time).format("ddd, MMM D, YYYY h:mm A")}
                        </p>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td>No Record found</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          )
        ) : null}
      </div>
    </>
  );
};

export default GmailAccounts;
