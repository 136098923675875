import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  get_group_conversation,
  unreadSmsCountReset,
  updateRecentMsg,
} from "../../../features/smsCenterSlice";
import api from "../../../services/api";

function GroupChatHeads(props) {
  const { userId, setUserId, setIsLoadChat } = props;
  const { recentMsg } = useSelector((state) => state.msgCenter);
  const dispatch = useDispatch();
  const containerRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const handleLoadMore = async () => {
    const lastMsg = recentMsg[recentMsg?.length - 1]?.createdAt;
    const payload = { createdAt: lastMsg };
    setIsLoading(true);
    try {
      const res = await api.get("api/message_center/get_recent_message_list", {
        params: { ...payload },
      });
      if (res.status === 200) {
        if (res?.data?.length) {
          dispatch(updateRecentMsg(res.data));
        } else {
          setIsEnd(true);
        }
      }
    } catch (err) {
      console.log("🚀 ~ handleLoadMore ~ err:", err);
    }
    setIsLoading(false);
    // setCurrentPage((prevPage) => prevPage + 1);
  };
  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () => {
      if (
        container.scrollTop + container.clientHeight >=
          container.scrollHeight &&
        !isEnd &&
        !isLoading
      ) {
        handleLoadMore();
      }
    };
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [isEnd, isLoading]);
  return (
    <div className="h-[500px] overflow-y-auto" ref={containerRef}>
      {recentMsg?.map((data, index) => {
        let name = `${
          data?.vendor?.first_name ? data?.vendor?.first_name : ""
        } ${data?.vendor?.last_name ? data?.vendor?.last_name : ""}`;
        let text = name
          ? name
              .replaceAll("+", "")
              .split(" ")
              .slice(0, 2)
              .map((string) => (string ? string[0].toUpperCase() : ""))
          : "";
        const recordType =
          data?.role?.name ||
          (data?.client?._id
            ? "Client"
            : data?.vendor?._id
            ? "Vendor"
            : "Custom");

        return (
          <div
            className={`wms-hysearch-result border-bottom ${
              userId &&
              userId?.vendor?._id === data?.vendor?._id &&
              userId?.client?._id === data?.client?._id &&
              userId?.vendor_conversation_group_id ===
                data?.vendor_conversation_group_id &&
              userId?.user_phone === data?.user_phone
                ? "bg-[#f7f8f8] border-l-4 border-menu"
                : ""
            }`}
            onClick={() => {
              setUserId({
                ...data,
                user_type: data?.user?._id
                  ? "User"
                  : data?.client?._id
                  ? "Client"
                  : data?.vendor?._id && !data?.vendor_conversation_group_id
                  ? "Vendor"
                  : data?.vendor_conversation_group_id
                  ? "Group"
                  : "Custom",
              });
              setIsLoadChat(false)
              dispatch(
                get_group_conversation({
                  vendor_conversation_group_id:
                    data?.vendor_conversation_group_id,
                })
              );

              dispatch(
                unreadSmsCountReset(
                  data.user?._id
                    ? { id: data.user?._id, type: "User" }
                    : data.user?._id
                    ? { id: data.client?._id, type: "Client" }
                    : data.vendor?._id
                    ? { id: data.vendor?._id, type: "Vendor" }
                    : data?.vendor_conversation_group_id
                    ? { id: data.vendor_conversation_group_id, type: "Vendor" }
                    : { user_phone: data?.user_phone, type: "Custom" }
                )
              );
            }}
            key={index}
          >
            <div className="wms-imgcollage">
              <div className="wms-lettercoll">
                <span>{text}</span>
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="wms-hysearch-result-name wms-truncate-ellipsis">
                {name ? name : ""}{" "}
                <span
                  style={{ color: "#888", fontSize: 12 }}
                  className="wms-hysearch-result-des"
                >
                  {recordType}
                </span>
              </div>
              <span style={{ fontSize: 12, color: "#888888" }}>
                {dayjs(data.createdAt).format("D MMM")}
              </span>
            </div>
            <div className="wms-hysearch-result-desc wms-truncate-ellipsis">
              <span className="wmsgrey">
                {" "}
                {data?.message_type === "" ? "You:" : name + ":"}{" "}
              </span>
              {data.message}
            </div>
            {data?.unread_count > 0 && (
              <small className="wms_menu_item_count -mt-1 !ml-1! px-[5px] py-[2px] rounded-full bg-menu text-white">
                {data?.unread_count}
              </small>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default GroupChatHeads;
