import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  InfoWindow,
  OverlayView,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import dayjs from "dayjs";
import { FaBusinessTime } from "react-icons/fa";
import { SlUser, SlWrench } from "react-icons/sl";
import { FiPhone } from "react-icons/fi";
import { CiCalendarDate } from "react-icons/ci";
import { BiMap } from "react-icons/bi";
import { Button } from "../../components";
import GoogleScript from "../../components/molecules/GoogleMapScript";

function JobsOnMap({ onCancelModal, records }) {
  const { isLoading } = useSelector((state) => state.leads);
  // const [zoom, setZoom] = useState(8);

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const [coords, setCoords] = useState(
    records?.length > 0
      ? {
          lat: +records[0]?.pickup_latitude,
          lng: +records[0]?.pickup_longitude,
        }
      : {
          lat: 10.99835602,
          lng: 77.01502627,
        }
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(null);
  useEffect(() => {
    setCoords(
      records?.length > 0
        ? {
            lat: +records[0]?.pickup_latitude,
            lng: +records[0]?.pickup_longitude,
          }
        : {
            lat: 35.699599,
            lng: -116.9426161,
          }
    );
    // eslint-disable-next-line
  }, [records?.length]);
  const mapRef = useRef();
  // Add a useEffect to calculate and set bounds when records change
  // useEffect(() => {
  //   if (isLoaded && mapRef.current && records?.length > 0) {
  //     const bounds = new window.google.maps.LatLngBounds();
  //     records.forEach((job) => {
  //       bounds.extend({
  //         lat: +job?.pickup_latitude,
  //         lng: +job?.pickup_longitude,
  //       });
  //     });
  //     mapRef.current.fitBounds(bounds);
  //   }
  // }, [isLoaded, records,mapRef]);
  // Define a callback function for the onLoad event of GoogleMap
  const onLoadMap = (map) => {
    mapRef.current = map;

    // Calculate and set bounds here
    if (map && records?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      records.forEach((job) => {
        bounds.extend({
          lat: +job?.pickup_latitude,
          lng: +job?.pickup_longitude,
        });
      });
      map.fitBounds(bounds);
    }

    const coordinateMap = {};

    records.forEach((item) => {
      const { pickup_latitude, pickup_longitude, job_number } = item;
      const coordinateKey = `${pickup_latitude}-${pickup_longitude}`;

      // Check if the coordinate exists in the map
      if (coordinateMap[coordinateKey]) {
      } else {
        // If not, store the coordinate and ID in the map
        coordinateMap[coordinateKey] = job_number;
      }
    });

    const missingCoordinates = [];

    records.forEach((item) => {
      const { pickup_latitude, pickup_longitude, job_number } = item;

      if (pickup_latitude !== undefined && pickup_longitude !== undefined) {
        const coordinateKey = `${pickup_latitude}-${pickup_longitude}`;

        if (coordinateMap[coordinateKey]) {
          coordinateMap[coordinateKey]++;
        } else {
          coordinateMap[coordinateKey] = 1;
        }
      } else {
        missingCoordinates.push(job_number);
      }
    });
  };
  const groupedData = {};

  records?.forEach((job) => {
    const key = `${job.pickup_latitude}_${job.pickup_longitude}`;
    if (groupedData[key]) {
      groupedData[key].push(job);
    } else {
      groupedData[key] = [job];
    }
  });
  // Now, groupedDataArray contains your data grouped by pickup_latitude and pickup_longitude
  const optionsRef = React.useRef();

  function Modal({ jobNumbers }) {
    return (
      <div
        className={`absolute inset-x-0 bottom-[140%] left-[10px] w-[200px] z-[10000]`}
      >
        <div className="bg-white border-t-2 border-primary-500 rounded-t-lg p-4 shadow-md">
          <div className="flex justify-between items-center">
            <p className="text-normal font-semibold">Job Numbers</p>
            <button
              className="text-primary-500 hover:text-primary-700 focus:outline-none"
              onClick={() => {
                setModalIndex(null);
                setModalVisible(false);
              }}
            >
              Close
            </button>
          </div>
          <div className={styles.info_window}>
            <ul className="mt-2">
              {jobNumbers.map((job, index) => (
                <li
                  key={index}
                  className="py-1 cursor-pointer"
                  onClick={() => handleActiveMarker(job?.lead_num)}
                >
                  {job?.job_number}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      <div className="relative w-full md:max-w-[1224px] md:min-w-[730px]">
        <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
          <button
            onClick={onCancelModal}
            className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="z-10 w-full  max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white">
          <div className="pt-0 max-w-[1194px] h-full px-3.5 m-auto">
            <header className="bg-primary-100 h-[60px] flex items-center justify-center shadow-lg">
              <h1 className="text-2xl text-white m-4 font-medium">
                {records?.length} Jobs
              </h1>
            </header>
            <div className="md:p-4">
              <div className="">
                {!isLoading ? (
                  <GoogleScript>
                    <GoogleMap
                      center={coords}
                      zoom={8}
                      mapContainerStyle={{ width: "100%", height: "60vh" }}
                      options={{
                        zoomControl: true,
                        fullscreenControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                      }}
                      // onClick={() => setActiveMarker(null)}
                      onLoad={onLoadMap}
                      clickableIcons={false}
                    >
                      {Object.keys(groupedData).map((key, index) => {
                        const jobs = groupedData[key];
                        const location = key.split("_");
                        const activeJob = jobs.find(
                          (job) => job.lead_num === activeMarker
                        );
                        return (
                          <Fragment key={index}>
                            <Marker
                              position={{
                                lat: +location[0],
                                lng: +location[1],
                              }}
                              onClick={() =>
                                handleActiveMarker(jobs[0].lead_num)
                              }
                            >
                              {activeJob ? (
                                <InfoWindow
                                  onCloseClick={() => setActiveMarker(null)}
                                  position={{
                                    lat: +location[0],
                                    lng: +location[1],
                                  }}
                                >
                                  <div
                                    className={styles.info_window}
                                    ref={optionsRef}
                                  >
                                    <ul className="pl-2 ">
                                      <li>
                                        <FaBusinessTime
                                          className="mr-2"
                                          size={20}
                                        />
                                        Job #{" "}
                                        {activeJob?.job_number
                                          ? activeJob?.job_number
                                          : activeJob?.lead_num}
                                      </li>
                                      <li>
                                        <SlUser className="mr-2" size={20} />
                                        {activeJob?.name}
                                      </li>
                                      <li>
                                        <FiPhone className="mr-2" size={20} />
                                        {activeJob?.phone}
                                      </li>
                                      <li>
                                        <CiCalendarDate
                                          className="mr-2"
                                          size={20}
                                        />
                                        {activeJob?.scheduled_on
                                          ? dayjs(
                                              activeJob?.scheduled_on
                                            ).format("ddd, MMM D, YYYY h:mm A")
                                          : "No scheduled date selected"}
                                      </li>
                                      <li>
                                        <BiMap className="mr-2" size={20} />
                                        {activeJob?.complete_pickup_address}
                                      </li>
                                      <li>
                                        <SlWrench className="mr-2" size={20} />
                                        {activeJob?.job_category_id?.name}
                                      </li>
                                    </ul>
                                  </div>
                                </InfoWindow>
                              ) : null}
                            </Marker>
                            {(modalVisible &&
                              modalIndex === index &&
                              jobs.length > 1) ||
                            (!modalVisible &&
                              !modalIndex &&
                              jobs.length > 1) ? (
                              <OverlayView
                                position={{
                                  lat: +location[0],
                                  lng: +location[1],
                                }}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                              >
                                <>
                                  <div
                                    className=" bg-primary-10 bg-blue-500 text-white py-1 px-1.5 rounded-full cursor-pointer z-100 relative !mr-2"
                                    onClick={() => {
                                      setModalIndex(index);
                                      setModalVisible(true);
                                    }}
                                  >
                                    Jobs at this position: {jobs.length}
                                    {modalVisible && modalIndex === index ? (
                                      <div className="w-0 h-0 border-solid border-transparent border-[7px] border-b-blue-500 absolute bottom-100 left-1/2 rotate- z-[1000]"></div>
                                    ) : null}
                                  </div>
                                  {modalVisible && modalIndex === index && (
                                    <Modal jobNumbers={jobs} />
                                  )}
                                </>
                              </OverlayView>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </GoogleMap>
                  </GoogleScript>
                ) : null}
              </div>
              <div className="!p-4 text-right">
                <Button
                  text="Cancel"
                  className="mx-2"
                  onClick={onCancelModal}
                  variant="btn_cancel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobsOnMap;
// {records?.map((job, index) => {
//   return (
//     <>
//       {job?.pickup_latitude && job?.pickup_longitude && (
//         <Marker
//           position={{
//             lat: +job?.pickup_latitude,
//             lng: +job?.pickup_longitude,
//           }}
//           onClick={() => handleActiveMarker(job?.lead_num)}
//           icon={{
//             scaledSize: new window.google.maps.Size(24, 37),
//           }}
//           key={index}
//         >
//           {
//             <OverlayView
//             position={{
//               lat: +job?.pickup_latitude,
//               lng: +job?.pickup_longitude,
//             }}
//             mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
//           >
//             <div
//               className=" bg-primary-10 bg-blue-500 text-white py-1 px-1.5 rounded-full cursor-pointer z-100 relative !mr-2"
//               onClick={() =>
//                 handleActiveMarker(job?.lead_num)
//               }
//             >
//               Job Count at this lat lng
//             </div>
//           </OverlayView>
//           }
//           {activeMarker === job.lead_num ? (
//             <InfoWindow
//               onCloseClick={() => setActiveMarker(null)}
//               position={{
//                 lat: +job?.pickup_latitude,
//                 lng: +job?.pickup_longitude,
//               }}
//             >
//               <div
//                 className={styles.info_window}
//                 ref={optionsRef}
//               >
//                 <ul className="pl-2 ">
//                   <li>
//                     <FaBusinessTime
//                       className="mr-2"
//                       size={20}
//                     />
//                     Job #{" "}
//                     {job?.job_number
//                       ? job?.job_number
//                       : job?.lead_num}
//                   </li>
//                   <li>
//                     <SlUser className="mr-2" size={20} />
//                     {job?.name}
//                   </li>
//                   <li>
//                     <FiPhone className="mr-2" size={20} />
//                     {job?.phone}
//                   </li>
//                   <li>
//                     <CiCalendarDate
//                       className="mr-2"
//                       size={20}
//                     />
//                     {job?.scheduled_on
//                       ? dayjs(job?.scheduled_on).format(
//                           "ddd, MMM D, YYYY h:mm A"
//                         )
//                       : "No scheduled date selected"}
//                   </li>
//                   <li>
//                     <BiMap className="mr-2" size={20} />
//                     {job?.complete_pickup_address}
//                   </li>
//                   <li>
//                     <SlWrench className="mr-2" size={20} />
//                     {job?.job_category_id?.name}
//                   </li>
//                 </ul>
//               </div>
//             </InfoWindow>
//           ) : null}
//         </Marker>
//       )}
//     </>
//   );
// })}
