import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import api from "../../services/api";
import dayjs from "dayjs";
import {
  FaEdit,
  FaTrashAlt,
  FaEyeSlash,
  FaEye,
  FaSearch,
} from "react-icons/fa";
import { Button as MUIButton } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import Swal from "sweetalert2";
import { FaCopy } from "react-icons/fa";
import ToggleSwitch from "../../components/molecules/ToggleButton";
import { toast } from "react-toastify";
import { getFormattedDate } from "../../util/common";

const GoogleMapKeys = () => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [mapLists, setMapLists] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const getListing = async (filters) => {
    if (!filters?.filters?.rules?.length) {
      delete filters.filters;
    }
    const payload = {
      ...filters,
    };
    setIsLoading(true);
    try {
      const res = await api.post(`/api/googleMapKey/list`, payload);
      if (res.status === 200 || res.status === 201) {
        setMapLists(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error || "An error occurred");
      } else {
        toast.error(err?.response?.data || "An error occurred");
      }
    }
  };
  useEffect(() => {
    getListing({ page: 1, size: paginationModel.pageSize });

    // eslint-disable-next-line
  }, []);
  const [showKey, setShowKey] = useState("");
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    {
      field: "map_key",
      headerName: "Key",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div className="flex items-center justify-between min-w-[200px]">
            <span className="mr-2">
              {showKey === params.row.records?._id
                ? params.row.records.map_key
                : "*******"}
            </span>

            <div className="flex items-center ml-auto space-x-2">
              {showKey === params.row.records._id ? (
                <FaEye
                  className="my_navIcon cursor-pointer"
                  onClick={() => setShowKey("")}
                />
              ) : (
                <FaEyeSlash
                  className="my_navIcon cursor-pointer"
                  onClick={() => setShowKey(params.row.records._id)}
                />
              )}
              <FaCopy
                className="my_navIcon cursor-pointer"
                onClick={() => copyToClipboard(params.row.records.map_key)}
                title="Copy Key"
              />
            </div>
          </div>
        );
      },
    },
    {
      headerName: "Mail",
      field: "mail",
      minWidth: 200,
      filterable: false,
    },
    {
      headerName: "Status",
      field: "active",
      width: 200,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: [
        { label: "Active", value: true },
        { label: "In Active", value: false },
      ],
      renderCell: (params) => {
        const { active } = params.row;
        return (
          <ToggleSwitch
            checked={active}
            unique_by={params?.row?.records?._id}
            onChange={() => {
              handleActiveStatus(params?.row?.records?._id);
            }}
          />
        );
      },
    },
    {
      headerName: "Created At",
      field: "created_at",
      minWidth: 200,
      filterable: false,
    },
    {
      headerName: "Disabled on",
      field: "disabled_on",
      minWidth: 200,
      filterable: false,
    },
    {
      headerName: "Actions",
      field: "actions",
      minWidth: 150,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="flex flex-row gap-x-2 items-center">
            <div className="flex flex-row">
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Key"
              />
              <FaTrashAlt
                onClick={() => deleteRecordHandler(params.row.records._id)}
                className="my_navIcon"
                title="Delete Key"
              />
            </div>
          </div>
        );
      },
    },
  ];

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getListing({
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getListing({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getListing({
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };

  const copyToClipboard = (key) => {
    navigator.clipboard
      .writeText(key)
      .then(() => {
        toast.success("Key copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy key!");
        console.error("Copy to clipboard failed: ", err);
      });
  };

  const onFormSubmit = () => {
    setIsEditing(false);
    getListing({
      page: 1,
      size: paginationModel.pageSize,
      filters: queryOptions,
    });
  };

  const deleteRecordHandler = async (recordId) => {
    const c = window.confirm(`Are you sure you want to delete this KEY?`);
    if (!c) return;
    setIsLoading(true);
    try {
      const res = await api.delete(`/api/googleMapKey/${recordId}`);
      if (res?.status === 200) {
        toast.success("Google Map Key deleted successfully");
        getListing({
          page: 1,
          size: paginationModel.pageSize,
          filters: queryOptions,
        });
      } else {
        toast.error("Google Map Key couldn't be deleted");
      }
    } catch (err) {
      toast.error("Google Map Key couldn't be deleted");
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleActiveStatus = (recordId) => {
    Swal.fire({
      title: "Are you sure to perform this action?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      customClass: {
        popup: "w-90 p-4 text-sm",
        title: "text-lg font-bold",
        content: "text-sm font-semibold",
        confirmButton: "text-sm py-2 px-4",
        cancelButton: "text-sm py-2 px-4",
      },
    }).then(async (result) => {
      if (!result.isConfirmed) return;
      setIsLoading(true);
      try {
        const res = await api.put(`/api/googleMapKey/mark_active/${recordId}`);
        if (res.status === 200) {
          toast.success("Google Map API key activated successfully");
          getListing({
            page: 1,
            size: paginationModel.pageSize,
            filters: queryOptions,
          });
        } else {
          toast.error(
            res?.data?.message || "Google Map API key couldn't be activated"
          );
        }
      } catch (err) {
        toast.error(
          err?.response?.data?.message ||
            err?.message ||
            "Google Map API key couldn't be activated"
        );
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    });
  };

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Key"
          onCancelForm={cancelFormHandler}
          onFormSubmit={onFormSubmit}
        />
      )}
      <PageHeader
        heading="Google Map Keys Listing"
        onClick={openFormHandler(0)}
        isAllowed={true}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={mapLists?.records?.map((record, index) => {
            let counter = index + offset + 1;
            let created_at = dayjs(record?.createdAt).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            const { map_key, active, mail, disabled_on } = record;
            return {
              records: record,
              counter,
              map_key,
              active,
              created_at,
              mail,
              disabled_on: getFormattedDate(disabled_on),
            };
          })}
          searchText={searchText}
          totalItems={mapLists?.totalItems}
          setSearchText={setSearchText}
          searchable="No"
          isLoading={isLoading}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
        />
      </div>
    </>
  );
};

export default GoogleMapKeys;
