import api from "./api";

export const getApiUser = async () => {
  return await api.get(`/api/api_users`);
};
export const getApiUserDrd = async () => {
  return await api.get(`/api/api_users/drd`);
};
export const createApiUser = async (data) => {
  return await api.post("/api/api_users", data);
};

export const toggleActive = async (id) => {
  return await api.patch(`/api/api_users/toggle_active/${id}`);
};

export const resendEmail = async (id) => {
  return await api.patch(`/api/api_users/resend_email/${id}`);
};
