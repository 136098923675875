import React, { useState, useRef, useEffect } from "react";
import { FaInfoCircle, FaChevronDown } from "react-icons/fa";
import CustomModal from "../../components/molecules/Modal/customModal";
import { DotsLoader } from "../../components";
import api from "../../services/api";
import { toast } from "react-toastify";

const JobStatusModal = ({
  onCancelForm,
  modalTitle,
  selectedData,
  onFormSubmit,
  jobStatus,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); 
  const [selectedItemName, setSelectedItemName] = useState(""); 
  const [openParents, setOpenParents] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useRef(null);

  const handleChildChange = (childId, childName, isChecked) => {
    if (isChecked) {
      setSelectedItem(childId);
      setSelectedItemName(childName);
    } else {
      setSelectedItem(null);
      setSelectedItemName(""); 
    }
  };

  const toggleParentVisibility = (parentId) => {
    setOpenParents((prevState) => ({
      ...prevState,
      [parentId]: !prevState[parentId],
    }));
  };

  const toggleDropdown = () => {
    setIsOpen((prevState) => {
      const newState = !prevState;
      if (!newState) {
        setOpenParents({});
      }
      return newState;
    });
  };

  const handleSubmit = async () => { 
    if (!selectedData?.length || !selectedItem) {
      toast.error(
        !selectedData?.length
          ? "No jobs selected to update the status."
          : "Please select a job status."
      );
      return;
    }
    try {
      setLoading(true);
      const payload = {
        leadIds: selectedData?.map((item) => item._id),
        job_status_id: selectedItem,
      };
      const res = await api.put("/api/jobs/update_job_status", payload);
      if (res.status === 200 || res.status === 201) {
        toast.success("Job status updated successfully!");
        onFormSubmit();
      } else {
        toast.error("Failed to update job status.");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      } else {
        toast.error(err?.response?.data);
      }
      console.error(err);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
        setOpenParents({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {isLoading && <DotsLoader />}
      <CustomModal
        handleModal={onCancelForm}
        cancelButtonRef={onCancelForm}
        className="max-w-3xl h-[500px]"
        modalType={false}
        title={modalTitle}
        onSubmit={handleSubmit}
      >
        <div
          className="flex items-center p-2 mb-3 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
          role="alert"
        >
          <FaInfoCircle />
          <div>
            <span className="font-medium text-sm ml-2">
              You have selected {selectedData?.map((item) => item._id)?.length}{" "}
              job(s) to update the status.
            </span>
          </div>
        </div>

        <form>
          <div className="grid md:grid-cols-1 gap-4 w-3/5">
            <div className="relative" ref={selectRef}>
              <div
                className="border border-gray-300 rounded-lg p-2 cursor-pointer flex justify-between items-center"
                onClick={toggleDropdown}
              >
                <span className="block text-lg">
                  {selectedItemName || "Select Status"}{" "}
                </span>
                <FaChevronDown
                  className={`transform transition-transform ${
                    isOpen ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>

              {isOpen && (
                <div className="absolute left-0 right-0 bg-white border border-gray-300 rounded-lg mt-2 max-h-64 overflow-y-auto shadow-lg">
                  <div className="p-4 space-y-2">
                    {jobStatus.map((parent) => (
                      <div key={parent._id}>
                        <div
                          className="font-medium text-lg cursor-pointer flex justify-between items-center border-b pb-2 mb-2"
                          onClick={() => toggleParentVisibility(parent._id)}
                        >
                          <span className="text-sm">{parent.name}</span>
                          <span className="text-gray-500 text-sm">
                            ({parent.totalJobCount} jobs)
                          </span>
                          <FaChevronDown
                            className={`ml-2 transform transition-transform ${
                              openParents[parent._id]
                                ? "rotate-180"
                                : "rotate-0"
                            }`}
                          />
                        </div>

                        {openParents[parent._id] &&
                          parent.child &&
                          parent.child.length > 0 && (
                            <div className="ml-6 mt-2 space-y-2">
                              {parent.child.map((child) => (
                                <div
                                  key={child._id}
                                  className="flex items-center space-x-2 border-b pb-2 mb-2 cursor-pointer"
                                  onClick={() =>
                                    handleChildChange(
                                      child._id,
                                      child.name, 
                                      selectedItem !== child._id
                                    )
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectedItem === child._id}
                                    onChange={(e) =>
                                      handleChildChange(
                                        child._id,
                                        child.name,
                                        e.target.checked
                                      )
                                    }
                                    className={`h-4 w-4 border-gray-300 rounded ${
                                      selectedItem === child._id
                                        ? "bg-blue-600"
                                        : ""
                                    }`}
                                  />
                                  <span className="text-sm">{child.name}</span>
                                  <span className="text-gray-500 text-xs">
                                    ({child.jobCount} jobs)
                                  </span>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-3 flex justify-end absolute bottom-4 right-4 mr-2 mb-4">
            <button
              type="button"
              onClick={handleSubmit}
              className="bg-primary-100 text-white text-sm rounded px-4 py-2 font-semibold"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={onCancelForm}
              className="bg-black text-white text-sm rounded px-4 py-2 font-semibold ml-2"
            >
              Cancel
            </button>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default JobStatusModal;
