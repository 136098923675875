import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createJobType,
  deleteJobType,
  getJobTypes,
  updateJobType,
} from "../services/jobTypes";

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  record: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Job types Data
export const getJobTypesData = createAsyncThunk(
  "/api/get_job_types",
  async (thunkAPI) => {
    try {
      return await getJobTypes();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Job types
export const createNewJobTypes = createAsyncThunk(
  "/api/create_job_types",
  async (data, thunkAPI) => {
    try {
      return await createJobType(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Job types
export const updateJobTypes = createAsyncThunk(
  "/api/update_job_types",
  async (data, thunkAPI) => {
    try {
      return await updateJobType(data?.id, data?.name);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Job types
export const deleteJobTypes = createAsyncThunk(
  "/api/delete_job_types",
  async (id, thunkAPI) => {
    try {
      return await deleteJobType(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const jobTypesSlice = createSlice({
  name: "getJobTypes",
  initialState,
  reducers: {
    jobTypesReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobTypesData.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(getJobTypesData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(getJobTypesData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createNewJobTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewJobTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.records.push(action.payload.data);
      })
      .addCase(createNewJobTypes.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateJobTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateJobTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.records.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result] = {
          ...state.record.records[result],
          ...action.payload.data,
        };
      })
      .addCase(updateJobTypes.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteJobTypes.fulfilled, (state, action) => {
        const result = state.record.records.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result] = {
          ...state.record.records[result],
          ...action.payload.data,
        };
      });
  },
});
export const { jobTypesReset } = jobTypesSlice.actions;
export default jobTypesSlice.reducer;
