import React from "react";
import dayjs from "dayjs";
import { marked } from "marked";
import { Modal } from "../../../components";
const EmailDetail = ({ onCancelForm, selectedEmail }) => {
  const preprocessContent = (content) => {
    if (!content) {
      return content;
    }
    const lines = content.split("\n");
    const urlLineRegex = /\bhttps?:\/\/[^\s]+/g;
    const processedLines = lines.map((line) => {
      const match = line.match(urlLineRegex);
      if (match) {
        const url = match[0];
        const domain = url.replace(/https?:\/\/(www\.)?/, "").split("/")[0];
        return `[${domain}](${url})`;
      }
      return line;
    });
    return processedLines.join("\n");
  };

  const renderContent = (content) => {
    if (!content) {
      return "";
    }
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(content);
    if (isHTML) {
      return content;
    }
    // const isMarkdown2 =
    //   /(^#|[-*_]{3,}|!\[.*\]\(.*\)|\*|\n|\[[^\]]+\]\([^\)]+\))/.test(content);
    const isMarkdown =
      /(^#|[-*_]{3,}|!\[.*\]\(.*\)|\*|\n|\[[^\]]+\]\([^)]+\))/.test(content);
    if (isMarkdown) {
      const preprocessedContent = preprocessContent(content);
      const htmlContent = marked(preprocessedContent);
      return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    }
    return <pre>{content}</pre>;
  };
  function decodeBase64UrlSafe(base64String) {
    let normalizedBase64 = base64String.replace(/-/g, "+").replace(/_/g, "/");
    while (normalizedBase64.length % 4 !== 0) {
      normalizedBase64 += "=";
    }
    try {
      const byteArray = Uint8Array.from(atob(normalizedBase64), (c) =>
        c.charCodeAt(0)
      );
      const finalResult = new TextDecoder("utf-8").decode(byteArray);
      return finalResult;
    } catch (e) {
      console.error("Invalid Base64 input:", e);
      return null;
    }
  }
  return (
    <>
      <Modal
        onCancelModal={onCancelForm}
        onClick={onCancelForm}
        modalClass="md:min-w-[80vw] !max-h-[calc(100vh-90px)]"
        modalType={true}
        hideHeader
        hideButtons={true}
      >
        <div className="mt- !max-h-[calc(100vh-150px)] overflow-y-scroll">
          <div className="h-full !p-4 bg-neutral-100">
            <div className="flex justify-between items-center">
              <h5 className="font-bold">
                {selectedEmail && selectedEmail.subject
                  ? selectedEmail.subject
                  : ""}
              </h5>
              <div className="flex gap-2">
                <span>
                  {selectedEmail && selectedEmail.time
                    ? dayjs(selectedEmail.time).format(
                        "ddd, MMM D, YYYY h:mm A"
                      )
                    : ""}
                </span>
              </div>
            </div>
            <div className="flex gap-3 mt-1">
              <span className="font-lg font-semibold">
                {selectedEmail && selectedEmail.from ? selectedEmail.from : ""}
              </span>
            </div>
            <div className="p-2 mt-3">
              <div className="">
                <iframe
                  srcDoc={renderContent(
                    decodeBase64UrlSafe(selectedEmail?.mailcontent || "")
                  )}
                  className="w-full h-[65vh] border-none"
                  sandbox="allow-same-origin allow-scripts allow-popups"
                  title="Email Content"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EmailDetail;
