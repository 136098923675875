import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { getMonth } from "../util";
import { useDispatch, useSelector } from "react-redux";
import {
  setDaySelected,
  setMonthIndex,
  setSmallCalendarMonth,
} from "../../../../features/calendarEventSlice";

export default function SmallCalendar() {
  const { monthIndex, daySelected } = useSelector(
    (state) => state?.calendarEvents
  );
  const dispatch = useDispatch();
  const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());

  const [currentMonth, setCurrentMonth] = useState(getMonth());
  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIdx));
  }, [currentMonthIdx]);
  useEffect(() => {
    setCurrentMonthIdx(monthIndex);
  }, [monthIndex]);

  // function handlePrevMonth() {
  //   setCurrentMonthIdx(currentMonthIdx - 1);
  // }
  // function handleNextMonth() {
  //   setCurrentMonthIdx(currentMonthIdx + 1);
  // }
  function handlePrevMonth() {
    setCurrentMonthIdx(currentMonthIdx - 1);
    dispatch(setMonthIndex(monthIndex - 1));
  }
  function handleNextMonth() {
    setCurrentMonthIdx(currentMonthIdx + 1);
    dispatch(setMonthIndex(monthIndex + 1));
  }
  function getDayClass(day) {
    const format = "DD-MM-YY";
    const nowDay = dayjs().format(format);
    const currDay = day.format(format);
    const slcDay = daySelected && daySelected.format(format);
    if (nowDay === currDay) {
      return "bg-blue-500 rounded-full text-white";
    } else if (currDay === slcDay) {
      return "bg-blue-100 rounded-full text-blue-600 font-bold font-sans";
    } else {
      return "";
    }
  }
  return (
    <div className="!mt-4">
      <header className="flex justify-between">
        <p className="text-gray-500 font-bold font-sans">
          {dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM YYYY")}
        </p>
        <div>
          <button onClick={handlePrevMonth}>
            <span className="material-icons-outlined cursor-pointer text-gray-600 !mx-2">
              <MdChevronLeft size={24} />
            </span>
          </button>
          <button onClick={handleNextMonth}>
            <span className="material-icons-outlined cursor-pointer text-gray-600 !mx-2">
              <MdChevronRight size={24} />
            </span>
          </button>
        </div>
      </header>
      <div className="grid grid-cols-7 grid-rows-6">
        {currentMonth[0].map((day, i) => (
          <span key={i} className="text-sm py-1 text-center">
            {day.format("dd").charAt(0)}
          </span>
        ))}
        {currentMonth.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, idx) => (
              <button
                key={idx}
                onClick={() => {
                  dispatch(setSmallCalendarMonth(currentMonthIdx));
                  dispatch(setDaySelected(day));
                }}
                className={`!py-1 w-full ${getDayClass(day)}`}
              >
                <span className="text-sm">{day.format("D")}</span>
              </button>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
