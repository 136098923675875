import React from "react";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import api from "../../../services/api";
import { DotsLoader } from "../../../components";
import { FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";

function Voicemail({ setVoicemailCount }) {
  const [isLoader, setIsLoader] = React.useState(false);
  const [voicemailList, setVoicemailList] = React.useState([]);
  const get_docusign_template_list = async () => {
    setIsLoader(true);
    try {
      const res = await api.get(`/api/cc/admin_fun/vm/${"242"}`);
      if (res.status === 200) {
        setVoicemailList(res.data);
        setVoicemailCount(
          res.data?.filter(({ link_accessed }) => !link_accessed)?.length || 0
        );
      }
      setIsLoader(false);
    } catch (err) {
      console.log(err);
      setIsLoader(false);
    }
  };
  React.useEffect(() => {
    get_docusign_template_list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await api.delete(`/api/cc/admin_fun/vm/${record_id}`);
        if (res?.status === 200 || res?.status === 201) {
          toast.success(res?.data?.message || "Voicemail deleted successfully");
        } else {
          errorMessage({
            payload: res?.data?.message,
            action: "Voicemail",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error?.response?.data?.message,
          action: "Voicemail",
          msg: "deleted",
        });
      }
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Created At", field: "created", minWidth: 120, flex: 1 },
    {
      headerName: "Recording",
      field: "createdAt",
      flex: 1,
      renderCell: (params) => {
        return (
          <audio
            id={params?.row?.link}
            controls
            className="h-10 w-[250px] relative"
          >
            {params.row?.link && (
              <source
                src={params?.row?.link}
                type="audio/mpeg"
                // onClick={() => handlePlay(params.row.records?._id)}
              />
            )}
          </audio>
        );
      },
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          <FaTrashAlt
            onClick={deleteRecordHandler(params?.row?.records?._id)}
            className="my_navIcon"
            title="Delete Affiliate"
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      {isLoader ? <DotsLoader /> : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={voicemailList?.map((record, index) => {
            const { id, link, link_accessed, created } = record;
            return {
              counter: index + 1,
              records: record,
              id,
              link,
              link_accessed,
              created,
            };
          })}
          searchable="No"
          pagination="No"
        />
      </div>
    </div>
  );
}

export default Voicemail;
