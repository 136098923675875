import api from "./api";

export const getLoginLogs = async (data) => {
  return await api.get("/api/reports/login/logs", { params: data });
};
export const getLastTouch = async (data) => {
  return await api.get("/api/reports/last_touch_report", { params: data });
};
export const getAPIsReport = async (data) => {
  return await api.post("/api/reports/api_req_res_report", data);
};
export const getCommissionReport = async (data) => {
  return await api.get("/api/reports/commission_report", { params: data });
};
export const getInboundReport = async (data) => {
  return await api.get("/api/reports/inbound_report_new", { params: data });
};
export const getInboundForwardingReport = async (data) => {
  let payload = { ...data };
  for (const key in payload) {
    if (payload[key] === null) {
      payload[key] = "";
    }
  }
  return await api.post("/api/cc/dids/call_forwarding_report", payload);
};
export const getAffiliateReport = async (data) => {
  let payload = { ...data };
  for (const key in payload) {
    if (payload[key] === null) {
      payload[key] = "";
    }
  }
  return await api.post("/api/cc/dids/call_inbound_report", payload);
};
export const getDIDDrd = async () => {
  return await api.get("/api/cc/dids/get_lead_dids_and_did_groups");
};
export const getClosingReport = async (data) => {
  return await api.get("/api/reports/closing_report", { params: data });
};
export const getAOODataReport = async (data) => {
  return await api.get("/api/reports/ooa_status_report", { params: data });
};
export const getAOODataFilters = async (data) => {
  return await api.post("/api/reports/ooa_status_report_filter", data);
};
export const getWebQueryReport = async (data) => {
  return await api.post("/api/web_queries", data);
};
export const getLeadSourceWebQueryReport = async (data) => {
  return await api.post("api/web_queries/lead_source_web_form", data);
};
export const deleteWebQuery = async (data) => {
  return await api.post(`/api/web_queries/remove`,data);
};
