import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import { filterPayload } from "../../util/filterPayload";
import { create_gmail_accounts, get_gmail_accounts, update_gmail_accounts } from "../../features/gmailAccountSlice";
const GoogleConsoleForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.googleEmails);
  const [errors, setErrors] = React.useState([]);

  const initialValues = {
    email: "",
    ...(editingRecord && {
      id: editingRecord?._id,
      email: editingRecord?.email,
    }),
  };
  function validateForm(data) {
    const errors = [];
    if (!data.email) {
      errors.push({
        email: "email is not allowed to be empty",
      });
    }
    return errors;
  }
  const handleSubmit = async (values) => {
    const action = editingRecord
      ? update_gmail_accounts
      : create_gmail_accounts;
    const message = editingRecord ? "updated" : "added";
    const errors = validateForm(values);
    if (errors?.length) {
      return errorMessage({
        payload: errors,
        setErrors: setErrors,
        action: "Email",
        msg: message,
      });
    }
    let payload = filterPayload(values);
    try {
      const res = await dispatch(action(payload));
      console.log("👊 ~ handleSubmit ~ res:", res)
      if (res.payload?.status === 200 || res.payload?.status === 201) {
        toast.success(`Email ${message} successfully`);
        dispatch(get_gmail_accounts());
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload
              ? res.payload
              : `Email couldn't be ${message} successfully`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload
            ? error.payload
            : `Email couldn't be ${message} successfully`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt- grid grid-cols-1 gap-3">
          <FormInput
            errors={errors}
            name="email"
            label="Email"
            formik={formik}
            required
          />
        </form>
      </Modal>
    </>
  );
};

export default GoogleConsoleForm;
