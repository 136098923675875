import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import allowedIpReducer from "../features/allowedipSlice";
import merchantsReducer from "../features/merchantSlice";
import jobTypesReducer from "../features/jobTypesSlice";
import usersReducer from "../features/usersSlice";
import timezoneReducer from "../features/timezoneSlice";
import countriesReducer from "../features/countriesSlice";
import tagsReducer from "../features/tagsSlice";
import emailTemplateReducer from "../features/emailTemplateSlice";
import leadsReducer from "../features/leadsSlice";
import jobsReducer from "../features/jobsSlice";
import cardTypesReducer from "../features/cardTypesSlice";
import monthsReducer from "../features/monthsSlice";
import paymentGatewayReducer from "../features/paymentGatewaySlice";
import jobStatusReducer from "../features/jobStatusSlice";
import declineReasonReducer from "../features/declineReasonSlice";
import smsTemplateReducer from "../features/smsTemplateSlice";
import reportsReducer from "../features/reportsSlice";
import rolesReducer from "../features/rolesSlice";
import IndustryReducer from "../features/IndustrySlice";
import jobCategoryReducer from "../features/jobCategorySlice";
import agentlessFormReducer from "../features/agentlessForm";
import quotePricingReducer from "../features/quotePricingSlice";
import dialerReducer from "../features/dialerSlice";
import inboundGroupReducer from "../features/inboundGroupSlice";
import campaignReducer from "../features/campaignSlice";
import didReducer from "../features/didSlice";
import generalSettingReducer from "../features/generalSettingSlice";
import providerReducer from "../features/ProviderSlice";
import callCenterReducer from "../features/callCentersSlice";
import tasksReducer from "../features/tasksSlice";
import messageCenterSlice from "../features/messageCenterSlice";
import geoLocSlice from "../features/geoLocSlice";
import geoIndustrySlice from "../features/GeoIndustrySlice";
import geoVendorsSlice from "../features/GeoVendorsSlice";
import TldsSlice from "../features/TldSlice";
import registrarSlice from "../features/registrarSlice";
import domainCenterSlice from "../features/domainCenterSlice";
import vendorIndustrySlice from "../features/vendorIndustrySlice";
import conversationSlice from "../features/conversationSlice";
import linkReportSlice from "../features/linkReportSlice";
import purchaseRegistrarSlice from "../features/purchaseRegistrarSlice";
import registrarAccountsSlice from "../features/registrarAccountsSlice";
import dashboardSlice from "../features/dashboardSlice";
import googleConsoleSlice from "../features/googleConsoleSlice";
import googleEmailSlice from "../features/googleEmailSlice";
import gscServerSlice from "../features/serverSlice";
import nationSitesSlice from "../features/nationSitesSlice";
import domainReportSlice from "../features/domainsActiveReportSlice";
import domainIndexReportSlice from "../features/domainsIndexReportSlice";
import databaseSlice from "../features/databaseSlice";
import uptimeRobotSlice from "../features/uptimeRobotSlice ";
import BatchListReducer from "../features/batchListSlice";
import systemJobStatusSlice from "../features/systemJobStatusSlice";
import SmsCenterSlice from "../features/smsCenterSlice";
import apiUserSlice from "../features/apiUserSlice";
import { injectDispatch } from "../util/reduxUtils";
import gmailSlice from "../features/gmailAccountSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    allowedIP: allowedIpReducer,
    merchant: merchantsReducer,
    jobTypes: jobTypesReducer,
    users: usersReducer,
    timezone: timezoneReducer,
    countries: countriesReducer,
    tags: tagsReducer,
    emailTemplate: emailTemplateReducer,
    leads: leadsReducer,
    jobs: jobsReducer,
    cardTypes: cardTypesReducer,
    months: monthsReducer,
    paymentGateway: paymentGatewayReducer,
    jobStatus: jobStatusReducer,
    systemJobStatus: systemJobStatusSlice,
    declineReason: declineReasonReducer,
    smsTemplate: smsTemplateReducer,
    reports: reportsReducer,
    roles: rolesReducer,
    industry: IndustryReducer,
    jobCategory: jobCategoryReducer,
    agentlessForm: agentlessFormReducer,
    quotePricing: quotePricingReducer,
    dialer: dialerReducer,
    inbound: inboundGroupReducer,
    campaigns: campaignReducer,
    dids: didReducer,
    generalSetting: generalSettingReducer,
    provider: providerReducer,
    callCenters: callCenterReducer,
    tasks: tasksReducer,
    msgCenter: messageCenterSlice,
    geoLoc: geoLocSlice,
    geoIndustry: geoIndustrySlice,
    geoVendors: geoVendorsSlice,
    tld: TldsSlice,
    registrar: registrarSlice,
    domainCenter: domainCenterSlice,
    vendorIndustries: vendorIndustrySlice,
    siteConversation: conversationSlice,
    linkReport: linkReportSlice,
    purchaseRegistrar: purchaseRegistrarSlice,
    registrarAccounts: registrarAccountsSlice,
    dashboard: dashboardSlice,
    googleConsole: googleConsoleSlice,
    googleEmails: googleEmailSlice,
    servers: gscServerSlice,
    nation: nationSitesSlice,
    domainReport: domainReportSlice,
    domainIndexReport: domainIndexReportSlice,
    database: databaseSlice,
    uptime: uptimeRobotSlice,
    batchList: BatchListReducer,
    smsCenter: SmsCenterSlice,
    apiUsers: apiUserSlice,
    gmailAccounts: gmailSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

injectDispatch(store.dispatch);
export default store;
