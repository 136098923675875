import React from "react";
import { FormInput, FormSelectInput, Modal } from "../../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  create_inbound_group,
  update_inbound_group,
} from "../../../features/inboundGroupSlice";
import { toast } from "react-toastify";
function AddUpdateForm({
  editingRecord,
  onCancelForm,
  modalTitle,
  onSave,
  newRecord,
}) {
  const { isLoading, record } = useSelector((state) => state.inbound);
  const { callMenu } = useSelector((state) => state.callCenters);
  const [queuePriority, setQueuePriority] = React.useState();
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);
  const initialValues = {
    group_name: "",
    group_color: "Green",
    active: "Y",
    queue_priority: "0",
    drop_call_seconds: "3600",
    icbq_call_time_id: "24hours",
    drop_action: "",
    voicemail_ext: "",
    drop_inbound_group: "",
    drop_callmenu: "",
    call_time_id: "24hours",
    no_agent_no_queue: "N",
    no_agent_action: "EXTENSION",
    in_queue_nanque: "N",
    EXextension_no_agent_action: "8304",
    EXcontext_no_agent_action: "default",
    no_agent_action_value: "",
    IGgroup_id_no_agent_action: "",
    no_agent_delay: 0,
    ...(editingRecord && {
      id: editingRecord?.group_id,
      group_name: editingRecord?.group_name,
      group_color:
        editingRecord?.group_color?.toLowerCase() === "green"
          ? "#008000"
          : editingRecord.group_color,
      active: editingRecord?.active,
      queue_priority: editingRecord.queue_priority,
      drop_call_seconds: editingRecord?.drop_call_seconds,
      icbq_call_time_id: editingRecord?.icbq_call_time_id,
      drop_action: editingRecord?.drop_action,
      voicemail_ext: editingRecord?.voicemail_ext,
      drop_inbound_group: editingRecord?.drop_inbound_group,
      drop_callmenu: editingRecord?.drop_callmenu,
      no_agent_no_queue: editingRecord?.no_agent_no_queue,
      IGgroup_id_no_agent_action:
        editingRecord?.no_agent_action === "INGROUP"
          ? editingRecord?.no_agent_action_value?.replace(
              ",CID,LB,TESTCAMP,998,1",
              ""
            )
          : "",
      no_agent_action:
        editingRecord?.no_agent_action === "MESSAGE"
          ? "EXTENSION"
          : editingRecord?.no_agent_action,
      in_queue_nanque: editingRecord?.in_queue_nanque,
      EXextension_no_agent_action:
        editingRecord?.no_agent_action === "EXTENSION"
          ? editingRecord?.no_agent_action_value?.replace(",default", "")
          : "8304",

      no_agent_action_did_value:
        editingRecord?.no_agent_action === "DID"
          ? editingRecord?.no_agent_action_value
          : "",
      no_agent_action_call_menu_value:
        editingRecord?.no_agent_action === "CALLMENU"
          ? editingRecord?.no_agent_action_value
          : "",
      EXcontext_no_agent_action: editingRecord?.EXcontext_no_agent_action,
      no_agent_action_value:
        editingRecord?.no_agent_action === "MESSAGE"
          ? ""
          : editingRecord?.no_agent_action_value,

      no_agent_delay: editingRecord?.no_agent_delay || 0,
    }),
  };
  const handleSubmit = async (values) => {
    const action = editingRecord ? update_inbound_group : create_inbound_group;
    const message = editingRecord ? "updated" : "created";
    const payload = { ...values };
    if (payload?.no_agent_action === "EXTENSION") {
      payload.no_agent_action_value = `${payload?.EXextension_no_agent_action},default`;
    }
    if (payload?.no_agent_action === "INGROUP") {
      payload.no_agent_action_value = `${payload?.IGgroup_id_no_agent_action},CID,LB,TESTCAMP,998,1`;
    }
    if (payload?.no_agent_action === "CALLMENU") {
      payload.no_agent_action_value = `${payload?.no_agent_action_call_menu_value}`;
    }
    if (payload?.no_agent_action === "DID") {
      payload.no_agent_action_value = `${payload?.no_agent_action_did_value}`;
    }
    delete payload.no_agent_action_did_value;
    delete payload.no_agent_action_call_menu_value;
    delete payload.EXextension_no_agent_action;
    delete payload.IGgroup_id_no_agent_action;

    try {
      const res = await dispatch(action(payload));
      if (res.payload?.status === 200) {
        if (!res.payload?.data?.status) {
          toast.error(
            `CC Error: ${
              res.payload?.data?.message ||
              `Inbound Group couldn't be ${message}`
            }`
          );
        } else {
          toast.success(`Inbound Group successfully  ${message}`);
          onSave(!newRecord);
          onCancelForm();
        }
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload ? res.payload : `Inbound Group couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `Inbound Group couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  React.useEffect(() => {
    const priority = [];
    for (let i = 99; i >= -99; i--) {
      const label =
        i === 0
          ? `${i} - Even`
          : i > 0
          ? `${i} - Higher`
          : i < 0
          ? `${i} - Lower`
          : "";
      priority.push({ label, value: i });
    }
    setQueuePriority(priority);
  }, []);
  const dropActions = [
    { value: "HANGUP", label: "Hangup" },
    { value: "IN_GROUP", label: "Ring Group" },
    { value: "CALLMENU", label: "Call Menu" },
    { value: "VOICEMAIL", label: "Voicemail" },
  ];
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      <form className="grid md:grid-cols-4 gap-x-3 mt-5 mx-2.5">
        <div className="mb-3">
          <FormInput
            errors={errors}
            name="group_name"
            label="Group Name"
            formik={formik}
          />
        </div>
        <div className="mb-3">
          <FormInput
            errors={errors}
            name="group_color"
            label="Group Color"
            formik={formik}
            type="color"
          />
        </div>
        <div className="mb-3">
          <FormSelectInput
            errors={errors}
            name="active"
            label="Active"
            formik={formik}
            options={[
              { value: "Y", label: "Y" },
              { value: "N", label: "N" },
            ]}
            valueProp="value"
            labelProp="label"
          />
        </div>

        <div className="mb-3">
          <FormSelectInput
            errors={errors}
            name="queue_priority"
            label="Queue Priority"
            options={queuePriority}
            formik={formik}
            valueProp="value"
            labelProp="label"
          />
        </div>

        <div className="mb-3">
          <FormInput
            errors={errors}
            name="drop_call_seconds"
            label="Drop Call Seconds"
            formik={formik}
          />
        </div>
        <div className="mb-3">
          <FormSelectInput
            errors={errors}
            name="drop_action"
            label="Drop Action"
            options={dropActions}
            formik={formik}
            valueProp="value"
            labelProp="label"
          />
        </div>
        {formik.values.drop_action === "VOICEMAIL" ||
        formik.values.drop_action === "VMAIL_NO_INST" ? (
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="voicemail_ext"
              label="Voicemail Ext"
              formik={formik}
            />
          </div>
        ) : null}
        {formik.values.drop_action === "IN_GROUP" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="drop_inbound_group"
              label="Drop Ring Group"
              options={record}
              formik={formik}
              valueProp="group_id"
              labelProp="group_name"
            />
          </div>
        ) : null}

        {formik.values.drop_action === "CALLMENU" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="drop_callmenu"
              label="Drop Call Menu"
              options={callMenu}
              formik={formik}
              valueProp="menu_id"
              labelProp="menu_name"
            />
          </div>
        ) : null}

        <div className="mb-3">
          <FormSelectInput
            errors={errors}
            name="no_agent_no_queue"
            label="No Agents No Queue"
            options={[
              { label: "No", value: "N" },
              { label: "Yes", value: "Y" },
              { label: "No Paused", value: "NO_PAUSED" },
              { label: "No Ready", value: "NO_READY" },
            ]}
            formik={formik}
            valueProp="value"
            labelProp="label"
          />
        </div>
        <div className="mb-3">
          <FormSelectInput
            errors={errors}
            name="no_agent_action"
            label="No Agent Action"
            options={[
              { label: "Call Menu", value: "CALLMENU" },
              { label: "In Group", value: "INGROUP" },
              { label: "DID", value: "DID" },
              { label: "Extension", value: "EXTENSION" },
              { value: "VOICEMAIL", label: "Voicemail" },
            ]}
            formik={formik}
            valueProp="value"
            labelProp="label"
          />
        </div>
        <div className="mb-3">
          <FormInput
            errors={errors}
            name="no_agent_delay"
            label="No Agent No Queue Delay"
            type="number"
            formik={formik}
            maxlength={5}
            onChange={(e) => {
              if (e.target.value?.length > 5) {
                return setErrors([
                  { no_agent_delay: "You can enter the maximum of 5 digits" },
                ]);
              }
              formik.setFieldValue("no_agent_delay", e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <FormSelectInput
            errors={errors}
            name="in_queue_nanque"
            label="No Agent No Queue Lead Reset"
            options={[
              { label: "Yes", value: "Y" },
              { label: "No", value: "N" },
              { label: "No Paused", value: "NO_PAUSED" },
              { label: "No Paused Exceptions", value: "NO_PAUSED_EXCEPTIONS" },
              { label: "No Ready", value: "NO_READY" },
            ]}
            formik={formik}
            valueProp="value"
            labelProp="label"
          />
        </div>
        {formik.values.no_agent_action === "EXTENSION" ? (
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="EXextension_no_agent_action"
              label="Extension"
              formik={formik}
            />
          </div>
        ) : null}
        {formik.values.no_agent_action === "INGROUP" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="IGgroup_id_no_agent_action"
              label="No Agent Ring Group"
              options={record}
              formik={formik}
              valueProp="group_id"
              labelProp="group_name"
            />
          </div>
        ) : null}

        {formik.values.no_agent_action === "CALLMENU" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="no_agent_action_call_menu_value"
              label="No Agent Call Menu"
              options={callMenu}
              formik={formik}
              valueProp="menu_id"
              labelProp="menu_name"
            />
          </div>
        ) : null}
        {formik.values.no_agent_action === "DID" ? (
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="no_agent_action_did_value"
              label="No Agent DID"
              formik={formik}
            />
          </div>
        ) : null}
        {formik.values.no_agent_action === "VOICEMAIL" ? (
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="no_agent_action_value"
              label="No Agent Voicemail Ext"
              formik={formik}
            />
          </div>
        ) : null}
      </form>
    </Modal>
  );
}

export default AddUpdateForm;
