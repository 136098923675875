import api from "./api";

export const getGmailAccounts = async () => {
  return await api.get(`/api/gmail_account`);
};
export const createGmailAccounts = async (data) => {
  let payload = { ...data };
  delete payload.app_url;
  return await api.post("/api/gmail_account", payload);
};
export const updateGmailAccounts = async (data) => {
  let payload = { ...data };
  delete payload.id;
  delete payload.app_url;
  return await api.put(`/api/gmail_account/${data.id}`, payload);
};
export const deleteGmailAccounts = async (id) => {
  return await api.delete(`/api/gmail_account/${id}`);
};
