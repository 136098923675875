import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import {
  apiUserReset,
  get_api_users,
  handle_toggle_active,
  resend_email,
} from "../../features/apiUserSlice";
import { FaCopy } from "react-icons/fa";
import ToggleSwitch from "../../components/molecules/ToggleButton";
import { toast } from "react-toastify";
import { RiMailSendLine } from "react-icons/ri";
const APIUsers = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.apiUsers);
  const [searchText, setSearchText] = useState("");

  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_api_users());
    return () => {
      dispatch(apiUserReset());
    };
    // eslint-disable-next-line
  }, []);
  const reasons = (record?.records || record || [])?.filter(
    (reason) =>
      reason?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      reason?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      reason?.apiToken?.toLowerCase().includes(searchText.toLowerCase())
  );
  const copyContent = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Token copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Email", field: "email", flex: 1 },
    {
      headerName: "Token",
      field: "apiToken",
      flex: 1,
    },
    {
      headerName: "Active",
      field: "active",
      renderCell: (params) => {
        const { active } = params.row;
        return (
          <ToggleSwitch
            checked={active}
            unique_by={params?.row?.records?._id}
            onChange={() => {
              dispatch(handle_toggle_active(params?.row?.records?._id));
            }}
          />
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      renderCell: (params) => {
        const { apiToken } = params.row;
        return (
          <div className="flex flex-row gap-x-2 items-center">
            <button onClick={() => copyContent(apiToken)}>
              {<FaCopy size={18} />}
            </button>
            <button
              onClick={() => {
                const c = window.confirm(
                  "Are you sure to send the token to the user?"
                );
                if (!c) return;
                dispatch(resend_email(params?.row?.records?._id));
              }}
            >
              {<RiMailSendLine size={20} />}
            </button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add API Users"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        heading="API Users Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add API Users")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={reasons?.map((record, index) => {
            let counter = index + 1;
            const { name, email, apiToken, active } = record;
            return {
              records: record,
              counter,
              name,
              email,
              apiToken,
              active,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default APIUsers;
