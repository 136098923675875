import React from "react";
import FormInput from "../Common/FormInput";
import VehicleTrailerDetailsSection from "../Common/Sections/VehicleTrailerDetailsSection";
import FormSelectInput from "../Common/FormSelectInput";
const NonVehicleTow = ({ formik }) => {
  return (
    <div className="bg-white border px-2 md:px-4 mt-3">
      <div className="pl-2 pt-3 !mb-2">
        <div className="font-semibold text-lg">Non-vehicle Tow:</div>
      </div>

      <div
        className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4 mt-2`}
      >
        <FormSelectInput
          name="extra_fields.is_in_parking"
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          convertor={(value) => (value ? value === "true" : "")}
          valueProp="value"
          labelProp="label"
          label="Is it inside of a parking garage?"
          formik={formik}
          value={formik.values?.extra_fields?.is_in_parking}
          isCustomOption
        />
        {formik.values?.extra_fields?.is_in_parking ? (
          <>
            <FormInput
              name="extra_fields.height_clearance"
              type="text"
              label="Height Clearance"
              formik={formik}
              value={formik.values?.extra_fields?.height_clearance}
              placeholder={"feet (ft.)"}
            />
            <FormInput
              name="extra_fields.floor_level"
              type="text"
              label="Which floor level is the vehicle at?"
              formik={formik}
              value={formik.values?.extra_fields?.floor_level}
              placeholder={"Floor #"}
            />
          </>
        ) : null}
      </div>
      <div class="flex flex-row gap-4 mb-4 mt-2 ml-">
        <div class="basis-1/2 p-">
          <div
            className="font-semibold text-xs text-gray-500 mb-1"
            htmlFor="to_be_towed"
          >
            What is the equipment or item that needs to be towed?
          </div>
          <FormInput
            name="extra_fields.to_be_towed"
            type="text"
            formik={formik}
            value={formik.values?.extra_fields?.to_be_towed}
          />
        </div>

        <div class="basis-full ">
          <div className="font-semibold text-xs text-gray-500 mb-1">
            Dimensions or size of the equipment/item?
          </div>
          <VehicleTrailerDetailsSection
            formik={formik}
            fieldsToShow={["weight", "height", "length", "width"]}
          />
        </div>
      </div>
    </div>
  );
};

export default NonVehicleTow;
