import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { Button } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  create_google_calendar_events,
  setAddUpdateEvent,
  setSelectedEvent,
  setShowEventModal,
  update_google_calendar_events,
} from "../../../../features/calendarEventSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MdAccessTime } from "react-icons/md";
import { LuFileText } from "react-icons/lu";
import { FiMapPin } from "react-icons/fi";
export default function AddUpdateGoogleEventModal({ getLeadsByFilter }) {
  const { selectedEvent } = useSelector((state) => state?.calendarEvents);
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  const optionsRef = React.useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      dispatch(setShowEventModal(false));
    }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      dispatch(setShowEventModal(false));
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  let initialPayload = {
    location: "",
    metaData: null,
    summary: null,
    startDateTime: new Date(),
    endDateTime: new Date(),
    description: "",
  };
  if (selectedEvent) {
    const {
      id,
      summary,
      start,
      end,
      location,
      description,
      reminders,
      main_data,
    } = selectedEvent;
    initialPayload = {
      id: id,
      summary,
      startDateTime: start?.dateTime,
      location,
      endDateTime: end?.dateTime,
      description,
      reminders,
      main_data,
    };
  }
  const [payload, setPayload] = useState(initialPayload);
  function formatReminders(data) {
    return data.map((item) => {
      if (item.before_minutes < 60) {
        return {
          type: "minutes",
          minutes: item.before_minutes.toString(),
        };
      } else {
        const hours = item.before_minutes / 60;
        return {
          type: "hours",
          hours: hours.toString(),
        };
      }
    });
  }
  useEffect(() => {
    const reminders = payload?.main_data?.reminders || [];

    if (reminders.length > 0) {
      const formattedReminders = formatReminders(reminders);
      setNotifications(formattedReminders);
    }
  }, [payload?.main_data?.reminders]);

  const handleSubmit = async () => {
    try {
      let data;
      const action = payload?.main_data
        ? update_google_calendar_events
        : create_google_calendar_events;
      if (payload?.main_data) {
        data = {
          id: payload?.main_data?._id,
          summary: payload?.summary,
          location: payload?.location,
          description: payload?.description,
          startDateTime: payload?.startDateTime,
          endDateTime: payload?.endDateTime,
          notify_before_mins: generateFinalArray(),
        };
      } else {
        data = { ...payload, notify_before_mins: generateFinalArray() };
      }
      const res = await dispatch(action(data));
      if (res.payload.status === 200 || res.payload.status === 201) {
        dispatch(setSelectedEvent(null));
        getLeadsByFilter();
      }
    } catch (err) {
      console.log("👊 ~ handleSubmit ~ err:", err);
    }
  };

  const handleAddNotification = () => {
    if (notifications.length < 3) {
      setNotifications([...notifications, { type: "minutes", minutes: "10" }]);
    }
  };

  const handleTypeChange = (index, value) => {
    const updatedNotifications = [...notifications];
    updatedNotifications[index].type = value;
    if (value === "hours") {
      delete updatedNotifications[index].minutes;
      if (!updatedNotifications[index].hours) {
        updatedNotifications[index].hours = "1";
      }
    } else {
      delete updatedNotifications[index].hours;
      if (!updatedNotifications[index].minutes) {
        updatedNotifications[index].minutes = "10";
      }
    }

    setNotifications(updatedNotifications);
  };

  const handleHoursChange = (index, value) => {
    const updatedNotifications = [...notifications];
    updatedNotifications[index].hours = value;
    setNotifications(updatedNotifications);
  };

  const handleMinutesChange = (index, value) => {
    const updatedNotifications = [...notifications];
    updatedNotifications[index].minutes = value;
    setNotifications(updatedNotifications);
  };

  const handleRemoveNotification = (index) => {
    const updatedNotifications = notifications.filter((_, i) => i !== index);
    setNotifications(updatedNotifications);
  };

  const generateFinalArray = () => {
    return notifications.map((notification) => {
      if (notification.type === "hours") {
        // Convert hours to minutes (multiply by 60)
        return parseInt(notification.hours) * 60;
      } else if (notification.type === "minutes") {
        // Directly use the minutes value
        return parseInt(notification.minutes);
      }
      return null;
    });
  };

  return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center z-[1000]">
      <div
        className="bg-white rounded-lg shadow-2xl md:w-[40%] border"
        ref={optionsRef}
      >
        <header
          className={`bg-[#f7f7f7] px-4 py-2 flex justify-between items-center border-b border-[#bbb2b2]`}
        >
          <span className="material-icons-outlined text-black">
            {payload?.main_data ? "Update Event" : "Add Event"}
          </span>
          <div>
            <button onClick={() => dispatch(setAddUpdateEvent(false))}>
              <span className="material-icons-outlined text-menu">
                <AiOutlineCloseCircle size={25} />
              </span>
            </button>
          </div>
        </header>
        <div className="p-3 flex">
          <div className="w-full">
            <input
              className="border w-full mb-3 h-10 px-2 rounded"
              placeholder="Add Title"
              onChange={(e) => {
                setPayload({ ...payload, summary: e?.target.value });
              }}
              value={payload.summary}
            />
            <div className="flex gap-x-2">
              <div className="flex flex-col justify-center w-full mb-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    value={payload?.startDateTime}
                    onChange={(val) => {
                      setPayload({
                        ...payload,
                        startDateTime: val?.toString(),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="From Date"
                        className="bg-[#f3f3f3]"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="flex flex-col justify-center w-full mb-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    value={payload?.endDateTime}
                    onChange={(val) => {
                      setPayload({ ...payload, endDateTime: val?.toString() });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="To Date"
                        className="bg-[#f3f3f3]"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-2 w-full mb-3">
              <div className="gap-y-2">
                <div className="flex items-center space-x-2">
                  <button
                    onClick={handleAddNotification}
                    className={`${
                      notifications?.length === 2
                        ? "cursor-not-allowed opacity-50"
                        : "!opacity-100"
                    } px-2 text-white text-base bg-[#5f7786] font-semibold h-10 w-full rounded flex gap-x-1 items-center justify-center`}
                    disabled={notifications?.length === 2}
                  >
                    <MdAccessTime size={20} /> Add Reminder
                  </button>
                </div>
                <div className="gap-y-0">
                  {notifications.map((notification, index) => (
                    <div
                      key={index}
                      className="flex items-center space-x-2 rounded-sm"
                    >
                      {/* Type Selection */}
                      <select
                        value={notification.type}
                        onChange={(e) =>
                          handleTypeChange(index, e.target.value)
                        }
                        className="px-2 py-0 border rounded-sm"
                      >
                        <option value="hours">Hours</option>
                        <option value="minutes">Minutes</option>
                      </select>

                      {/* Conditional Dropdown for Hours */}
                      {notification.type === "hours" && (
                        <select
                          value={notification.hours}
                          onChange={(e) =>
                            handleHoursChange(index, e.target.value)
                          }
                          className="px-0 py-0 border rounded-md w-[80px]"
                        >
                          {[...Array(12).keys()].map((i) => (
                            <option key={i} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                      )}

                      {/* Conditional Dropdown for Minutes */}
                      {notification.type === "minutes" && (
                        <select
                          value={notification.minutes}
                          onChange={(e) =>
                            handleMinutesChange(index, e.target.value)
                          }
                          className="px-0 py-0 border rounded-md w-[80px]"
                        >
                          {[...Array(59).keys()].map((i) => (
                            <option key={i} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                      )}
                      <button
                        onClick={() => handleRemoveNotification(index)}
                        className="text-red-500 hover:text-red-700 p-2"
                      >
                        <RxCross2 className="h-5 w-5" color="gray" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="relative w-full">
                <input
                  className="border w-full mb-3 h-10 rounded pl-2 !pr-8"
                  placeholder="Add Location"
                  onChange={(e) => {
                    setPayload({ ...payload, location: e?.target.value });
                  }}
                  value={payload.location}
                />
                <FiMapPin className="absolute right-2 top-3 text-gray-500" />
              </div>
            </div>
            <div className="relative w-full">
              <LuFileText className="absolute left-2 top-2 text-gray-500" />
              <textarea
                className="border bg-[#f1f3f] p-1 !pl-8 w-full" // Add padding-left to accommodate the icon
                placeholder="Add Description"
                rows={3}
                onChange={(e) => {
                  setPayload({
                    ...payload,
                    description: e.target.value,
                  });
                }}
                value={payload.description}
              />
            </div>
            {/* <textarea
              className="border bg-[#f1f3f] p-1 w-full"
              placeholder="Add Description"
              rows={3}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  description: e?.target.value,
                });
              }}
              value={payload?.description}
            /> */}
          </div>
        </div>
        <footer className="flex justify-end border-t !p-3 !mt-">
          <Button
            text="Save"
            onClick={handleSubmit}
            variant="btn_submit"
            // className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
          />
        </footer>
      </div>
    </div>
  );
}
