import React from "react";
import GoogleMails from "./Emails";
import CalendarEvents from "./GoogleCalendar";
import GoogleTask from "./GoogleTask";
import Voicemail from "./Voicemail";

function NewDashboard() {
  const tabs = ["Email", "My Calendar", "My Tasks", "Voicemail"];
  const [activeTab, setActiveTab] = React.useState("Email");
  const [emailCount, setEmailCount] = React.useState(0);
  const [voicemailCount, setVoicemailCount] = React.useState(0);

  return (
    <div className="!my-4">
      <ul className="grid grid-cols-6 gap-x-2">
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={`p-2 rounded-t-md hover:bg text-center cursor-pointer ${
              activeTab === tab ? "bg-primary-100 text-white" : "bg-neutral-100"
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {`${tab} ${tab === "Email" ? `(${emailCount})` : ""}`}
          </li>
        ))}
      </ul>
      {/* <div className="shadow-md border h-full"> */}
      {activeTab === "Email" ? (
        <GoogleMails setEmailCount={setEmailCount} emailCount={emailCount} />
      ) : activeTab === "My Calendar" ? (
        <div className="max-h-[calc(100vh-150px)] overflow-y-auto">
          <CalendarEvents />
        </div>
      ) : activeTab === "My Tasks" ? (
        <GoogleTask />
      ) : activeTab === "Voicemail" ? (
        <Voicemail
          setVoicemailCount={setVoicemailCount}
          voicemailCount={voicemailCount}
        />
      ) : null}
      {/* </div> */}
    </div>
  );
}

export default NewDashboard;
