import React from "react";
import FormInput from "../Common/FormInput";
import VehicleTrailerDetailsSection from "../Common/Sections/VehicleTrailerDetailsSection";
import VehicleInfoSection from "../Common/Sections/VehicleInfoSection";
const WinchOutSection = ({ formik, serviceName }) => {
  return (
    <>
      <VehicleInfoSection
        serviceName={serviceName}
        formik={formik}
        fieldsToShow={[
          "car_year",
          "car_make",
          "car_model",
          "is_neutral",
          "car_color",
          "drivetrain",
          "duty_type",
          "is_tires_ok",
          "car_type",
        ]}
      />
      <VehicleTrailerDetailsSection
        formik={formik}
        fieldsToShow={[
          ...(formik.values.extra_fields?.duty_type &&
          formik.values.extra_fields?.duty_type !== "light_duty"
            ? [
                "is_trailer",
                "weight",
                "height",
                "length",
                "width",
                "axles_count",
                "is_dually",
                "loaded_with",
                "brakes_be_release",
              ]
            : []),
          formik.values?.extra_fields?.tow_type === "secondary"
            ? "payout_section"
            : null,
        ].filter(Boolean)}
      />

      <div className="!mt-3 border-t-2 mb-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 mt-2">
          <FormInput
            name="extra_fields.far_from_road"
            type="text"
            label="How far is the vehicle from the road or solid pavement?"
            formik={formik}
            value={formik.values?.extra_fields?.far_from_road}
          />
          <FormInput
            name="extra_fields.stucked_depth"
            type="text"
            label="How deep is the vehicle stuck?"
            formik={formik}
            value={formik.values?.extra_fields?.stucked_depth}
          />
          <FormInput
            name="extra_fields.stucked_part"
            type="text"
            label="Which part of the vehicle is stuck?"
            formik={formik}
            value={formik.values?.extra_fields?.stucked_part}
          />
        </div>
      </div>
    </>
  );
};

export default WinchOutSection;
