import React, { useState } from "react";
import { DotsLoader, FormInput, Modal } from "../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import api from "../../services/api";
import * as Yup from "yup";
const AddUpdateForm = ({
  editingRecord,
  onCancelForm,
  modalTitle,
  onFormSubmit,
}) => {
  const [errors, setErrors] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let initialValues = {
    map_key: "",
    mail: "",
  };

  if (editingRecord) {
    const { _id, map_key, mail } = editingRecord;
    initialValues = {
      id: _id,
      map_key,
      mail,
    };
  }

  const validationSchema = Yup.object({
    map_key: Yup.string().required("Google Map Key is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const message = editingRecord ? "updated" : "created";
      setIsLoading(true);
      try {
        const payload = {
          map_key: values.map_key,
          mail: values.mail,
        };
        const apiAction = editingRecord
          ? api.put(`/api/googleMapKey/${editingRecord._id}`, payload)
          : api.post("/api/googleMapKey", payload);

        const res = await apiAction;

        if (res?.status === 200 || res?.status === 201) {
          toast.success(`Google Map Key ${message} successfully`);
          onFormSubmit();
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "Google Map Key",
            msg: message,
          });
        }
      } catch (error) {
        toast.error(`Google Map Key couldn't be ${message}`);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <div className="mb-3 mt-4">
            <FormInput
              errors={errors}
              name="mail"
              label="Mail"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="map_key"
              label="Google Map Key"
              formik={formik}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
