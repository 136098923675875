import React from "react";
import { MdAccessTime, MdOutlineAddCircleOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddUpdateEvent,
  setViewType,
} from "../../../../features/calendarEventSlice";
import userPermission from "../../../../util/userPermission";

export default function CalendarHeader() {
  const dispatch = useDispatch();
  const { viewType, calendarEvents: filteredEvents } = useSelector(
    (state) => state?.calendarEvents
  );
  const views = [
    { value: "day", label: "Daily View" },
    { value: "month", label: "Monthly View" },
  ];
  return (
    <header className="px-2 py-2 flex items-center justify-between">
      <div className="flex items-center gap-x-3">
        {userPermission("Write Calendar") ? (
          <button
            className="flex items-center border rounded gap-x-1 px-2 shadow hover:shadow-lg"
            onClick={() => dispatch(setAddUpdateEvent(true))}
          >
            <MdOutlineAddCircleOutline /> Add Event
          </button>
        ) : null}

        <div className="flex items-center border rounded gap-x-1 shadow px-2 bg-gray-600 text-white">
          <MdAccessTime /> Total Events{" "}
          <span className="bg-white text-black text-xs rounded-full px-1 font-semibold">
            {filteredEvents?.length || 0}
          </span>
        </div>
      </div>
      <div className="flex items-center gap-x-2">
        {views?.map((view, index) => {
          const { label, value } = view;
          return (
            <button
              key={index}
              onClick={() => dispatch(setViewType(value))}
              className={`${
                viewType === value
                  ? "border-primary-100 text-primary-100 border-b-2"
                  : "border-gray-500"
              } border-b font-semibold text-s px-2`}
            >
              {label}
            </button>
          );
        })}
      </div>
    </header>
  );
}
