import React, { useState, useRef } from "react";
import { address_icn } from "../../../../images";
import CustomSelect from "../../Components/Selectors";
import { DotsLoader } from "../../../../components";
import { Autocomplete } from "@react-google-maps/api";
import { BiRefresh } from "react-icons/bi";
import GoogleScript from "../../../../components/molecules/GoogleMapScript";
import Radar from "radar-sdk-js";
import "radar-sdk-js/dist/radar.css";
function Locations({ formik, setIsDisable, record, currentStep }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [accuracy, setAccuracy] = React.useState(null);
  const [customDistance, setCustomDistance] = React.useState("");
  const isDropLocation = record?.records?.findIndex(
    (service) => service._id === formik?.values?.job_category_id
  );
  const isDroped = record?.records[isDropLocation]?.drop_location_required;
  const [manualAddress, setManualAddress] = React.useState(!isDroped);
  const [autocomplete, setAutocomplete] = React.useState();
  const googleKey = localStorage.getItem("google_api_key");
  const onLoadPickup = (autocomplete) => {
    setAutocomplete(autocomplete);
  };
  const onLoadDrop = (autocomplete) => {
    setAutocomplete(autocomplete);
  };
  const [isCustomDistance, setIsCustomDistance] = useState(false);
  const onChangeAddress = (prefix) => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place?.geometry?.location.lat();
      const lng = place?.geometry?.location.lng();

      formik?.setFieldValue(
        `complete_${prefix}_address`,
        place?.formatted_address
      );
      formik?.setFieldValue(`${prefix}_latitude`, lat);
      formik?.setFieldValue(`${prefix}_longitude`, lng);

      extractAddress(place, prefix);
    } else {
      console.warn("Autocomplete is not loaded yet!");
    }
  };

  const extractAddress = (place, prefix) => {
    if (!Array.isArray(place?.address_components)) {
      return null;
    }

    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component;

      if (types.includes("postal_code")) {
        formik.setFieldValue(`${prefix}_zip`, value?.long_name);
      }
      if (types.includes("locality")) {
        formik.setFieldValue(`${prefix}_city`, value?.long_name);
      }
      if (types.includes("administrative_area_level_1")) {
        formik.setFieldValue(`${prefix}_state`, value?.short_name);
      }
      if (types.includes("country")) {
        formik.setFieldValue(`${prefix}_country`, value?.long_name);
      }
    });
  };
  React.useEffect(() => {
    if (formik?.values?.complete_pickup_address) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
    // eslint-disable-next-line
  }, [formik?.values?.complete_pickup_address]);

  const pickupcompleteRef = React.useRef(null);
  const dropcompleteRef = React.useRef(null);
  Radar.initialize(process.env.REACT_APP_RADAR_API);
  React.useEffect(() => {
    if (document.getElementById("pickupAddress")) {
      pickupcompleteRef.current = Radar.ui.autocomplete({
        container: "pickupAddress",
        countryCode: "US, CA",
        onSelection: (address) => {
          selectradarAddress(address, "pickup");
          pickupcompleteRef.current.inputField.disabled = true;
        },
      });
    }
    if (document.getElementById("dropAddress")) {
      dropcompleteRef.current = Radar.ui.autocomplete({
        container: "dropAddress",
        countryCode: "US, CA",
        onSelection: (address) => {
          selectradarAddress(address, "drop");
          dropcompleteRef.current.inputField.disabled = true;
        },
      });
    }
    if (
      formik?.values.complete_pickup_address &&
      pickupcompleteRef.current &&
      pickupcompleteRef.current.inputField
    ) {
      pickupcompleteRef.current.inputField.value =
        formik?.values.complete_pickup_address;
      pickupcompleteRef.current.inputField.disabled = true;
    }
    if (
      formik?.values.complete_drop_address &&
      dropcompleteRef.current &&
      dropcompleteRef.current.inputField
    ) {
      dropcompleteRef.current.inputField.value =
        formik?.values.complete_drop_address;
      dropcompleteRef.current.inputField.disabled = true;
    }
    return () => {
      pickupcompleteRef.current?.remove();
      dropcompleteRef.current?.remove();
    };
  }, [manualAddress]);

  const selectradarAddress = (address, prefix) => {
    if (address.postalCode) {
      formik.setFieldValue(`${prefix}_zip`, address.postalCode);
    }
    if (address.city) {
      formik.setFieldValue(`${prefix}_city`, address.city);
    }
    if (address.stateCode) {
      formik.setFieldValue(`${prefix}_state`, address.stateCode);
    }
    if (address.country) {
      formik.setFieldValue(`${prefix}_country`, address.country);
    }

    formik?.setFieldValue(
      `complete_${prefix}_address`,
      address.formattedAddress
    );
    formik?.setFieldValue(`${prefix}_latitude`, address.latitude);
    formik?.setFieldValue(`${prefix}_longitude`, address.longitude);
  };
  const toggleAddress = () => {
    formik.setFieldValue("complete_pickup_address", "");
    formik.setFieldValue("distance", "");
    pickupcompleteRef.current.inputField.disabled =
      !pickupcompleteRef.current.inputField.disabled;
  };
  const toggledropAddress = () => {
    formik.setFieldValue("complete_drop_address", "");
    formik.setFieldValue("distance", "");
    dropcompleteRef.current.inputField.disabled =
      !dropcompleteRef.current.inputField.disabled;
  };

  const getLocation = () => {
    setIsLoading(true);
    navigator.geolocation.getCurrentPosition((position) => {
      formik.setFieldValue("pickup_latitude", position?.coords?.latitude);
      formik.setFieldValue("pickup_longitude", position?.coords?.longitude);
      setAccuracy(position?.coords?.accuracy);
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position?.coords?.latitude},${position?.coords?.longitude}&sensor=true&key=${googleKey}`
      )
        .then((res) => res.json())
        .then((json) => {
          setIsLoading(false);
          extractAddress(json.results[0], "pickup");
          formik.setFieldValue(
            "complete_pickup_address",
            json.results[0].formatted_address
          );
        });
    });
  };
  // async function calculateRoute() {
  //   if (
  //     !formik?.values?.complete_pickup_address ||
  //     !formik?.values?.complete_drop_address
  //   ) {
  //     formik.setFieldValue("distance", "");
  //     return;
  //   }
  //   // eslint-disable-next-line
  //   const directionsService = new google.maps.DirectionsService();
  //   const results = await directionsService.route({
  //     origin: `${formik?.values?.pickup_latitude}, ${formik?.values?.pickup_longitude}`,
  //     destination: `${formik?.values?.drop_latitude}, ${formik?.values?.drop_longitude}`,
  //     // eslint-disable-next-line
  //     travelMode: google.maps.TravelMode.DRIVING,
  //   });
  //   formik.setFieldValue(
  //     "distance",
  //     (results.routes[0].legs[0].distance.value / 1600).toFixed(2) || 0
  //   );
  // }
  async function calculateRoute() {
    const {
      complete_pickup_address,
      complete_drop_address,
      pickup_latitude,
      pickup_longitude,
      drop_latitude,
      drop_longitude,
    } = formik?.values || {};

    // Validate required addresses
    if (!complete_pickup_address || !complete_drop_address) {
      formik.setFieldValue("distance", "");
      return;
    }
    try {
      const result = Radar.distance({
        origin: { latitude: pickup_latitude, longitude: pickup_longitude },
        destination: { latitude: drop_latitude, longitude: drop_longitude },
        modes: ["car"], // Adjust modes as needed (e.g., 'foot', 'bike')
      })
        .then((result) => {
          const { routes } = result;
          const { car } = routes;
          return car?.distance?.value;
          // do something with distance result
        })
        .catch((err) => {
          console.log("👊 ~ calculateRoute ~ err:", err);
          // handle error
        });
      const carDistanceMeters = (await result) || 0;
      const distanceInMiles = (carDistanceMeters / 5280).toFixed(2);
      // const distanceInMiles = (carDistanceMeters / 1609.34).toFixed(2);
      formik.setFieldValue("distance", distanceInMiles);
    } catch (error) {
      console.error("Error calculating route with Radar:", error);
      formik.setFieldValue("distance", "0");
    }
  }
  React.useEffect(() => {
    if (currentStep !== 5) {
      calculateRoute();
    }
    // eslint-disable-next-line
  }, [formik?.values?.drop_longitude]);

  return (
    <div>
      {isLoading && <DotsLoader />}
      {manualAddress ? (
        <div className="text-base font-pop text-center font-medium bottom-3.7">
          {`Please manually enter the address in the fields below. Want to use `}
          <strong>Autocomplete</strong>?{" "}
          <span
            className="text-[#007bff] underline cursor-pointer"
            onClick={() => setManualAddress(!manualAddress)}
          >
            click here
          </span>
          {` to switch`}
        </div>
      ) : (
        <div className="text-base font-pop text-center font-medium bottom-3.7">
          {`Start typing to search for your address or prefer manual entry? `}
          <span
            className="text-[#007bff] underline cursor-pointer"
            onClick={() => setManualAddress(!manualAddress)}
          >
            click here
          </span>
          {` to enter your address manually`}
        </div>
      )}
      {manualAddress ? (
        <div className="mt-3">
          {/* Pickup location Start */}
          <span className="text-base font-pop text-center font-medium">
            Pickup Address
          </span>
          <div className="flex gap-4 mt-2">
            <div className="w-full">
              <CustomSelect
                icon={address_icn}
                value={formik?.values?.complete_pickup_address}
                placeholder="Pickup Address"
                isInput
                formik={formik}
                name="complete_pickup_address"
                label={
                  accuracy ? `Accurate to ${accuracy?.toFixed(2)} meters` : ""
                }
                // errors={errors}
              />
            </div>
          </div>
          <div className="grid lg:grid-cols-3 gap-2">
            <CustomSelect
              // icon={id}
              // label="Please enter the Zip."
              placeholder="Zip"
              isInput={true}
              formik={formik}
              name="pickup_zip"
              value={formik?.values?.pickup_zip}
              // errors={errors}
            />
            <CustomSelect
              // icon={id}
              // label="Please enter the State."
              placeholder="State"
              isInput={true}
              formik={formik}
              name="pickup_state"
              value={formik?.values?.pickup_state}
              // errors={errors}
            />
            <CustomSelect
              // icon={id}
              // label="Please enter the City."
              placeholder="City"
              isInput={true}
              formik={formik}
              name="pickup_city"
              value={formik?.values?.pickup_city}
              // errors={errors}
            />
          </div>
          {/* Pickup location end */}

          {/* Drop location start */}
          {isDroped ? (
            <div>
              <span className="text-base font-pop text-center font-medium">
                Drop Address
              </span>
              <div className="flex gap-4 mt-2">
                <div className="w-full">
                  <CustomSelect
                    icon={address_icn}
                    value={formik?.values?.complete_drop_address}
                    placeholder="Drop Address"
                    isInput
                    formik={formik}
                    name="complete_drop_address"
                    // errors={errors}
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-3 gap-2">
                <CustomSelect
                  placeholder="Zip"
                  isInput={true}
                  formik={formik}
                  name="drop_zip"
                  value={formik?.values?.drop_zip}
                  // errors={errors}
                />
                <CustomSelect
                  placeholder="State"
                  isInput={true}
                  formik={formik}
                  name="drop_state"
                  value={formik?.values?.drop_state}
                  // errors={errors}
                />
                <CustomSelect
                  placeholder="City"
                  isInput={true}
                  formik={formik}
                  name="drop_city"
                  value={formik?.values?.drop_city}
                  // errors={errors}
                />
              </div>
              <div className="flex justify-between">
                <small className="font-pop">
                  {formik?.values?.distance
                    ? `Distance is ${formik?.values?.distance} miles`
                    : ""}
                </small>
                {isCustomDistance ? (
                  <div className="flex flex-row gap-x-2 items-centr justify-cente">
                    <input
                      className="bg-transparent border-b border-black"
                      value={customDistance}
                      onChange={(e) => setCustomDistance(e.target.value)}
                      // onChange={formik.handleChange}
                      name="distance"
                    />
                    <div className="flex flex-row gap-x-2 items-end">
                      <button
                        onClick={() => {
                          formik.setFieldValue("distance", customDistance);
                          setCustomDistance("");
                          setIsCustomDistance(false);
                        }}
                        className="text-sm text-blue-500"
                      >
                        Done
                      </button>
                      <button
                        onClick={() => {
                          setCustomDistance("");
                          setIsCustomDistance(false);
                        }}
                        className="text-sm text-red-500"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row gap-x-2">
                    <button
                      onClick={() => setIsCustomDistance(true)}
                      type="button"
                      className="text-blue-500"
                    >
                      Want to change the distance?
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <div>
            {/* Pickup Address*/}
            <div className="flex gap-4">
              <div
                className={
                  !formik.values?.complete_pickup_address
                    ? "w-[100%] flex"
                    : "w-[95%] flex"
                }
              >
                <div
                  className={`bg-client-100 flex w-[60px] justify-center items-center rounded-l "h-10"`}
                >
                  <span
                    className="w-6 h-6 bg-center bg-no-repeat bg-contain"
                    style={{ backgroundImage: `url(${address_icn})` }}
                  />
                </div>
                <div id="pickupAddress" className="w-[100%]" />
              </div>
              {formik?.values?.complete_pickup_address && (
                <div
                  className="flex justify-center items-center min-w-[40px]"
                  style={{
                    backgroundColor: "#5b6eb6",
                    maxHeight: 40,
                    borderRadius: 5,
                  }}
                >
                  <BiRefresh
                    size={25}
                    className=""
                    onClick={() => {
                      toggleAddress();
                    }}
                    cursor="pointer"
                    color="white"
                  />
                </div>
              )}
            </div>

            {isDroped && (
              <div>
                <div className="flex gap-4 mt-2">
                  <div
                    className={
                      isDroped &&
                      !formik.values?.complete_drop_address &&
                      formik?.values?.complete_pickup_address
                        ? "w-[100%] flex"
                        : "w-[95%] flex"
                    }
                  >
                    <div
                      className={`bg-client-100 flex w-[60px] justify-center items-center rounded-l "h-10"`}
                    >
                      <span
                        className="w-6 h-6 bg-center bg-no-repeat bg-contain"
                        style={{ backgroundImage: `url(${address_icn})` }}
                      />
                    </div>
                    <div id="dropAddress" className="w-[100%]" />
                  </div>
                  {formik.values?.complete_drop_address &&
                    formik?.values?.complete_pickup_address && (
                      <div
                        className="flex justify-center items-center min-w-[40px]"
                        style={{
                          backgroundColor: "#5b6eb6",
                          maxHeight: 40,
                          borderRadius: 5,
                        }}
                      >
                        <BiRefresh
                          size={25}
                          className=""
                          onClick={() => {
                            toggledropAddress();
                          }}
                          cursor="pointer"
                          color="white"
                        />
                      </div>
                    )}
                </div>
                <div className="flex justify-between">
                  <small className="font-pop">
                    {formik?.values?.distance
                      ? `Distance is ${formik?.values?.distance} miles`
                      : ""}
                  </small>
                  {isCustomDistance ? (
                    <div className="flex flex-row gap-x-2 items-centr justify-cente">
                      <input
                        className="bg-transparent border-b border-black"
                        value={customDistance}
                        onChange={(e) => setCustomDistance(e.target.value)}
                        // onChange={formik.handleChange}
                        name="distance"
                      />
                      <div className="flex flex-row gap-x-2 items-end">
                        <button
                          onClick={() => {
                            formik.setFieldValue("distance", customDistance);
                            setCustomDistance("");
                            setIsCustomDistance(false);
                          }}
                          className="text-sm text-blue-500"
                        >
                          Done
                        </button>
                        <button
                          onClick={() => {
                            setCustomDistance("");
                            setIsCustomDistance(false);
                          }}
                          className="text-sm text-red-500"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row gap-x-2">
                      <button
                        onClick={() => setIsCustomDistance(true)}
                        type="button"
                        className="text-blue-500"
                      >
                        Want to change the distance?
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="hidden">
            <GoogleScript>
              <div>
                {!formik.values?.complete_pickup_address && (
                  <Autocomplete
                    onPlaceChanged={() => onChangeAddress("pickup")}
                    onLoad={(autocomplete) => onLoadPickup(autocomplete)}
                  >
                    <CustomSelect
                      icon={address_icn}
                      placeholder="Pickup Address"
                      isInput
                      formik={formik}
                    />
                  </Autocomplete>
                )}
                {formik?.values?.complete_pickup_address && (
                  <div className="flex gap-4">
                    <div className="w-[95%]">
                      <CustomSelect
                        icon={address_icn}
                        value={formik?.values?.complete_pickup_address}
                        placeholder="Pickup Address"
                        isInput
                        isDisable
                        formik={formik}
                        label={
                          accuracy
                            ? `Accurate to ${accuracy?.toFixed(2)} meters`
                            : ""
                        }
                      />
                    </div>
                    <div
                      className="flex justify-center items-center min-w-[40px]"
                      style={{
                        backgroundColor: "#5b6eb6",
                        maxHeight: 40,
                        borderRadius: 5,
                      }}
                    >
                      <BiRefresh
                        size={25}
                        className=""
                        onClick={() => {
                          formik.setFieldValue("complete_pickup_address", "");
                          formik.setFieldValue("distance", "");
                        }}
                        cursor="pointer"
                        color="white"
                      />
                    </div>
                  </div>
                )}

                {isDroped &&
                  !formik.values?.complete_drop_address &&
                  formik?.values?.complete_pickup_address && (
                    <Autocomplete
                      onPlaceChanged={() => onChangeAddress("drop")}
                      onLoad={(autocomplete) => onLoadDrop(autocomplete)}
                    >
                      <CustomSelect
                        icon={address_icn}
                        placeholder="Drop Address"
                        isInput
                        formik={formik}
                        label={
                          formik?.values?.distance
                            ? `Distance is ${formik?.values?.distance} miles`
                            : ""
                        }
                      />
                    </Autocomplete>
                  )}
                {formik.values?.complete_drop_address &&
                  formik?.values?.complete_pickup_address && (
                    <div className="flex gap-4">
                      <div className="w-[95%]">
                        <CustomSelect
                          icon={address_icn}
                          placeholder="Drop Address"
                          isInput
                          formik={formik}
                          label={
                            formik?.values?.distance
                              ? `Distance is ${formik?.values?.distance} miles`
                              : ""
                          }
                          value={formik.values?.complete_drop_address}
                          isDisable
                          isCustomDistance={isCustomDistance}
                          customDistance={customDistance}
                          setCustomDistance={setCustomDistance}
                          setIsCustomDistance={setIsCustomDistance}
                          calculateRoute={calculateRoute}
                          isCustomComponent
                        />
                      </div>
                      <div
                        className="flex justify-center items-center min-w-[40px]"
                        style={{
                          backgroundColor: "#5b6eb6",
                          maxHeight: 40,
                          borderRadius: 5,
                        }}
                      >
                        <BiRefresh
                          size={25}
                          className=""
                          onClick={() => {
                            formik.setFieldValue("complete_drop_address", "");
                            formik.setFieldValue("distance", "");
                          }}
                          cursor="pointer"
                          color="white"
                        />
                      </div>
                    </div>
                  )}
              </div>
            </GoogleScript>
          </div>
        </div>
      )}
    </div>
  );
}

export default Locations;
