import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getConversation,
  getGroupConversation,
  getGroupRecentMsgList,
  getMsgUsers,
  getRecentMsgList,
  markAsGroupRead,
  markAsRead,
  sendGroupMessage,
  sendMessage,
  getMessageUsers,
} from "../services/SmsCenterService";
const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  userList: [],
  clientList: [],
  recentMsg: [],
  conversation: [],
  message: "",
  recentSmsMsg: [],
  currentMessPage: 2,
  currentChatPage: 1,
  loadRecentSmsMsg: [],
  recentSmsMsgonLoad: [],
  messUsersList: [],
  currentNewMessPage: 1,
  socketMessages: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};

export const get_conversation = createAsyncThunk(
  "get_conversation",
  async (data, thunkAPI) => {
    try {
      return await getConversation(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_group_recent_messages = createAsyncThunk(
  "get_group_recent_messages",
  async (data, thunkAPI) => {
    try {
      return await getGroupRecentMsgList(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_group_conversation = createAsyncThunk(
  "get_group_conversation",
  async (data, thunkAPI) => {
    try {
      return await getGroupConversation(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const send_group_message = createAsyncThunk(
  "send_group_message",
  async (data, thunkAPI) => {
    try {
      return await sendGroupMessage(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const send_message = createAsyncThunk(
  "send_message",
  async (data, thunkAPI) => {
    try {
      return await sendMessage(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_recent_message_list_new = createAsyncThunk(
  "get_recent_message_list_new",
  async (data, thunkAPI) => {
    try {
      return await getRecentMsgList(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//only for first time load
export const get_recent_message_list_onload = createAsyncThunk(
  "get_recent_message_list_onload",
  async (data, thunkAPI) => {
    try {
      return await getRecentMsgList(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//get message center users
export const get_message_users_list = createAsyncThunk(
  "get_message_users_list",
  async (data, thunkAPI) => {
    try {
      return await getMessageUsers(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const mark_as_read_new = createAsyncThunk(
  "mark_as_read_new",
  async (data, thunkAPI) => {
    try {
      return await markAsRead(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const mark_as_group_read = createAsyncThunk(
  "mark_as_group_read",
  async (data, thunkAPI) => {
    try {
      return await markAsGroupRead(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const SmsCenterSlice = createSlice({
  name: "industries",
  initialState,
  reducers: {
    resetConversation: (state) => {
      state.conversation = [];
    },
    newMessageList: (state, action) => {
      const chatIndex = state.recentMsg?.data?.findIndex((msg) => {
        return (
          (msg?.user?._id === action?.payload?.user_id?._id &&
            action.payload?.user_type === "User") ||
          (msg?.client?._id === action?.payload?.client_id?._id &&
            action.payload?.user_type === "Client") ||
          (msg?.vendor?._id === action?.payload?.vendor_id?._id &&
            action.payload?.user_type === "Vendor") ||
          (msg?.user_phone === action?.payload?.user_phone &&
            action.payload?.user_type === "Custom")
        );
      });
      if (chatIndex > -1) {
        // Move the existing message chat head to the top
        const existingChat = state.recentMsg.splice(chatIndex, 1)[0];
        state.recentMsg.unshift(existingChat);
        // Update the existing message chat head with the new message details
        state.recentMsg[0] = {
          ...state.recentMsg[0],
          ...action.payload,
        };
      } else {
        state.recentMsg.unshift(
          action.payload?.user_type === "User"
            ? {
                message: action.payload?.message,
                createdAt: action.payload?.createdAt,
                message_type: action.payload?.message_type,
                unread_count: action.payload?.unread_count,
                user: {
                  _id: action.payload?.user_id?._id,
                  username: action.payload?.user_id?.username,
                  first_name: action.payload?.user_id?.first_name,
                  last_name: action.payload?.user_id?.last_name,
                },
                client: {},
                role: {
                  name: action.payload?.user_id?.role_id?.name,
                },
                user_type: action.payload?.user_type,
                user_phone: action.payload?.user_phone,
              }
            : action.payload?.user_type === "Vendor"
            ? {
                message: action.payload?.message,
                createdAt: action.payload?.createdAt,
                message_type: action.payload?.message_type,
                unread_count: action.payload?.unread_count,
                vendor: {
                  _id: action.payload?.vendor_id?._id,
                  username: action.payload?.vendor_id?.username,
                  first_name: action.payload?.vendor_id?.first_name,
                  last_name: action.payload?.vendor_id?.last_name,
                },
                client: {},
                role: {
                  name: "Vendor",
                },
                user_type: action.payload?.user_type,
                user_phone: action.payload?.user_phone,
              }
            : action.payload?.user_type === "Client"
            ? {
                message: action.payload?.message,
                createdAt: action.payload?.createdAt,
                message_type: action.payload?.message_type,
                unread_count: action.payload?.unread_count,
                user: {},
                client: {
                  _id: action.payload?.client_id?._id,
                  name: action.payload?.client_id?.name,
                },
                role: {},
                user_type: action.payload?.user_type,
                user_phone: action.payload?.user_phone,
              }
            : {
                message: action.payload?.message,
                createdAt: action.payload?.createdAt,
                message_type: action.payload?.message_type,
                unread_count: action.payload?.unread_count,
                user: {},
                client: {},
                role: {},
                user_type: action.payload?.user_type,
                user_phone: action.payload?.user_phone,
              }
        );
      }
      if (state.conversation?.length > 0) {
        if (
          (state.conversation[0].user_id === action?.payload?.user_id?._id &&
            action.payload?.user_type === "User") ||
          (state.conversation[0].client_id ===
            action?.payload?.client_id?._id &&
            action.payload?.user_type === "Client") ||
          (state.conversation[0].vendor_id ===
            action?.payload?.vendor_id?._id &&
            action.payload?.user_type === "Vendor") ||
          (state.conversation[0].user_phone === action?.payload?.user_phone &&
            action.payload?.user_type === "Custom")
        ) {
          state.conversation.push(action.payload);
          const chatIndex = state.recentMsg?.data?.findIndex((msg) => {
            return (
              (msg?.user?._id === action?.payload?.user_id?._id &&
                action.payload?.user_type === "User") ||
              (msg?.client?._id === action?.payload?.client_id?._id &&
                action.payload?.user_type === "Client") ||
              (msg?.vendor?._id === action?.payload?.vendor_id?._id &&
                action.payload?.user_type === "Vendor") ||
              (msg?.user_phone === action?.payload?.user_phone &&
                action.payload?.user_type === "Custom")
            );
          });
          state.recentMsg[chatIndex] = {
            ...state.recentMsg[chatIndex],
            unread_count: 0,
          };
        }
      }
    },
    newGroupMsg: (state, action) => {
      const chatIndex = state.recentMsg?.data?.findIndex(
        (msg) =>
          msg?.vendor_conversation_group_id ===
          action?.payload?.vendor_conversation_group_id
      );
      if (chatIndex > -1) {
        const existingChat = state.recentMsg.splice(chatIndex, 1)[0];
        state.recentMsg.unshift(existingChat);
        // Update the existing message chat head with the new message details
        state.recentMsg[0] = {
          ...state.recentMsg[0],
          ...action.payload,
        };
      } else {
        state.recentMsg.unshift({
          createdAt: action.payload?.createdAt,
          message: action.payload?.message,
          phone: action.payload?.phone,
          unread_count: action.payload?.unread_count,
          vendor: action.payload?.vendor_id,
          vendor_conversation_group_id:
            action.payload?.vendor_conversation_group_id,
        });
      }
      if (state.conversation?.length > 0) {
        if (
          state.conversation[0].vendor_conversation_group_id ===
          action?.payload?.vendor_conversation_group_id
        ) {
          state.conversation.push(action.payload);
          state.recentMsg[chatIndex] = {
            ...state.recentMsg[chatIndex],
            unread_count: 0,
          };
        }
      }
    },
    markAsReadMsg: (state, action) => {
      state.conversation = state.conversation?.data?.map((msg) => {
        if (msg?.is_read === false) {
          return { ...msg, is_read: true };
        }
        return msg;
      });
    },
    unreadSmsCountReset: (state, action) => {
      const chatIndex = state.recentMsg?.data?.findIndex((msg) => {
        return (
          (msg?.user?._id === action?.payload?.id &&
            action.payload?.type === "User") ||
          (msg?.client?._id === action?.payload?.id &&
            action.payload?.type === "Client") ||
          (msg?.vendor?._id === action?.payload?.id &&
            action.payload?.type === "Vendor") ||
          (msg?.user_phone === action?.payload?.user_phone &&
            action.payload?.type === "Custom")
        );
      });
      /* state.recentMsg[chatIndex] = {
        ...state.recentMsg[chatIndex],
        unread_count: 0,
      }; */
    },
    updateRecentMsg: (state, action) => {
      state.recentMsg = [...state.recentMsg, ...action.payload];
    },
    updateConversation: (state, action) => {
      state.conversation = [...state.conversation, ...action.payload];
    },
    smsCenterReset: (state, action) => {
      state.recentSmsMsg = action.payload;
      //state.loadRecentSmsMsg= [];
      state.recentSmsMsgonLoad = action.payload;
    },
    setMessCurrentPage: (state, action) => {
      state.currentMessPage = action.payload;
    },
    setChatCurrentPage: (state, action) => {
      state.currentChatPage = action.payload;
    },
    setNewMessCurrentPage: (state, action) => {
      state.currentNewMessPage = action.payload;
    },
    updateSocketMessages: (state, action) => {
      state.socketMessages.push(action.payload);
    },
    resetSocketMessages: (state) => {
      console.log("Resetting socket messages");
      state.socketMessages = []; 
    },
    removeSocketMessage: (state, action) => {
      const userPhoneToRemove = action.payload; 
      state.socketMessages = state.socketMessages.filter(
        message => message.user_phone !== userPhoneToRemove
      );
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(send_message.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(send_message.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.conversation = action.payload?.data
        // state.conversation.push(action.payload.data);
      })
      .addCase(send_message.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
       // toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(mark_as_read_new.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(mark_as_read_new.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(mark_as_read_new.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_conversation.pending, (state) => {
        state.isLoading = true;
        state.conversation = [];
      })
      .addCase(get_conversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.conversation = action.payload?.data;
      })
      .addCase(get_conversation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_recent_message_list_new.pending, (state) => {
        state.isLoading = true;
        state.recentSmsMsg = [];
      })
      .addCase(get_recent_message_list_new.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recentSmsMsg = action.payload?.data;
      })
      .addCase(get_recent_message_list_new.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_recent_message_list_onload.pending, (state) => {
        state.isLoading = true;
        state.recentSmsMsgonLoad = [];
      })
      .addCase(get_recent_message_list_onload.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recentSmsMsgonLoad = action.payload?.data;
      })
      .addCase(get_recent_message_list_onload.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_message_users_list.pending, (state) => {
        state.isLoading = true;
        state.messUsersList = [];
      })
      .addCase(get_message_users_list.fulfilled, (state, action) => {
        state.isLoading = false;
        state.messUsersList = action.payload?.data;
      })
      .addCase(get_message_users_list.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_group_conversation.pending, (state) => {
        state.isLoading = true;
        state.conversation = [];
      })
      .addCase(get_group_conversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.conversation = action.payload?.data;
      })
      .addCase(get_group_conversation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_group_recent_messages.pending, (state) => {
        state.isLoading = true;
        state.recentMsg = [];
      })
      .addCase(get_group_recent_messages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recentMsg = action.payload?.data;
      })
      .addCase(get_group_recent_messages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(send_group_message.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(send_group_message.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.conversation = action.payload?.data
        // state.conversation.push(action.payload.data);
      })
      .addCase(send_group_message.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(mark_as_group_read.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(mark_as_group_read.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(mark_as_group_read.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload ? action.payload : "Unknown Error");
      });
  },
});
export const {
  resetConversation,
  newMessageList,
  markAsReadMsg,
  unreadSmsCountReset,
  newGroupMsg,
  updateRecentMsg,
  updateConversation,
  smsCenterReset,
  setMessCurrentPage,
  setChatCurrentPage,
  setNewMessCurrentPage,
  updateSocketMessages,
  resetSocketMessages,
  removeSocketMessage,
} = SmsCenterSlice.actions;
export default SmsCenterSlice.reducer;
