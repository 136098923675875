import React, { useEffect, useState } from "react";

import {
  Backdrop,
  Button,
  DotsLoader,
} from "../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { get_merchants_companies } from "../../features/merchantSlice";
import { switchOffice } from "../../services/switchOfficeService";
import { toast } from "react-toastify";
import { FormControl, InputLabel, Select } from "@mui/material";
import errorMessage from "../../util/errorMessage";
import { useNavigate } from "react-router-dom";

const SwitchOfficeModal = ({ onCancelForm, modalTitle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.merchant);
  const [isLoader, setIsLoader] = useState(false);
  let initialValues = {
    merchant_id: "",
  };
  const [record, setRecord] = React.useState([]);

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnBlur: true,

    validationSchema: Yup.object().shape({
      merchant_id: Yup.string(),
    }),

    onSubmit: async (values) => {
      setIsLoader(true);
      switchOffice(values)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("user", JSON.stringify(res?.data?.userDetails));
            localStorage.setItem("iframe",res?.data?.iframe);
            localStorage.setItem("google_api_key",res?.data?.google_api_key);
            setIsLoader(false);
            toast.success("Switch office successfully");
            onCancelForm(false);
            navigate("/settings/dashboard");
            window.location.reload();
          } else {
            errorMessage({
              payload: res.payload,
              action: "Office",
              msg: "Switched",
            });
            setIsLoader(false);
          }
        })
        .catch((err) => {
          errorMessage({ payload: err, action: "Office", msg: "switched" });
          setIsLoader(false);
        });
    },
  });

  useEffect(() => {
    // dispatch(get_merchants_companies());
    getCompanies();
    // eslint-disable-next-line
  }, []);
  const getCompanies = async () => {
    try {
      const res = await dispatch(get_merchants_companies());
      if (res?.payload?.status === 200) {
        const groupedData =
          res?.payload?.data?.companies_by_call_center?.reduce((acc, item) => {
            const key = item?.call_center_id?.url
              ? item?.call_center_id?.url
              : "Without Call Center";
            if (key === "Without Call Center") {
              acc.withoutCallCenter = acc.withoutCallCenter || [];
              acc.withoutCallCenter.push(item);
            } else {
              acc[key] = acc[key] || [];
              acc[key].push(item);
            }
            return acc;
          }, {});
        setRecord(groupedData);
      }
    } catch (error) {
      console.error("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    }
  };
  return (
    <>
      <Backdrop onClick={onCancelForm} />
      <div className="fixed w-full max-w-[500px] max-h-[calc(100vh-64px)] overflow-y-auto top-8 left-1/2 bg-white rounded z-[200] shadow-[0_2px_8px_rgba(0,_0,_0,_0.26)] -translate-x-1/2">
        {isLoading && <DotsLoader />}
        {isLoader && <DotsLoader />}
        <header className="border-b-2 border-[#3b0062]">
          <h1 className="text-2xl text-[#3b0062] m-4">{modalTitle}</h1>
        </header>
        <div className="p-4">
          <FormControl
            sx={{ mt: 0, minWidth: 120, borderColor: "#6666" }}
            fullWidth
            variant="outlined"
          >
            <InputLabel htmlFor="merchant_id">{"Company Name"}</InputLabel>
            <Select
              sx={{
                borderColor: "#6666",
                mt: 0,
                background: "#fff",
              }}
              label="Company Name"
              size="small"
              fullWidth
              native
              name={"merchant_id"}
              variant="outlined"
              onChange={(e) =>
                formik?.setFieldValue("merchant_id", e.target.value)
              }
            >
              <option value="" label=""></option>
              {record?.withoutCallCenter?.length > 0 &&
                record?.withoutCallCenter?.map((item) => (
                  <option value={item._id} key={item._id}>
                    {item.company_name}
                  </option>
                ))}
              {Object.keys(record)?.map((key) => (
                <optgroup label={key} key={key}>
                  {record[key]?.map((item) => (
                    <option value={item._id} key={item._id}>
                      {item.company_name}
                    </option>
                  ))}
                </optgroup>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="p-4 text-right">
          <Button
            text="Switch Now"
            className="mr-2"
            onClick={formik.handleSubmit}
            isLoading={isLoading ? isLoading : isLoader}
            variant="btn_submit"
          />
        </div>
      </div>
    </>
  );
};

export default SwitchOfficeModal;
