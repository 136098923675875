import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createGmailAccounts,
  deleteGmailAccounts,
  getGmailAccounts,
  updateGmailAccounts,
} from "../services/gmailService";

const initialState = {
  isError: false,
  isLoading: false,
  emailAccounts: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Google Seach Console
export const get_gmail_accounts = createAsyncThunk(
  "get_gmail_accounts",
  async (thunkAPI) => {
    try {
      return await getGmailAccounts();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Google Search Console
export const create_gmail_accounts = createAsyncThunk(
  "create_gmail_accounts",
  async (data, thunkAPI) => {
    try {
      return await createGmailAccounts(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Google Search Console
export const update_gmail_accounts = createAsyncThunk(
  "update_gmail_accounts",
  async (data, thunkAPI) => {
    try {
      return await updateGmailAccounts(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Google Search Console
export const delete_gmail_accounts = createAsyncThunk(
  "delete_gmail_accounts",
  async (id, thunkAPI) => {
    try {
      return await deleteGmailAccounts(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const gmailSlice = createSlice({
  name: "gmailSlice",
  initialState,
  reducers: {
    googleConsoleReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.emailAccounts = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_gmail_accounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_gmail_accounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.emailAccounts = action.payload?.data;
      })
      .addCase(get_gmail_accounts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.emailAccounts = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_gmail_accounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_gmail_accounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.emailAccounts?.push(action.payload.data);
      })
      .addCase(create_gmail_accounts.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_gmail_accounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_gmail_accounts.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.emailAccounts?.findIndex(
          ({ _id }) => _id === action?.payload?.data?.updatedDocument?._id
        );
        state.emailAccounts[result] = {
          ...state.emailAccounts[result],
          ...action.payload.data?.updatedDocument,
        };
      })
      .addCase(update_gmail_accounts.rejected, (state, action) => {
        console.log("👊 ~ .addCase ~ action:", action)
        state.isLoading = false;
      })
      .addCase(delete_gmail_accounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_gmail_accounts.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_gmail_accounts.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { googleConsoleReset } = gmailSlice.actions;
export default gmailSlice.reducer;
