import React from "react";
import FormInput from "../FormInput";
import FormSelectInput from "../FormSelectInput";

function VehicleTrailerDetailsSection({ formik, fieldsToShow = [] }) {
  function hasCommonElement(array) {
    return array.some((item) => fieldsToShow.includes(item));
  }
  return (
    <div class="flex flex-row gap-4 my-">
      {fieldsToShow.includes("payout_section") && (
        <div class="basis-1/2 border p-4 bg-stone-100">
          <div>
            <div className="flex space-x-2 mb-4 ">
              <div className="flex flex-col w-full">
                <label
                  htmlFor="select1-1"
                  className="font-semibold text-xs text-gray-500 mb-1"
                >
                  Is there a payout?
                </label>
                <FormSelectInput
                  name="extra_fields.is_payout"
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  convertor={(value) => (value ? value === "true" : "")}
                  formik={formik}
                  valueProp="value"
                  labelProp="label"
                  isCustomOption
                  value={formik.values?.extra_fields?.is_payout}
                />
              </div>
              <FormInput
                name="extra_fields.payout_amount"
                type="text"
                label="Amount of Payout"
                formik={formik}
                value={formik.values?.extra_fields?.payout_amount}
              />
            </div>
            <div className="flex flex-col w-full">
              <label
                htmlFor="select1-3"
                className="font-semibold text-xs text-gray-500 mb-1"
              >
                Are there paperwork needed?
              </label>
              <FormSelectInput
                name="extra_fields.is_paperwork_needed"
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                convertor={(value) => (value ? value === "true" : "")}
                formik={formik}
                valueProp="value"
                labelProp="label"
                isCustomOption
                value={formik.values?.extra_fields?.is_paperwork_needed}
              />
            </div>
          </div>
        </div>
      )}
      {hasCommonElement([
        "is_trailer",
        "weight",
        "height",
        "length",
        "width",
        "axles_count",
        "is_dually",
        "loaded_with",
        "brakes_be_release"
      ]) ? (
        <div class="w-full border py-4 px-2 bg-stone-100">
          {hasCommonElement([
            "is_trailer",
            "weight",
            "height",
            "length",
            "width",
          ]) ? (
            <div
              class={`grid grid-cols-2 md:grid-cols-3  gap-2 ${
                fieldsToShow?.length < 5
                  ? `lg:grid-cols-${fieldsToShow?.length}`
                  : "lg:grid-cols-5"
              }`}
            >
              {fieldsToShow.includes("is_trailer") && (
                <div class="flex flex-col">
                  <label
                    htmlFor="select2-1"
                    class="font-semibold text-xs text-gray-500 mb-1"
                  >
                    Does it have a trailer attached?
                  </label>
                  <FormSelectInput
                    name="extra_fields.is_trailer"
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    convertor={(value) => (value ? value === "true" : "")}
                    isCustomOption
                    valueProp="value"
                    labelProp="label"
                    formik={formik}
                    value={formik.values?.extra_fields?.is_trailer}
                  />
                </div>
              )}
              {fieldsToShow.includes("weight") && (
                <FormInput
                  name="extra_fields.weight"
                  type="text"
                  label="Weight (lb):"
                  placeholder={"Enter the weight"}
                  formik={formik}
                  value={formik.values?.extra_fields?.weight}
                />
              )}

              {fieldsToShow.includes("height") && (
                <FormInput
                  name="extra_fields.height"
                  type="text"
                  label="Height (ft):"
                  placeholder={"Enter the height"}
                  formik={formik}
                  value={formik.values?.extra_fields?.height}
                />
              )}

              {fieldsToShow.includes("length") && (
                <FormInput
                  name="extra_fields.length"
                  type="text"
                  label="Length (ft):"
                  placeholder={"Enter the length"}
                  formik={formik}
                  value={formik.values?.extra_fields?.length}
                />
              )}
              {fieldsToShow.includes("width") && (
                <FormInput
                  name="extra_fields.width"
                  type="text"
                  label="Width (ft):"
                  placeholder={"Enter the width"}
                  formik={formik}
                  value={formik.values?.extra_fields?.width}
                />
              )}
            </div>
          ) : null}

          {hasCommonElement(["axles_count", "is_dually", "loaded_with"]) ? (
            <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 !mt-3">
              {fieldsToShow.includes("axles_count") && (
                <FormInput
                  name="extra_fields.axles_count"
                  type="text"
                  label="How many axles does it have?"
                  placeholder={"Enter the value"}
                  formik={formik}
                  value={formik.values?.extra_fields?.axles_count}
                />
              )}
              {fieldsToShow.includes("is_dually") && (
                <div class="flex flex-col">
                  <label
                    htmlFor="select2-1"
                    class="font-semibold text-xs text-gray-500 mb-1"
                  >
                    Is it Dually:
                  </label>
                  <FormSelectInput
                    name="extra_fields.is_dually"
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    convertor={(value) => (value ? value === "true" : "")}
                    isCustomOption
                    formik={formik}
                    valueProp="value"
                    labelProp="label"
                    value={formik.values?.extra_fields?.is_dually}
                  />
                </div>
              )}
              {fieldsToShow.includes("loaded_with") && (
                <FormInput
                  name="extra_fields.loaded_with"
                  type="text"
                  label="Is it loaded? If Yes, What is it loaded with?"
                  placeholder={"Enter the value"}
                  formik={formik}
                  value={formik.values?.extra_fields?.loaded_with}
                />
              )}
              {fieldsToShow.includes("brakes_be_release") && (
                <div class="flex flex-col">
                  <label
                    htmlFor="select2-1"
                    class="font-semibold text-xs text-gray-500 mb-1"
                  >
                    Can brakes be released?
                  </label>
                  <FormSelectInput
                    name="extra_fields.brakes_be_release"
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    convertor={(value) => (value ? value === "true" : "")}
                    isCustomOption
                    formik={formik}
                    valueProp="value"
                    labelProp="label"
                    value={formik.values?.extra_fields?.brakes_be_release}
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default VehicleTrailerDetailsSection;
