import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  assignInbounToIndustry,
  createIndustry,
  deleteIndustry,
  getIndustriesDB,
  getIndustry,
  getIndustryDrd,
  toggleActive,
  updateIndustry,
} from "../services/IndustryService";
const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  record: [],
  industryDrd: [],
  industryDB: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Industry Data
export const get_industry = createAsyncThunk(
  "get_industry",
  async (token, thunkAPI) => {
    try {
      return await getIndustry(token);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Industry
export const get_industry_drd = createAsyncThunk(
  "get_industry_drd",
  async (data, thunkAPI) => {
    try {
      return await getIndustryDrd(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Industry
export const create_industry = createAsyncThunk(
  "create_industry",
  async (data, thunkAPI) => {
    try {
      return await createIndustry(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Industry
export const update_industry = createAsyncThunk(
  "update_industry",
  async (data, thunkAPI) => {
    try {
      return await updateIndustry(data?.id, data?.name);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Toggle Active
export const toggle_active = createAsyncThunk(
  "toggle_active",
  async (id, thunkAPI) => {
    try {
      return await toggleActive(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Industry
export const delete_industry = createAsyncThunk(
  "delete_industry",
  async (id, thunkAPI) => {
    try {
      return await deleteIndustry(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Delete Industry
export const assign_inbound_to_industry = createAsyncThunk(
  "assign_inbound_to_industry",
  async (data, thunkAPI) => {
    try {
      return await assignInbounToIndustry(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Delete Industry
export const get_industries_db = createAsyncThunk(
  "get_industries_db",
  async (data, thunkAPI) => {
    try {
      return await getIndustriesDB(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const IndustrySlice = createSlice({
  name: "industries",
  initialState,
  reducers: {
    industryReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.industryDrd = [];
      state.message = "";
    },
    updateTotalDeployedCount: (state, action) => {
      const result = state.industryDB.records.findIndex(
        ({ industry_name }) => industry_name === action?.payload?.industry
      );
      state.industryDB.records[result] = {
        ...state.industryDB?.records[result],
        ...action.payload,
      };
    },
    updateAPILink: (state, action) => {
      const result = state.industryDB.records.findIndex(
        (entry) => entry?.id === action?.payload?.id
      );
      state.industryDB.records[result] = {
        ...state.industryDB?.records[result],
        domain: action.payload.domain,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_industry.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_industry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_industry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_industry_drd.pending, (state) => {
        state.isLoading = true;
        state.industryDrd = [];
      })
      .addCase(get_industry_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        const sortedIndustries = Array.isArray(action.payload?.data)
          ? [...action.payload?.data].sort((a, b) =>
              a?.name?.localeCompare(b?.name)
            )
          : [];
        state.industryDrd = sortedIndustries;
      })
      .addCase(get_industry_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_industry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_industry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record?.records?.push(action.payload.data);
      })
      .addCase(create_industry.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_industry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_industry.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record?.records?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result] = {
          ...state.record?.records[result],
          ...action.payload.data,
        };
      })
      .addCase(update_industry.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_industry.fulfilled, (state, action) => {
        const result = state.record?.records?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record?.records[result],
          ...action.payload.data,
        };
      })
      .addCase(assign_inbound_to_industry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assign_inbound_to_industry.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record?.records?.findIndex(
          ({ _id }) => _id === action?.payload?.data?.industry_id
        );
        state.record.records[result] = {
          ...state.record?.records[result],
          ...action.payload.data,
        };
      })
      .addCase(assign_inbound_to_industry.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(toggle_active.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(toggle_active.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record?.records?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result] = {
          ...state.record?.records[result],
          ...action.payload.data,
        };
      })
      .addCase(toggle_active.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_industries_db.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_industries_db.fulfilled, (state, action) => {
        state.isLoading = false;
        state.industryDB = action.payload?.data;
      })
      .addCase(get_industries_db.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.industryDB = [];
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      });
  },
});
export const { industryReset, updateTotalDeployedCount, updateAPILink } =
  IndustrySlice.actions;
export default IndustrySlice.reducer;
