import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Button, FormInput, Loader } from "../../components";
import labels from "../Lead/Components/leads_labels.json";
import axios from "axios";
import { MdFullscreen } from "react-icons/md";
import { FaTimes } from "react-icons/fa";
const QuotingModal = ({ onClose, invoiceDetail, reloadReport }) => {
  const [isLoader, setIsLoader] = useState(false);
  const [isInMiles, setIsInMiles] = useState(true);
  const [amount, setAmount] = useState(invoiceDetail?.service_cost || 0);
  const [isWeatherInCelc, setIsWeatherInCelc] = useState(true);
  const [weatherData, setWeatherData] = useState(null); // State to store API response
  const API_KEY = "aef9e2f1f19d41b49ed152539241911";
  // const API_KEY = "1b1d7c9cd71c0a4ce94529749ed6e8da";
  // useEffect(() => {
  //   if (invoiceDetail?.pickup_latitude && invoiceDetail?.pickup_longitude) {
  //     const fetchWeather = async () => {
  //       try {
  //         const response = await axios.get(
  //           `https://api.openweathermap.org/data/2.5/weather?lat=${invoiceDetail.pickup_latitude}&lon=${invoiceDetail.pickup_longitude}&appid=${API_KEY}&units=metric`
  //         );
  //         setWeatherData(response.data);
  //       } catch (error) {
  //         console.error("Error fetching weather data:", error);
  //       }
  //     };

  //     fetchWeather();
  //   }
  // }, [invoiceDetail]);
  useEffect(() => {
    if (invoiceDetail?.pickup_latitude && invoiceDetail?.pickup_longitude) {
      const fetchWeather = async () => {
        try {
          const response = await axios.get(
            `https://api.weatherapi.com/v1/current.json?key=${API_KEY}&q=${invoiceDetail?.pickup_latitude},${invoiceDetail?.pickup_longitude}&aqi=no`
          );
          setWeatherData(response.data);
        } catch (error) {
          console.error("Error fetching weather data:", error);
        }
      };

      fetchWeather();
    }
  }, [invoiceDetail]);
  const handleConfirm = async (status) => {
    setIsLoader(true);
    try {
      const res = await api.post(
        `/api/leads/${invoiceDetail?._id}/update_quoting_price`,
        {
          status: status,
          price: amount,
        }
      );
      if (res.status === 200) {
        toast.success("Invoice created successfully");
        reloadReport();
        onClose();
      } else {
        toast.error(res?.data?.message || "Invoice couldn't be created");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || "Invoice couldn't be created"
      );
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const formatValue = (val) => {
    if (typeof val !== "boolean" && !val) return null;
    if (typeof val === "boolean") {
      return val ? "Yes" : "No";
    }
    if (typeof val === "number") {
      return val;
    }
    if (val && !val?.includes("_"))
      return val?.charAt(0).toUpperCase() + val?.slice(1);
    return val
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  };
  const renderItem = (label, value) => (
    <div className="flex gap-x-1">
      <label className="text-client-50 font-medium text-sm">{label}</label>{" "}
      <span className="font-medium text-sm">{formatValue(value)}</span>
    </div>
  );
  const renderItemHeader = (label) => (
    <div className="col-span-1 text-client-100 pb-1.25 pl-0 border-b border-client-50 ml-2.5 mb-1.25 font-bold">
      {label}
    </div>
  );
  const hasKey = (obj, searchedKey) => {
    return Object.keys(obj).some((key) => key.includes(searchedKey));
  };
  const convertDistance = (distanceInMiles) => {
    // Conversion factor for miles to kilometers
    const MILES_TO_KM = 1.60934;

    if (isInMiles) {
      return `${distanceInMiles} `;
    } else if (!isInMiles) {
      const distanceInKm = (distanceInMiles * MILES_TO_KM).toFixed(2); // Convert and round to 2 decimal places
      return `${distanceInKm} `;
    } else {
      throw new Error("Invalid unit. Please use 'miles' or 'km'.");
    }
  };
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[500px] max-w-[90%]">
        <div className="flex w-full justify-between items-start">
          <h2 className="text-lg font-semibold mb-4">Quoting Request</h2>
          <button className="hover:text-red-500" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="overflow-y-auto max-h-[80vh]">
          <div className="relative flex flex-col">
            <div className="grid grid-cols-2 gapx-2">
              <div>
                {renderItem("Industry:", invoiceDetail?.industry_id?.name)}
                {invoiceDetail?.job_category_id
                  ? renderItem(
                      "Job Category:",
                      invoiceDetail?.job_category_id?.name
                    )
                  : null}
              </div>
              <div>
                <div className="grid grid-cols-2 gap-x-2">
                  {renderItem("Type of Call:", invoiceDetail?.payment_type)}
                  {invoiceDetail?.payment_type === "Insurance"
                    ? renderItem(
                        "Insurance Name:",
                        invoiceDetail?.extra_fields?.insurance_name
                      )
                    : null}
                </div>
                {renderItem("Lead Number:", invoiceDetail?.lead_num)}
              </div>
            </div>
            <FormInput
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
              name="amount"
              placeholder={"Service Cost"}
              label="Service Cost"
              className={"my-3"}
              type={"number"}
            />
          </div>
          <div className="pt-0 w-auto m-auto ">
            <div className={`md:p-2 `}>
              <div className="!p-3 bg-white rounded shadow-client">
                {/* Basic  Information */}
                <div>
                  {renderItemHeader("Basic Information")}
                  <div className="ml-3 grid grid-cols-2 gap-x-4">
                    {renderItem("Name:", invoiceDetail?.name)}
                    {renderItem("Phone:", invoiceDetail?.phone)}
                    {renderItem(
                      "Alternate No:",
                      invoiceDetail?.alternate_phone
                    )}
                    {renderItem("Email:", invoiceDetail?.email)}
                  </div>
                </div>
                {/* Pickup & Drop Location */}
                <div>
                  {renderItemHeader("Pickup & Drop Location")}

                  <div className="ml-3 grid grid-cols-1">
                    <div className="grid grid-cols-4 gap-x-3">
                      <div className="col-span-3">
                        {renderItem(
                          "Pickup Address:",
                          invoiceDetail?.complete_pickup_address
                        )}
                        {invoiceDetail?.complete_drop_address
                          ? renderItem(
                              "Drop Address:",
                              invoiceDetail?.complete_drop_address
                            )
                          : null}
                      </div>
                      <div className="col-span-1">
                        {renderItem(
                          "Weather Condition:",
                          weatherData
                            ? weatherData?.current?.condition?.text
                            : ""
                        )}
                        <div className="flex gap-x-1">
                          <label className="text-client-50">
                            {"Weather Temp:"}
                          </label>{" "}
                          {weatherData?.current ? (
                            <span>
                              {isWeatherInCelc
                                ? `${weatherData?.current?.temp_c || ""}`
                                : `${weatherData?.current?.temp_f || ""}`}{" "}
                              <span
                                className="text-blue-500 hover:underline cursor-pointer"
                                onClick={() =>
                                  setIsWeatherInCelc(!isWeatherInCelc)
                                }
                              >
                                °{isWeatherInCelc ? "C" : "F"}
                              </span>
                            </span>
                          ) : null}
                        </div>
                        {/* {renderItem(
                          "Weather Temperature:",
                          weatherData ? `${weatherData?.main?.temp}°C` : ""
                        )} */}
                      </div>
                    </div>

                    {invoiceDetail?.complete_drop_2_address
                      ? renderItem(
                          "2nd Drop off Address:",
                          invoiceDetail?.complete_drop_2_address
                        )
                      : null}
                    {invoiceDetail?.distance ? (
                      <div className="flex gap-x-1">
                        <label className="text-client-50">{"Distance:"}</label>{" "}
                        <span>
                          {convertDistance(invoiceDetail?.distance)}{" "}
                          <span
                            className="text-blue-500 hover:underline cursor-pointer"
                            onClick={() => setIsInMiles(!isInMiles)}
                          >
                            {isInMiles ? "miles" : "km"}
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="!p-3 bg-white rounded shadow-client mt-2">
                <div>
                  {renderItemHeader("Service & Car Info")}
                  {invoiceDetail?.extra_fields ? (
                    <>
                      <div className="ml-3 grid grid-cols-3 gap-x-4 gap-y-1">
                        {Object.keys(invoiceDetail?.extra_fields)
                          ?.filter(
                            (key) =>
                              !key.includes("replacement_car") &&
                              key !== "replacement_license_number"
                          )
                          ?.map((key) => {
                            const label = labels[key] || key;
                            return renderItem(
                              label,
                              invoiceDetail?.extra_fields[key]
                            );
                          })}
                      </div>
                      {hasKey(
                        invoiceDetail?.extra_fields,
                        "replacement_car"
                      ) ? (
                        <div className="col-span-3">
                          {renderItemHeader("Replacement vehicle")}
                          <div className="ml-3 grid grid-cols-3 gap-x-4 gap-y-1">
                            {Object.keys(invoiceDetail?.extra_fields)
                              ?.filter(
                                (key) =>
                                  key.includes("replacement_car") ||
                                  key === "replacement_license_number"
                              )
                              ?.map((key) => {
                                const label = labels[key] || key;
                                return renderItem(
                                  label?.replace("Replacement vehicle ", ""),
                                  invoiceDetail?.extra_fields[key]
                                );
                              })}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div className="ml-3 grid grid-cols-4 gap-x-4 gap-y-1">
                      {renderItem("Year:", invoiceDetail?.car_year)}
                      {renderItem("Make:", invoiceDetail?.car_make)}
                      {renderItem("Model:", invoiceDetail?.car_model)}
                      {renderItem(
                        "Is Neutral?",
                        invoiceDetail?.is_neutral ? "Yes" : "No"
                      )}
                    </div>
                  )}
                  <div className="!mt-3">
                    <div className="flex gap-x-1">
                      <span className="col-span-1 text-client-100 pb-1.25 pl-0 ml-2.5 mb-1.25 font-bold">
                        Additional Info/Instructions:
                      </span>
                      <span>{invoiceDetail?.special_instruction}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap gap-x-2 mt-2">
                {invoiceDetail?.attachments?.map((img, index) => {
                  const enterFullScreen = (src) => {
                    const imgElement = document.createElement("img");
                    imgElement.src = src;
                    imgElement.style.width = "100%";
                    imgElement.style.height = "100%";
                    imgElement.style.objectFit = "contain";
                    imgElement.style.backgroundColor = "black";
                    const fullScreenDiv = document.createElement("div");
                    fullScreenDiv.style.position = "fixed";
                    fullScreenDiv.style.top = "0";
                    fullScreenDiv.style.left = "0";
                    fullScreenDiv.style.width = "100vw";
                    fullScreenDiv.style.height = "100vh";
                    fullScreenDiv.style.zIndex = "9999";
                    fullScreenDiv.style.display = "flex";
                    fullScreenDiv.style.justifyContent = "center";
                    fullScreenDiv.style.alignItems = "center";
                    fullScreenDiv.appendChild(imgElement);
                    fullScreenDiv.addEventListener("click", () => {
                      document.body.removeChild(fullScreenDiv);
                    });
                    document.body.appendChild(fullScreenDiv);
                  };

                  return (
                    <div className="relative w-56 flex-shrink-0" key={index}>
                      <img
                        src={
                          img.includes("merchants")
                            ? `${process.env.REACT_APP_BACKEND_URL}/${img}`
                            : img
                        }
                        alt="Car"
                        className="w-56 h-40 shadow m- rounded"
                      />
                      <button
                        type="button"
                        onClick={() =>
                          enterFullScreen(
                            `${process.env.REACT_APP_BACKEND_URL}/${img}`
                          )
                        }
                        className="absolute !p-1 bg-white top-0 right-0 w-7 h-7 rounded-bl-xl flex justify-center items-center hover:text-red-500"
                      >
                        <MdFullscreen size={20} />
                      </button>
                    </div>
                  );
                })}
              </div>

              <div className="flex justify- !mt-4">
                {isLoader ? (
                  <Loader />
                ) : (
                  <div className="flex justify-between w-full">
                    <Button
                      text="Close"
                      variant="btn_cancel"
                      onClick={onClose}
                    />
                    <div className="flex gap-x-2">
                      <Button
                        text="Reject"
                        variant="btn_danger"
                        onClick={() => handleConfirm("Rejected")}
                      />
                      <Button
                        text="Accept"
                        variant="btn_submit"
                        onClick={() => handleConfirm("Quoted")}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotingModal;
