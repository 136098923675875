import axios from "axios";
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";
import socket from "../components/organisms/SMS/socket";
import jwtDecode from "jwt-decode";
import { logoutUser } from "../features/authSlice";
import { getDispatch } from "../util/reduxUtils";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const { accessToken, refreshToken } = localStorage;
    if (accessToken) {
      const jwt = jwtDecode(accessToken);
      const isExpired = dayjs.unix(jwt.exp).diff(dayjs()) < 3;
      if (!isExpired) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        logout(refreshToken);
      }
    }
    return config;
  },
  (error) => {
    console.error("Error in request interceptor", error);
    return Promise.reject(error);
  }
);

const MAX_RETRIES = 5;

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && error.config.url !== "/api/auth") {
      logout();
      return null;
    }
    if (
      (error.response?.status === 504 ||
        error.message.includes("Network Error")) &&
      !originalRequest._retryCount
    ) {
      originalRequest._retryCount = 0; // Initialize retry count
    }

    if (
      (error.response?.status === 504 || error.message.includes("Network Error")) &&
      originalRequest._retryCount < MAX_RETRIES
    ) {
      originalRequest._retryCount = originalRequest._retryCount || 0; // Initialize if undefined
      originalRequest._retryCount += 1;
    
      console.warn(
        `Retrying request... (${originalRequest._retryCount}/${MAX_RETRIES})`
      );
    
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          api(originalRequest)
            .then(resolve) // Resolve the promise on success
            .catch(reject); // Reject the promise on failure
        }, 500); // Retry after 500ms
      });
    }
    // If retries are exhausted, log the error and return to the user
    if (originalRequest._retryCount >= MAX_RETRIES) {
      console.error("Max retries reached. Returning error to user.");
      // Sentry.captureException(error);
      return Promise.reject(error);
    }
    // Sentry.captureException(error);
    return Promise.reject(error);
  }
);

function logout() {
  const dispatch = getDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const eventName = user?.current_merchant_id + "_quotingRequest";
  socket.off(eventName);
  socket.disconnect();
  dispatch(logoutUser());
  axios.get("/api/logout", {
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });
  // window.location.reload();
}

export default api;
