import React, { useState } from "react";
import { useFormik } from "formik";
import Stepper from "react-stepper-horizontal";
import { id, email } from "../../images";
import CustomSelect from "../../views/Client/Components/Selectors";
import api from "../../services/api";
import PhoneInput from "react-phone-number-input";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { DotsLoader } from "../../components";
import { useNavigate } from "react-router-dom";

function AddUpdateLeadPage() {
  const navigate = useNavigate();
  const [errors] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editLeadsource, setEditleadsource] = useState();
  // State to keep track of phone numbers
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  // Function to add a new phone number field
  const addPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, ""]);
  };
  // Function to remove empty phone numbers from the state
  const removeEmptyPhoneNumbers = () => {
    return phoneNumbers.filter(phone => phone.trim() !== "");
  };
  // Function to remove a phone number by its index
  const removePhoneNumber = (index) => {
    const updatedPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
    setPhoneNumbers(updatedPhoneNumbers);
  };
  // Function to handle changes in input fields
  const handlePhoneNumberChange = (index, event) => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers[index] = event;
    setPhoneNumbers(updatedPhoneNumbers);
  };

  const sections = [
    { title: "Lead Source" },
    { title: "Lead Source Account" }
  ];

  const [currentStep, setCurrentStep] = React.useState(1);
  let initialValues = {
    title: "",
    alternate_title: "",
    email: "",
    active: true,
    isActive: true,
    type: "",
  }
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (currentStep === 1) {
        handlestep1Form(values)
      }
      if (currentStep === 2) {
        handlestep2Form(values)
      }
    }
  })
  const validateForm = (formData) => {
    const newErrors = {};
    // Validate each field
    if (!formData.title.trim()) {
      newErrors.title = "Title is required";
    }
    if (!formData.alternate_title.trim()) {
      newErrors.alternate_title = "Alternate Title is required";
    }
    // Email validation (basic pattern)
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    return newErrors;  // Return true if there are no errors
  };
  const handlestep1Form = async (values) => {
    let error = validateForm(values);
    if (Object.keys(error).length) {
      return toast.error(Object.values(error).toString());
    }
    let payload = {
      title: values.title,
      alternate_title: values.alternate_title,
      email: values.email,
      isActive: values.active
    }
    try {
      setIsLoading(true);
      const res = await api.post("api/lead_source/add", payload);
      if (res.status === 201 || res.status === 200) {
        setEditleadsource(res.data)
        setCurrentStep(currentStep + 1)
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }
  const validate2Form = (formData) => {
    const newErrors = {};
    // Validate each field
    if (!formData.type.trim()) {
      newErrors.type = "Type is required";
    }
    return newErrors;  // Return true if there are no errors
  };
  const handlestep2Form = async (values) => {
    let error = validate2Form(values);
    if (Object.keys(error).length) {
      return toast.error(Object.values(error).toString());
    }
    let payload = {
      leadSourceId: editLeadsource._id,
      type: values.type,
      inboundPhoneNumbers: values.type === "InboundCall"?removeEmptyPhoneNumbers():[],
      isActive: values.isActive,
    }
    try {
      setIsLoading(true);
      const res = await api.post("api/lead_source_account", payload);
      if (res.status === 200 || res.status === 201) {
        navigate("/leadsource/list");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }
  const activeOptions = [{ label: "Yes", value: true }, { label: "No", value: false }]
  const callType = [{ label: "Web Form", value: "WebForm" }, { label: "Inbound Call", value: "InboundCall" }]

  return (
    <>
      {isLoading ? <DotsLoader /> : null}
      <div>
        <Stepper
          steps={sections}
          activeStep={currentStep}
          activeColor="#d9d9d9"
          defaultBarColor="#858484"
          completeColor="#5b6eb6"
          completeBarColor="#5b6eb6"
          size={24}
          titleFontSize={12}
          circleFontSize={14}
        />
        <div className="container-fluid mt-4">
          <div
            className=
            "bg-white p-3.7 rounded-xl shadow-[0_4px_20px_rgba(59,_89,_153,_0.15)] mb- mt-24"
          >
            <div className="flex flex-col ">
              {currentStep === 1 && <form>
                <div className="grid lg:grid-cols-2 gap-4">
                  <CustomSelect
                    icon={id}
                    label="Please enter an title."
                    placeholder="Title"
                    isInput={true}
                    formik={formik}
                    name="title"
                    value={formik?.values?.title}
                    errors={errors}
                  />
                  <CustomSelect
                    icon={id}
                    label="Please enter an Alternate title."
                    placeholder="Alternate Title"
                    isInput={true}
                    formik={formik}
                    name="alternate_title"
                    value={formik?.values?.alternate_title}
                    errors={errors}
                  />
                </div>
                <div className="grid lg:grid-cols-2 gap-4">
                  <CustomSelect
                    icon={email}
                    label="Please enter an email."
                    placeholder="Email"
                    isInput={true}
                    formik={formik}
                    name="email"
                    value={formik?.values?.email}
                    errors={errors}
                  />

                  <CustomSelect
                    icon={id}
                    label="Active"
                    placeholder="Active"
                    isSelect={true}
                    options={activeOptions}
                    formik={formik}
                    value={formik.values.active}
                    valueProp={formik.values.active}
                    name="active"
                  />
                </div>
              </form>
              }
              {currentStep === 2 && <form>

                <div className="grid lg:grid-cols-1 gap-4">
                  <CustomSelect
                    icon={id}
                    label="Type"
                    placeholder="Type"
                    isSelect={true}
                    options={callType}
                    formik={formik}
                    name="type"
                  />
                </div>
                {formik.values.type === 'InboundCall' && <div className="grid lg:grid-cols-1 gap-4 mb-4">
                  <button className="py-1.5 px-3 align-middle next ml-auto flex self-center justify-self-center text-white float-right bg-client-100 border-client-100" onClick={addPhoneNumber} type="button">Add Phone Number</button>
                  {phoneNumbers.map((phoneNumber, index) => (
                    <div key={index}>
                      <div className="flex flex-row items-center">
                        <PhoneInput
                          defaultCountry="US"
                          className="shadow appearance-none border rounded max-[400px]:w-[100%] min-[400px]:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded-l-none"
                          onChange={event => {
                            handlePhoneNumberChange(index, event)
                          }}
                          value={phoneNumber}
                          placeholder={`Phone Number ${index + 1}`}
                          initialValueFormat="national"
                        />
                        <FaTrash className="ml-2 mr-2 cursor-pointer"
                          onClick={() => {
                            removePhoneNumber(index)
                          }}
                          title="Remove"
                        />
                      </div>
                    </div>
                  ))}
                </div>}
                  <div className="grid lg:grid-cols-1 gap-4">
                    <CustomSelect
                      icon={id}
                      label="Active"
                      placeholder="Active"
                      isSelect={true}
                      options={activeOptions}
                      formik={formik}
                      value={formik.values.isActive}
                      name="isActive"
                    />
                  </div>
              </form>}

            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-2 mt-4 mr-4">
          <button
            className="py-1.5 px-3 align-middle next ml-auto flex self-center justify-self-center text-white float-right bg-client-100 border-client-100"
            onClick={formik.handleSubmit}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default AddUpdateLeadPage;
