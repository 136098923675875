import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const Loader = (props) => {
  const { linear, size, color, width, height } = props;

  return (
    <Box
      sx={{
        height: size || null,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {linear ? (
        <LinearProgress
          sx={{
            width: width || 100,
            height: height || 6,
            color: color || "rgba(255, 183, 0, 1)",
          }}
        />
      ) : (
        <CircularProgress
          style={{
            color: color || "rgba(255, 183, 0, 1)",
            width: width || 40,
            height: height || 40,
          }}
        />
      )}
    </Box>
  );
};

export default Loader;
