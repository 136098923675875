let dispatchFunction = null;

export const injectDispatch = (dispatch) => {
  dispatchFunction = dispatch;
};

export const getDispatch = () => {
  if (!dispatchFunction) {
    throw new Error("Dispatch function is not injected yet.");
  }
  return dispatchFunction;
};
