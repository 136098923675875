import React, { useState, useEffect, useCallback, useRef } from "react";
import api from "../../../../../src/services/api";

const Autocomplete = ({
  apiUrl,
  onSelect,
  queryParam = "query",
  getLabel = (item) => item.name,
  getValue = (item) => item.phone,
  placeholder = "Search...",
  debounceDelay = 500,
  chatFilter,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isDataStatus, setDataStatus] = useState(false);
  const inputRef = useRef(null);
  let userTtype;
  if (chatFilter === "vendor") {
    userTtype = "Vendor";
  } else if (chatFilter === "client") {
    userTtype = "Client";
  } else {
    userTtype = "";
  }

  const fetchSuggestions = useCallback(async () => {
    if (inputValue.trim() === "" || isDataStatus) {
      setSuggestions([]);
      return;
    }

    setIsLoading(true);
    const payload = {
      pattern: inputValue,
      type: userTtype,
    };
    try {
      const response = await api.post(apiUrl, payload);
      if (response.status === 200 || response.status === 201) {
        // Check if data is returned from API
        const apiData = response?.data?.data || [];
        setSuggestions(apiData);

        // If no data from API, add the input value as suggestion (if it's a valid number)
        if (
          apiData.length === 0 &&
          inputValue.length >= 10 &&
          !isNaN(inputValue)
        ) {
          setSuggestions([{ name: "", phone: inputValue }]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [inputValue, apiUrl, userTtype, isDataStatus]);

  useEffect(() => {
    if (isSelecting) return;

    const timeoutId = setTimeout(() => {
      fetchSuggestions();
    }, debounceDelay);

    return () => clearTimeout(timeoutId);
  }, [inputValue, fetchSuggestions, debounceDelay, isSelecting]);

  const handleSelect = (suggestion) => {
    setIsSelecting(true);
    setInputValue(getLabel(suggestion));
    setSuggestions([]);
    onSelect(suggestion);
    setDataStatus(true);
    setIsSelecting(false);
  };

  const handleInputChange = (e) => {
    const newInputValue = e.target.value;
    setInputValue(newInputValue);
    setDataStatus(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <div className="relative w-full px-3.0 !my-2">
      <input
        ref={inputRef}
        type="text"
        className="shadow-sm appearance-none border rounded-sm w-full !py-2 !pr-3 pl-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm h-[31px]"
        style={{ minHeight: 31 }}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
      />

      {isLoading && (
        <div className="absolute right-0 top-0 flex items-center justify-center h-full w-10 p-2 mr-3">
          <img src="/loading.gif" className="w-5" alt="loading" />
        </div>
      )}

      {suggestions.length > 0 && (
        <ul className="absolute top-full left-4 w-full bg-white border mt-1 max-h-60 overflow-y-auto z-10">
          {suggestions.map((suggestion) => (
            <li
              key={getValue(suggestion)}
              className="p-2 cursor-pointer border-b border-b-gray-300 text-sm bg-orange-50 text-black"
              onClick={() => handleSelect(suggestion)}
            >
              {suggestion?.name ? `${suggestion.name} | ` : ""}{" "}
              {suggestion?.phone ? `${suggestion.phone}` : ""}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
