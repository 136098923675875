import React, { useState, useRef } from "react";
import {
  GoogleMap,
  DrawingManager,
  Polygon,
  Marker,
  Polyline,
  Autocomplete,
} from "@react-google-maps/api";
import RenderShapes from "./RenderShapes";
import GoogleScript from "../../components/molecules/GoogleMapScript";

// Calculate the distance between two points using the Haversine formula
const calculateDistance = (lat1, lng1, lat2, lng2) => {
  const degToRad = (deg) => deg * (Math.PI / 180);
  const R = 6371; // Earth's radius in kilometers

  const dLat = degToRad(lat2 - lat1);
  const dLng = degToRad(lng2 - lng1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRad(lat1)) *
      Math.cos(degToRad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};
// Function to check if a point is within a circle
const isPointInCircle = (point, center, radius) => {
  const distance = calculateDistance(
    point.lat,
    point.lng,
    center.lat,
    center.lng
  );
  return distance <= radius;
};

// Function to check if a point is within a polygon
const isPointInPolygon = (point, paths) => {
  const googlePolygon = new window.google.maps.Polygon({ paths });
  return window.google.maps.geometry.poly.containsLocation(
    point,
    googlePolygon
  );
};

// Function to check if a point is within a rectangle
const isPointInRectangle = (point, bounds) => {
  const { lat, lng } = point;
  // const { north, south, east, west } = bounds;
  const swLatLng = bounds.getSouthWest();
  const neLatLng = bounds.getNorthEast();
  const south = swLatLng.lat();
  const west = swLatLng.lng();
  const north = neLatLng.lat();
  const east = neLatLng.lng();
  return lat >= south && lat <= north && lng >= west && lng <= east;
};
// Function to check if any lat long exists within the drawn radius/shape
const checkPointsWithinRadius = (pointsArray, shape) => {
  const { type, radius, paths, bounds } = shape;
  const radiusInKG = radius / 1000;
  const pointsWithinRadius = pointsArray.filter((point) => {
    if (type === "circle") {
      return isPointInCircle(point, paths, radiusInKG);
    } else if (type === "polygon") {
      return isPointInPolygon(point, paths);
    } else if (type === "rectangle") {
      return isPointInRectangle(point, bounds);
    } else {
      // Add support for other shape types if needed
      return false;
    }
  });
  return pointsWithinRadius;
};
const CustomDrawingControl = ({
  drawingManager,
  isDraw,
  setIsDraw,
  onChangeAddress,
  onLoadPickup,
}) => {
  const handleDrawingMode = (drawingMode) => {
    drawingManager.setDrawingMode(drawingMode);
  };
  const stopDrawingMode = () => {
    drawingManager.setDrawingMode(null); // Set drawing mode to null to stop drawing
  };
  const autocompleteStyle = {
    boxSizing: "border-box",
    border: "1px solid transparent",
    width: "300px",
    height: "38px",
    padding: "0 12px",
    borderRadius: "3px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    fontSize: "14px",
    outline: "none",
    textOverflow: "ellipses",
    left: "8%",
    top: "10px",
  };
  return (
    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 flex  bg-black/60 w-full p-2 flex justify-between">
      <div className="flex flex-row space-x-2">
        <Autocomplete
          onPlaceChanged={() => onChangeAddress("pickup")}
          onLoad={(autocomplete) => onLoadPickup(autocomplete)}
        >
          <input autoFocus style={autocompleteStyle} />
        </Autocomplete>
        {isDraw ? (
          <>
            <button
              className="bg-white border border-gray-400 px-3 py-1 rounded hover:bg-gray-100"
              onClick={() =>
                handleDrawingMode(window.google.maps.drawing.OverlayType.CIRCLE)
              }
            >
              Circle
            </button>
            <button
              className="bg-white border border-gray-400 px-3 py-1 rounded hover:bg-gray-100"
              onClick={() =>
                handleDrawingMode(
                  window.google.maps.drawing.OverlayType.RECTANGLE
                )
              }
            >
              Rectangle
            </button>
            <button
              className="bg-white border border-gray-400 px-3 py-1 rounded hover:bg-gray-100"
              onClick={() =>
                handleDrawingMode(
                  window.google.maps.drawing.OverlayType.POLYGON
                )
              }
            >
              Polygon
            </button>
            <button
              className="bg-white border border-gray-400 px-3 py-1 rounded hover:bg-gray-100"
              onClick={stopDrawingMode}
            >
              Stop Drawing
            </button>
          </>
        ) : null}
      </div>
      <div className=" top-0  flex space-x-2">
        {isDraw ? (
          <>
            <button
              onClick={() => setIsDraw(false)}
              className="bg-white border border-gray-400 px-3 py-1 rounded hover:bg-gray-100"
            >
              Cancel
            </button>
            <button className="bg-white border border-gray-400 px-3 py-1 rounded hover:bg-gray-100">
              Apply
            </button>
          </>
        ) : (
          <button
            onClick={() => setIsDraw(true)}
            className="bg-white border border-gray-400 px-3 py-1 rounded hover:bg-gray-100"
          >
            Draw
          </button>
        )}
      </div>
    </div>
  );
};
const MapWithRadius = () => {
  const [drawShapes, setDrawShapes] = useState([]);
  const [isDraw, setIsDraw] = useState(false);
  const [pointsInShape, setPointsInShape] = useState([]);
  const [currentDrawing, setCurrentDrawing] = useState([]);
  const [currentLatLng, setCurrentLatLng] = useState({
    lat: 33.7535679,
    lng: -118.0830047,
  });
  const [autocomplete, setAutocomplete] = useState();
  const [map, setMap] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [customPointsArray, setPointsArray] = useState([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const drawingManagerRef = useRef(null);
  React.useEffect(() => {
    // Attach the event listener to the document object to handle mouseup event anywhere on the page
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      // Clean up the event listener when the component is unmounted
      document.removeEventListener("mouseup", handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDrawingComplete = (shapes) => {
    drawingManagerRef.current.setDrawingMode(null);
    const shapeProps = shapes?.overlay;
    if (shapeProps) {
      const shapeType = shapes.type;
      const shapeData = {};

      if (shapeType === "circle") {
        shapeData.center = shapeProps.center;
        shapeData.radius = shapeProps.radius;
        shapeData.paths = {
          lat: shapeProps?.center?.lat(),
          lng: shapeProps?.center?.lng(),
        };
      } else if (shapeType === "rectangle") {
        shapeData.bounds = shapeProps.bounds;
      } else if (shapeType === "polygon") {
        const paths = shapeProps.getPath();
        if (paths) {
          shapeData.paths = paths.getArray().map((latLng) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
          }));
        } else {
          console.error("Polygon paths are undefined.");
        }
      }
      shapes.overlay?.setMap(null);
      setDrawShapes([...drawShapes, { type: shapeType, ...shapeData }]);
      // Calculate points within the shape whenever the shape is drawn or edited
      calculatePointsInShape({ type: shapeType, ...shapeData });
    }
  };
  // Function to calculate points within the shape
  const calculatePointsInShape = (data) => {
    if (data?.type && data) {
      const pointsWithinShape = checkPointsWithinRadius(pointsArray, data);
      setPointsInShape(pointsWithinShape);
    } else {
      setPointsInShape([]); // Reset pointsInShape to an empty array
    }
  };
  const pointsArray = [
    { lat: 32.2572, lng: 75.1604 },
    { lat: 32.2598, lng: 75.1631 },
    { lat: 30.2598, lng: 80.1631 },
    // Add more points to the array
  ];
  // Custom Shape start
  const handleMouseDown = (event) => {
    if (isDrawing) {
      setIsMouseDown(true);
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setCurrentDrawing([{ lat, lng }]);
    }
  };

  const handleMouseMove = (event) => {
    if (isMouseDown) {
      const lat = event?.latLng.lat();
      const lng = event?.latLng.lng();
      setCurrentDrawing((prev) => [...prev, { lat, lng }]);
    }
  };

  const handleMouseUp = () => {
    if (true) {
      setIsDrawing(false);
      setIsMouseDown(false);
      setPointsArray((prev) => [...prev, ...currentDrawing]);
      // setCurrentDrawing([]);
    }
  };
  const handleMapDragEnd = () => {
    if (map) {
      const mapCenter = map.getCenter();
      const newLatLng = {
        lat: mapCenter.lat(),
        lng: mapCenter.lng(),
      };
      setCurrentLatLng(newLatLng);
    }
  };
  const fetchAndDisplayPolygon = () => {
    if (map) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: "90740" }, (results, status) => {
        if (status === "OK" && results[0]) {
          const boundaryPoints = results[0].geometry.bounds;
          calculatePointsInShape({ type: "rectangle", bounds: boundaryPoints });
          setDrawShapes([
            ...drawShapes,
            { type: "rectangle", bounds: boundaryPoints },
          ]);
          // Pan and zoom the map to fit the polygon
          map.fitBounds(boundaryPoints);
        } else {
          console.error(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      });
    }
  };
  const handleZoomIn = () => {
    if (map) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom + 1);
    }
  };

  const handleZoomOut = () => {
    if (map) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom - 1);
    }
  };

  const onLoadPickup = (autocomplete) => {
    setAutocomplete(autocomplete);
  };
  const onChangeAddress = async () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      extractAddress(place);
    } else {
      console.error("Autocomplete is not loaded yet!");
    }
  };
  const extractAddress = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setCurrentLatLng({ lat, lng });
  };
  const removeAllShapes = () => {
    setDrawShapes([]);
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setOptions({ drawingMode: null });
      drawingManagerRef.current.setDrawingMode(null);
    }
    setIsDraw(false);
  };
  return (
    <GoogleScript>
      <div style={{ position: "relative" }}>
        <GoogleMap
          zoom={13}
          onLoad={(map) => {
            setMap(map);
          }}
          // ref={mapRef}
          center={currentLatLng}
          onDragEnd={handleMapDragEnd}
          mapContainerStyle={{ width: "100%", height: "500px" }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          options={{
            disableDefaultUI: true,
            draggable: !isDrawing, // Disable map dragging while drawing
          }}
        >
          <CustomDrawingControl
            drawingManager={drawingManagerRef.current}
            onLoadPickup={onLoadPickup}
            onChangeAddress={onChangeAddress}
            setIsDraw={setIsDraw}
            isDraw={isDraw}
          />
          {isDraw ? (
            <>
              <DrawingManager
                onOverlayComplete={handleDrawingComplete}
                onLoad={(dm) => {
                  drawingManagerRef.current = dm;
                }}
                options={{
                  fillOpacity: 0.3,
                  fillColor: "#8aaae5",
                  strokeColor: "#8aaae5",
                  strokeWeight: 2,
                  // draggable: true,
                  editable: true,
                }}
              />

              {customPointsArray.length > 0 && (
                <Polygon path={customPointsArray} />
              )}
              {currentDrawing.length > 0 && (
                <Polyline
                  path={currentDrawing.map((point) => ({
                    lat: point.lat,
                    lng: point.lng,
                  }))}
                />
              )}
            </>
          ) : null}
          <RenderShapes shapes={drawShapes} />
          <Marker position={currentLatLng} />
        </GoogleMap>
        <button onClick={calculatePointsInShape}>Calculate Points</button>
        <div>
          <h2>Points within the drawn shape:</h2>
          <ul>
            {pointsInShape.map((point, index) => (
              <li
                key={index}
              >{`Latitude: ${point.lat}, Longitude: ${point.lng}`}</li>
            ))}
          </ul>
        </div>
        <button onClick={() => setIsDrawing(true)}>start Drawing</button>
        <button onClick={fetchAndDisplayPolygon}>Fetch Data</button>
        <button
          className="bg-white border border-gray-400 px-3 py-1 rounded hover:bg-gray-100"
          onClick={handleZoomIn}
        >
          Zoom In
        </button>
        <button
          className="bg-white border border-gray-400 px-3 py-1 rounded hover:bg-gray-100"
          onClick={handleZoomOut}
        >
          Zoom Out
        </button>
        <button onClick={removeAllShapes}>Remove All Shapes</button>
      </div>
    </GoogleScript>
  );
};

export default MapWithRadius;
