import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDaySelected,
  setSelectedEvent,
  setShowEventModal,
  setViewType,
} from "../../../../features/calendarEventSlice";

export default function Day({ day, rowIdx }) {
  const [dayEvents, setDayEvents] = useState([]);
  const { calendarEvents: filteredEvents } = useSelector(
    (state) => state?.calendarEvents
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const events = filteredEvents?.filter(
      (evt) =>
        dayjs(evt?.event?.start?.dateTime).format("DD-MM-YY") ===
        day.format("DD-MM-YY")
    );
    setDayEvents(events);
  }, [filteredEvents, day]);

  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "bg-blue-600 text-white rounded-full w-7"
      : "";
  }
  function transformData(item) {
    return {
      main_data: {
        _id: item._id,
        user_id: item.user_id,
        startDateTime: item.startDateTime,
        endDateTime: item.endDateTime,
        reminders: item.reminders,
      },
      ...item.event,
    };
  }
  // const transformedData = filteredEvents?.map(transformData);
  return (
    <div className="border border-gray-200 flex flex-col h-full overflow-y-auto">
      <header className="flex flex-col items-center">
        {rowIdx === 0 && (
          <p className="text-sm mt-1">{day.format("ddd").toUpperCase()}</p>
        )}
        <p
          className={`text-sm p-1 my-0.5 text-center  ${getCurrentDayClass()}`}
        >
          {day.format("DD")}
        </p>
      </header>
      <div className="flex-1 cursor-pointer">
        {dayEvents?.slice(0, 3)?.map((evt, idx) => (
          <div
            key={idx}
            onClick={() => {
              dispatch(setSelectedEvent(transformData(evt)));
              dispatch(setShowEventModal(true));
            }}
            className={`${
              evt.job_created ? "bg-indigo-200" : "bg-purple-200"
            } p-1 mx-1.5 text-gray-600 text-xs rounded mb-1 truncate`}
          >
            {evt?.event?.summary}
          </div>
        ))}
        {dayEvents?.length > 3 && (
          <div
            onClick={() => {
              dispatch(setDaySelected(day));
              dispatch(setViewType("day"));
            }}
            className={`bg-red-200 p-0.5 mx-0.5 text-gray-600 text-sm rounded truncate `}
          >
            See More {`- ${dayEvents?.length - 3}`}
          </div>
        )}
      </div>
    </div>
  );
}
