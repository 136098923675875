import React, { useEffect, useState } from "react";

import { DotsLoader } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { useDispatch, useSelector } from "react-redux";
import { get_industry_drd } from "../../features/IndustrySlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaEdit, FaPhone, FaSearch } from "react-icons/fa";
import { MdAddCircleOutline } from "react-icons/md";
import { Button as MUIButton } from "@mui/material";
import dayjs from "dayjs";
import api from "../../services/api";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { get_crm_vendors_drd, get_users_drd } from "../../features/usersSlice";
import {
  new_get_job_status_with_job_count,
} from "../../features/jobStatusSlice";
import { get_job_category } from "../../features/jobCategorySlice";
import { get_tags_drd } from "../../features/tagsSlice";
import { sendIframeMsg } from "../../util/iFrame";
import { call_by_link } from "../../features/dialerSlice";
import { job_call_logs } from "../../features/jobsSlice";
import { IoMdClose } from "react-icons/io";
import JobStatusModal from "./JobStatusModal";
import { getFormattedDate } from "../../util/common";
function JobListing() {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const { jobStatusId } = useParams();
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("searchValue");
  const searchBy = searchParams.get("searchBy");
  const dialer = useSelector((state) => state.dialer);
  const { usersDrd, CRMVendorsDrd } = useSelector((state) => state.users);
  const { industryDrd } = useSelector((state) => state.industry);
  const jobStatus = useSelector((state) => state.jobStatus);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedJob, setSelectedJob] = React.useState(jobStatusId || null);
  const [record, setRecord] = useState([]);
  const dispatch = useDispatch();
  const [vicidialId, setVicidialId] = React.useState(null);
  const [jobDetail, setJobDetail] = useState(null);
  const [isParent, setIsParent] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const tags = useSelector((state) => state.tags);
  const [isJobStatusModal, setJobStatusModal] = React.useState(false);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [],
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    distance: false,
    job_category_id: false,
    "virtual_cards_info.card_info.status": false,
  });
  const handleColumnVisibilityChange = (model) => {
    setColumnVisibilityModel(model);
  };
  const jobTypes = useSelector((state) => state.jobCategory);
  const getSERPReport = async (filter) => {
    setRecord([]);
    setIsLoading(true);
    let updatedRules = [
      ...(queryOptions?.rules || []).filter(
        (rule) => rule.field !== "job_status_id"
      ),
    ];
    // Add newRule only if selectedJob has a value
    if (selectedJob) {
      const newRule = {
        field: "job_status_id",
        op: "eq",
        data: selectedJob,
      };
      updatedRules.push(newRule);
    }

    // Add search filter if searchValue is provided
    if (searchValue) {
      const searchedFilter = {
        field: searchBy,
        op: "cn",
        data: searchValue,
      };
      updatedRules.push(searchedFilter);
    }
    const filters = {
      ...filter.filters,
      rules: updatedRules.length ? updatedRules : [],
    };
    const finalFilter = {
      ...filter,
      filters,
    };
    if (!finalFilter?.filters.rules?.length) delete finalFilter.filters;
    delete finalFilter.pageSize;

    try {
      const res = await api.post("api/jobs/job_report", finalFilter);
      if (res.status === 200) {
        setRecord(res.data);
      }
    } catch (err) {
      console.log("🚀 ~ getSERPReport ~ err:", err);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    dispatch(get_users_drd());
    dispatch(get_industry_drd());
    dispatch(new_get_job_status_with_job_count());
    // dispatch(get_crm_vendors_drd());
    dispatch(get_job_category());
    dispatch(get_tags_drd());

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (jobStatusId) {
      const newRule = {
        field: "job_status_id",
        op: "eq",
        data: jobStatusId,
      };
      const updatedRules = (queryOptions?.rules || []).filter(
        (rule) => rule.field !== "job_status_id"
      );
      updatedRules.push(newRule);
      const payload = {
        ...paginationModel,
        ...sortingModel,
        size: paginationModel.pageSize,
        filters: {
          ...queryOptions,
          rules: updatedRules,
        },
      };

      setSelectedJob(jobStatusId);
      getSERPReport(payload);
      // dispatch(get_jobs_data({ job_status_id: _id }));
    } else if (selectedJob) {
      const newRule = {
        field: "job_status_id",
        op: "eq",
        data: selectedJob,
      };
      const updatedRules = (queryOptions?.rules || []).filter(
        (rule) => rule.field !== "job_status_id"
      );
      if (newRule?.data) {
        updatedRules.push(newRule);
      }
      const payload = {
        ...paginationModel,
        ...sortingModel,
        size: paginationModel.pageSize,
        filters: {
          ...queryOptions,
          rules: updatedRules,
        },
      };
      getSERPReport(payload);
    } else {
      getSERPReport({
        ...paginationModel,
        ...sortingModel,
        size: paginationModel.pageSize,
        filters: queryOptions,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy, selectedJob]);
  const jobs = record?.records?.filter((record) => {
    const searchTextLower = searchText?.toLowerCase();
    function shouldSkipKey(key) {
      return (
        key.endsWith("_id") &&
        !Array.isArray(key) &&
        !(typeof key === "object" && key !== null)
      );
    }
    function searchInElement(element) {
      if (Array.isArray(element)) {
        return element.some((item) => searchInElement(item));
      } else if (typeof element === "object" && element !== null) {
        if (element.first_name && element.last_name) {
          const fullName =
            `${element.first_name} ${element.last_name}`.toLowerCase();
          if (fullName.includes(searchTextLower)) {
            return true;
          }
        }
        return Object.keys(element).some((key) => {
          if (shouldSkipKey(key)) return false;
          return searchInElement(element[key]);
        });
      } else {
        return element?.toString()?.toLowerCase().includes(searchTextLower);
      }
    }
    // Start the search in the current record
    return searchInElement(record);
  });

  const TagsCellRenderer = (data) => {
    const tags = data?.value?.map((tag) => (
      <span
        key={tag._id}
        style={{
          backgroundColor: tag.color,
          margin: "2px",
          color: "#fff",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          fontWeight: 700,
          fontSize: "85%",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        {tag.name}
      </span>
    ));

    return (
      <div style={{ display: "flex", flexWrap: "wrap", width: "auto" }}>
        {tags}
      </div>
    );
  };
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const numberOfLines = data?.complete_pickup_address
      ? Math.ceil(data?.complete_pickup_address?.length / 40)
      : 0;
    const addressHeight = rowHeight + numberOfLines * 30 + 20;
    if (data?.tags && data?.tags.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data.tags.length;
      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }
    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };
  // };
  const openNewTab = ({ id }) => {
    if (isButtonDisabled) {
      toast.info("Please wait for a second to open this job");
      return;
    }
    setIsButtonDisabled(true);
    window.open(`/jobs/update/preview/${id}`, "_blank");
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
  };
  const receiveMessage = (event) => {
    if (event?.data?.action === "vendor_id_confirm") {
      if (document.visibilityState === "visible") {
        setVicidialId(event?.data?.vicidial_id);
      }
    }
  };

  window.addEventListener("message", receiveMessage);
  React.useEffect(() => {
    if (vicidialId && jobDetail._id && jobDetail?.vendor_id?._id) {
      dispatch(
        job_call_logs({
          lead_id: jobDetail._id,
          type: "Tech",
          tech_user_id: jobDetail?.vendor_id?._id,
          vicidial_id: vicidialId,
          call_type: "Outgoing",
        })
      );
      setJobDetail(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vicidialId]);
  const callOn = async (number, lead_num) => {
    if (dialer?.status === "PAUSE") {
      try {
        const res = await dispatch(call_by_link(number));
        if (res?.payload?.status === 200) {
          toast.success("Call Successfully");
          setTimeout(() => {
            sendIframeMsg({
              action: "send_vendor_lead_code",
              user: user?.dialer_data?.dialer_user,
              pass: user?.dialer_data?.dialer_pass,
              vendor_lead_code: lead_num,
            });
          }, 5000); // 5 seconds timeout
        }
      } catch (err) {
        console.error("🚀 ~ file: index.jsx:68 ~ callOn ~ err:", err);
      }
    } else if (dialer?.status === "") {
      toast.error("Please login to dialer");
    } else {
      toast.error("Please Pasue your dialer before call");
    }
  };

  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = jobs?.map((item) => ({
        _id: item._id,
        name: item.name,
      }));
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };

  const onSingleSelect = ({ checked, data }) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          if (!prevSelectedData?.some(({ _id }) => _id === data._id)) {
            return [...prevSelectedData, { _id: data._id, name: data.name }];
          }
          return prevSelectedData;
        });
      } else {
        setSelectedData((prevSelectedData) => {
          return prevSelectedData.filter(({ _id }) => _id !== data._id);
        });
      }
    } catch (err) {
      console.error("Error in onSingleSelect:", err);
    }
  };

  const isSelected = (data) => {
    return selectedData?.some(({ _id }) => _id === data._id);
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={jobs?.length && selectedData?.length === jobs?.length}
          disabled={!jobs?.length}
        />
      ),
      hideable:false,
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <div className="">
          {isSelected(params.row.records) && isLoading ? (
            <div>test</div>
          ) : (
            <input
              type="checkbox"
              checked={isSelected(params.row.records)}
              onChange={(e) =>
                onSingleSelect({
                  checked: e.target.checked,
                  data: params.row.records,
                })
              }
              className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            />
          )}
        </div>
      ),
      disableColumnMenu: true,
    },
    {
      field: "job_number",
      headerName: "Job #",
      renderCell: (params) => (
        <span
          onClick={() =>
            openNewTab({ id: params.row._id, jobNo: params.row.job_number })
          }
          className="text-blue-600 cursor-pointer hover:underline"
        >
          {params.row.job_number}
        </span>
      ),
      width: 60,
    },
    {
      headerName: "Created By",
      field: "createdBy",
      flex: 1,
      minWidth: 100,
      cellClassName: "multiline-cell",
      type: "singleSelect",
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) =>
        `${value?.first_name || ""} ${value?.last_name || ""} (${
          value?.username
        })`,
      valueOptions: usersDrd,
      renderCell: (params) => params.value?.createdBy,
      valueGetter: (params) => params.row?.createdBy,
      valueFormatter: (params) => params.value?.createdBy,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 120,
      cellClassName: "multiline-cell",
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.createdAt;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    {
      headerName: "Converted At",
      field: "convert_to_job_on",
      minWidth: 200,
      type: "date",
      valueGetter: (params) => new Date(params.row.convert_to_job_on),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.convert_to_job_on;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    { headerName: "Client", field: "name" },
    {
      field: "industry_id",
      headerName: "Industry",
      type: "singleSelect",
      flex: 1,
      minWidth: 100,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value?.name,
      valueOptions: industryDrd,
      renderCell: (params) => params.value?.industry_id,
      valueGetter: (params) => params.row?.industry_id,
      valueFormatter: (params) => params.value?.industry_id,
    },
    {
      headerName: "Site Name",
      field: "call_payload_domain",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Scheduled On",
      field: "scheduled_on",
      flex: 1,
      minWidth: 130,
      cellClassName: "multiline-cell",
      type: "date",
      valueGetter: (params) => new Date(params.row.scheduled_on),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.scheduled_on;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    { headerName: "Status", field: "job_status_id", filterable: false },
    {
      headerName: "Tags",
      field: "tags",
      type: "singleSelect",
      renderCell: TagsCellRenderer,
      flex: 1,
      minWidth: 200,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value?.name,
      valueOptions: tags.tagsDrd,
    },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 120 },
    {
      headerName: "Call Type",
      field: "call_type",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      filterable:false,
      headerName: "Tech",
      field: "vendor_id",
      minWidth: 150,
      cellClassName: "multiline-cell",
      type: "singleSelect",
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) =>
        `${value?.first_name || ""} ${value?.last_name || ""}`,
      valueOptions: CRMVendorsDrd,
      renderCell: (params) => params.value?.vendor_id,
      valueGetter: (params) => params.row?.vendor_id,
      valueFormatter: (params) => params.value?.vendor_id,
    },
    {
      headerName: "Vendor Card Dues",
      field: "job_vendor_card_due_amount",
      flex: 1,
      minWidth: 160,
      type: "number",
      align: "center",
      headerAlign: "left",
      renderCell: (params) => {
        return params.row.job_vendor_card_due_amount ? (
          <div className="w-full justify-cente fle flex-col">
            <strong className="text-center">
              Due Amount: ${params.row.job_vendor_card_due_amount}
            </strong>
            <div>
              {params?.row?.records?.vendor_id?.phone}
              <FaPhone
                className="ml-2 cursor-pointer"
                onClick={() => {
                  setJobDetail(params?.row?.records);
                  callOn(params?.row?.records?.vendor_id?.phone);
                }}
              />
            </div>
          </div>
        ) : null;
      },
    },
    {
      headerName: "Card Status",
      field: "virtual_cards_info.card_info.status",
      minWidth: 150,
      hideable:false,
      cellClassName: "multiline-cell",
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value?.label,
      valueOptions: [
        { label: "Frozen", value: "FROZEN" },
        { label: "Activated", value: "ACTIVATED" },
        { label: "Delete", value: "DELETED" },
      ],
      renderCell: (params) => params.value?.status,
      valueGetter: (params) => params.row?.status,
      valueFormatter: (params) => params.value?.status,
    },
    {
      headerName: "Job Category",
      field: "job_category_id",
      minWidth: 150,
      hideable:false,
      cellClassName: "multiline-cell",
      type: "singleSelect",
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value?.name,
      valueOptions: jobTypes?.record?.records,
      renderCell: (params) => params.value?.job_category_id,
      valueGetter: (params) => params.row?.job_category_id,
      valueFormatter: (params) => params.value?.job_category_id,
    },
    {
      headerName: "Address",
      field: "complete_pickup_address",
      width: 180,
      wrap: true,
      cellClassName: "multiline-cell",
    },
    { headerName: "Distance", field: "distance", type: "number" },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      renderCell: (params) => (
        <div>
          {userPermission("Update Job") && (
            <FaEdit
              onClick={() => {
                navigate(`/jobs/update/${params.row._id}`);
              }}
              className="my_navIcon"
              title="Update Job"
            />
          )}
        </div>
      ),
      width: 80,
    },
  ];
  // const isSelected = (val) => {
  //   if (selectedJob) {
  //     if (selectedJob === val._id) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  React.useEffect(() => {
    dispatch(get_industry_drd({ data: { page: 1, size: 1000 } }));
    // eslint-disable-next-line
  }, []);

  // React.useEffect(() => {
  //   dispatch(get_job_status_with_job_count());
  //   // eslint-disable-next-line
  // }, [selectedJob]);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getSERPReport({
      ...sortingModel,
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      const newRule = selectedJob
        ? [
            {
              field: "job_status_id",
              op: "eq",
              data: selectedJob,
            },
          ]
        : [];

      const payload = {
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
        filters: {
          ...queryOptions,
          rules: newRule,
        },
      };
      getSERPReport(payload);
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });

    const newRule = selectedJob
      ? {
          field: "job_status_id",
          op: "eq",
          data: selectedJob,
        }
      : null;

    const updatedRules = newRule
      ? (queryOptions?.rules || [])
          .filter((rule) => rule.field !== "job_status_id")
          .concat(newRule)
      : queryOptions?.rules || [];

    const payload = {
      ...sortingModel,
      page: 1,
      size: paginationModel.pageSize,
      filters: {
        ...queryOptions,
        rules: updatedRules,
      },
    };

    getSERPReport(payload);
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={updateJobStatus}
          startIcon={<MdAddCircleOutline className="h-5 w-5" />}
          sx={{
            fontSize: "0.8125rem",
            color: !selectedData.length ? "#6B7280" : "#042a42",
            pointerEvents: !selectedData.length ? "none" : "auto",
          }}
          disabled={!selectedData.length}
        >
          Update Job Status
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    getSERPReport({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };
  const findParentObjectById = (arr, id) => {
    for (let obj of arr) {
      if (findObjectById(obj, id)) {
        return obj;
      }
    }
    return null;
  };

  const findObjectById = (obj, id) => {
    if (obj._id === id) {
      return obj;
    }
    if (obj.child) {
      for (let child of obj.child) {
        const result = findObjectById(child, id);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };
  const handleDropdownChange = (value) => {
    setSelectedJob(value);
    if (jobStatusId) {
      navigate(`/jobs`);
    }
    // dispatch(get_jobs_data({ job_status_id: _id }));
  };

  const cancelFormHandler = () => {
    setJobStatusModal(false);
  };

  const onFormSubmit = () => {
    setJobStatusModal(false);
    setSelectedData([]);
    getSERPReport({
      filters: queryOptions,
      page: paginationModel.page,
      size: paginationModel.pageSize,
    });
  };

  const updateJobStatus = async () => {
    if (selectedData?.length) {
      setJobStatusModal(true);
    } else {
      toast.error("Please select at least one Job.");
    }
  };
  const desiredOrder = ["Open Job", "Job In Progress", "Closed"];
  function reorderStatus(array) {
    if (!array) return [];
    return [...array]?.sort(
      (a, b) => desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)
    );
  }
  return (
    <>
      <PageHeader
        route="Setting > Industries"
        heading="Job Listing"
        onClick={() => navigate("/leads/add")}
        isAllowed={userPermission("Add Lead")}
      />
      {isLoading ? <DotsLoader /> : null}
      {isJobStatusModal ? (
        <JobStatusModal
          modalTitle="Update Job Status"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
          methodType=""
          onFormSubmit={onFormSubmit}
          jobStatus={jobStatus?.jobStatusesNew}
        />
      ) : null}
      {jobStatus?.jobStatusesNew?.length > 0 ? (
        <div className="historyTabs statsTabs mb-4">
          <div className="flex flex-wrap mt-2 gap-x-2 border-b-2 pb-2">
            {reorderStatus(jobStatus?.jobStatusesNew)?.map((item, index) => {
              const { _id, name = "", totalJobCount = 0 } = item;
              const selectedParentStatus = findParentObjectById(
                jobStatus?.jobStatusesNew,
                selectedJob
              );
              const isParentSelected =
                selectedParentStatus?._id === _id || isParent === _id;
              return (
                <div key={index} className={`bg-white`}>
                  <div className="">
                    <div className="flex flex-wrap items-center text-sm">
                      <div
                        className={`text-start w-full border flex justify-between items-center cursor-pointer hover:shadow-md font-medium font-pop pl-2 ${
                          isParentSelected
                            ? "bg-primary-100 !border-primary-100 text-white"
                            : ""
                        }`}
                        onClick={() => setIsParent(_id)}
                      >
                        <span>{`${name} - ${totalJobCount}`}</span>
                        <div className="!ml-3 flex items-center">
                          {isParentSelected ? (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsParent(null);
                                setSelectedJob(null);
                              }}
                              className="flex items-center"
                            >
                              <IoMdClose />
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <CustomDropdown
                  isParentSelected={isParentSelected}
                  options={child}
                  placeholder="Select status"
                  value={selectedJob}
                  onChange={handleDropdownChange}
                  name={name}
                  totalJobCount={totalJobCount}
                  selectedChildStatus={selectedChildStatus}
                  selectedJob={selectedJob}
                  setSelectedJob={setSelectedJob}
                /> */}
                </div>
              );
            })}
          </div>
          {/* <hr className="mt-1"/> */}
          <div className="flex flex-wrap items-cente text-sm font-pop !mt-3 gap-2">
            {jobStatus?.jobStatusesNew
              ?.find(({ _id }) => _id === isParent)
              ?.child.map((childItem, childIndex) => {
                return childItem?.jobCount ? (
                  <button
                    key={childIndex}
                    className={`text-cente border py-0.5 px-2 text-xs ${
                      selectedJob === childItem?._id
                        ? "b text-white bg-menu "
                        : ""
                    }`}
                    onClick={() => handleDropdownChange(childItem?._id)}
                  >
                    {`${childItem?.name} (${childItem?.jobCount})`}
                  </button>
                ) : null;
              })}
          </div>
        </div>
      ) : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={jobs?.map((record, index) => {
            const {
              _id,
              job_number,
              name,
              phone,
              job_category_id,
              createdBy,
              createdAt,
              complete_pickup_address,
              scheduled_on,
              vendor_id,
              tags,
              job_status_id,
              industry_id,
              call_type,
              distance,
              job_vendor_card_due_amount,
              convert_to_job_on,
              call_payload_domain
            } = record;
            return {
              _id,
              counter: index + 1,
              records: record,
              job_number,
              job_category_id: job_category_id?.name,
              createdBy: createdBy?.username,
              name,
              call_payload_domain,
              call_type,
              phone,
              convert_to_job_on: getFormattedDate(convert_to_job_on),
              job_status_id: job_status_id?.name,
              createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
              scheduled_on:
                scheduled_on &&
                dayjs(scheduled_on).format("ddd, MMM D, YYYY h:mm A"),
              industry_id: industry_id?.name,
              vendor_id: `${
                vendor_id?.first_name ? vendor_id?.first_name : ""
              } ${vendor_id?.last_name ? vendor_id?.last_name : ""}`,
              complete_pickup_address,
              tags,
              job_vendor_card_due_amount,
              distance
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          totalItems={record?.totalItems}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          gridOptions={getRowHeight}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
        />
      </div>
    </>
  );
}

export default JobListing;
