import React, { useState } from "react";
import { FaChevronDown, FaEdit, FaPlus } from "react-icons/fa";
import { Button, DotsLoader, FormSelectInput } from "../../../components";
import { toast } from "react-toastify";
import InputForm from "./InputForms";
import errorMessage from "../../../util/errorMessage";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { apiCalling } from "../../../services";
import {
  addSettingEvents,
  getSettingEvents,
  updateSettingEvents,
} from "../../../services/generalSettingService";
import { Button as MUIButton } from "@material-ui/core";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import TagsInput from "../../../components/atoms/CustomTagsInput";
import { useEffect } from "react";
function EventsSection() {
  let initialValues = {
    event_name: "",
    event_alias: "",
    sms_participants: [],
    email_participants: [],
    sms_type: "",
  };
  const [eventsData, setEventsData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [eventPayload, setEventPayload] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const SMSCellRenderer = (data) => {
    const tags = data?.value?.map((tag, index) => (
      <span
        key={tag}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
          // backgroundColor:"#f5f5f5"
        }}
      >
        {index + 1}. {tag}
      </span>
    ));

    return <div className="flex flex-col w-auto">{tags}</div>;
  };
  const EmailCellRenderer = (data) => {
    const tags = data?.value?.map((tag, index) => (
      <span
        key={tag}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
          // backgroundColor:"#f5f5f5"
        }}
      >
        {index + 1}. {tag}
      </span>
    ));

    return <div className="flex flex-col w-auto">{tags}</div>;
  };
  const pauseCodesColumns = [
    { headerName: "#", field: "counter", width: 60 },
    { headerName: "Event Name", field: "event_name", flex: 1, maxWidth: 300 },
    {
      headerName: "Event Alias",
      field: "event_alias",
      flex: 1,
      width: 300,
    },
    {
      headerName: "SMS Type",
      field: "sms_type",
      flex: 1,
      maxWidth: 150,
    },
    {
      headerName: "SMS Participants",
      field: "sms_participants",
      renderCell: SMSCellRenderer,
      flex: 1,
      maxWidth: 300,
    },
    {
      headerName: "Email Participants",
      field: "email_participants",
      renderCell: EmailCellRenderer,
      flex: 1,
      maxWidth: 300,
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon"
            title="Update Event"
          />
        </div>
      ),
      width: 100,
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventPayload({
      ...eventPayload,
      [name]: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const action = eventPayload?._id ? updateSettingEvents : addSettingEvents;
    const message = eventPayload?._id ? "updated" : "created";
    if (eventPayload?._id) {
      delete eventPayload.event_alias;
    }
    setIsLoading(true);
    try {
      const res = await action(eventPayload);
      if (res.status === 200) {
        toast.success(`Event successfully ${message}`);
        if (eventPayload?._id) {
          const result = eventsData.findIndex(
            ({ _id }) => _id === res.data?._id
          );
          if (result !== -1) {
            // Create a copy of the updated record
            const updatedRecord = {
              ...eventsData[result],
              ...res.data,
            };

            // Create a copy of the eventsData array with the updated record
            const updatedData = [...eventsData];
            updatedData[result] = updatedRecord;

            // Update the state with the modified array
            setEventsData(updatedData);
          }
        } else {
          setEventsData([...eventsData, res.data]);
        }
        setEventPayload(initialValues);
        cancelFormHandler();
      } else {
        errorMessage({
          payload: res.payload,
          action: "Event",
          msg: message,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Error ~ file: AddUpdateForm.jsx ~ line 59 ~ onSubmit: ~ error",
        error
      );
      errorMessage({
        payload: error,
        action: "Event",
        msg: message,
      });
    }
  };
  const cancelFormHandler = () => {
    setEventPayload(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEventPayload(record);
    setIsEditing(true);
  };
  useEffect(() => {
    const getSettingEvent = async () => {
      setIsLoading(true);
      try {
        const res = await apiCalling({ action: getSettingEvents });
        if (res.status === 200) {
          setEventsData(res.data);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(
          "🚀 ~ file: Events.jsx:94 ~ getSettingEvent ~ error:",
          error
        );
      }
    };
    getSettingEvent();
  }, []);
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const record =
      data?.sms_participants?.length > data?.email_participants?.length
        ? data?.sms_participants
        : data?.email_participants;
    if (record && record?.length > 1) {
      const tagHeight = 20; // height of each tag
      const numTags = record?.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <MUIButton
          variant="text"
          onClick={openFormHandler(initialValues)}
          startIcon={<FaPlus size={16} />}
          // sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Add New
        </MUIButton>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
      </GridToolbarContainer>
    );
  }
  return (
    <details className="border" open>
      <summary className="flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer  bg-gray-100 hover:bg-gray-300 py-2 px-1">
        <span>Events</span>
        <FaChevronDown iconClass="fas fa-times" />
      </summary>
      <div className="flex flex-col p-2">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <div className="w-full gap-x-2 mt-2">
                {isEditing ? (
                  <>
                    {isLoading ? <DotsLoader /> : null}
                    <div className="flex flex-row space-x-2 md:mt-md-2">
                      <div className="w-full mb-3">
                        <InputForm
                          name="event_name"
                          placeholder="Event Name"
                          onChange={(e) => handleInputChange(e)}
                          value={eventPayload?.event_name}
                          type="text"
                        />
                      </div>
                      {eventPayload?._id ? null : (
                        <div className="w-full mb-3">
                          <InputForm
                            name="event_alias"
                            placeholder="Event Alias"
                            onChange={(e) => handleInputChange(e)}
                            value={eventPayload?.event_alias}
                            type="text"
                          />
                        </div>
                      )}
                      <div className="w-full mb-3">
                        <FormSelectInput
                          name="sms_type"
                          placeholder="SMS Type"
                          onChange={(e) =>
                            setEventPayload({ ...eventPayload, sms_type: e })
                          }
                          value={eventPayload?.sms_type}
                          options={[
                            { value: "Inteliquent", label: "Inteliquent" },
                            { value: "Twilio", label: "Twilio" },
                          ]}
                          valueProp="value"
                          labelProp="label"
                          label="SMS Type"
                        />
                      </div>
                    </div>
                    <div className="w-full mb-3">
                      <TagsInput
                        tags={eventPayload.sms_participants}
                        setTags={(e) =>
                          setEventPayload({
                            ...eventPayload,
                            sms_participants: e,
                          })
                        }
                        phoneInput
                        placeholder="Add Phone Number"
                        label="Participant Phone Number"
                        isFloat
                      />
                    </div>

                    <div className="w-full mb-3">
                      <TagsInput
                        tags={eventPayload.email_participants}
                        setTags={(e) =>
                          setEventPayload({
                            ...eventPayload,
                            email_participants: e,
                          })
                        }
                        placeholder="Add Email"
                        label="Participant Emails"
                        isFloat
                      />
                    </div>
                    <div className="w-full flex space-x-2 justify-end items-center">
                      <Button
                        text="Cancel"
                        variant="btn_cancel"
                        onClick={cancelFormHandler}
                      />
                      <Button
                        text="Save"
                        variant="btn_submit"
                        onClick={handleSubmit}
                      />
                    </div>
                  </>
                ) : (
                  <div className="bg-white rounded w-full">
                    <MUIDataTable
                      items={eventsData?.map((record, index) => {
                        let counter = index + 1;
                        let records = record;
                        const {
                          event_name,
                          event_alias,
                          sms_participants,
                          email_participants,
                          sms_type,
                        } = record;
                        return {
                          counter,
                          records,
                          event_name,
                          event_alias,
                          sms_participants,
                          email_participants,
                          sms_type,
                        };
                      })}
                      columnDefs={pauseCodesColumns}
                      pagination="No"
                      height="46vh"
                      searchable="No"
                      displayCount="No"
                      CustomToolbar={CustomToolbar}
                      isLoading={isLoading}
                      gridOptions={getRowHeight}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </details>
  );
}

export default EventsSection;
