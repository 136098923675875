import React, { useState } from "react";
import { FaPaperclip } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";


const EmailComposerModal = ({ isOpen, closeModal, handleSubmit, fileChange, selectedFiles, ...props }) => {
    const [isCCVisible, setIsCCVisible] = useState(false);  // For CC field visibility
    const [isBCCVisible, setIsBCCVisible] = useState(false);  // For BCC field visibility
    if (!isOpen) return null; // Don't render if the modal is not open

    return (
        <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
            <div className="relative">
                <div
                    className={`relat z-10 w-auto w-full md:max-w-[1524px] md:min-w-[1090px] max-h-[calc(250vh-30px)] overflow-y-auto bg-white  border-white ${props.modalClass}`}
                >

                    <div className={`pt-0 h-full m-auto ${props.modalBodyClass}`}>
                        <div
                            onClick={closeModal}
                        >
                            <div
                                className="bg-white rounded-lg w-300"
                                onClick={(e) => e.stopPropagation()} // Prevent closing modal on inside click
                            >
                                {!props.hideHeader && (
                                    <header
                                        className={`bg-[#f7f7f7] px-4 py-2 flex justify-between items-center border-b border-[#bbb2b2]`}
                                    >
                                        <span className="material-icons-outlined text-black">
                                            {props.title}
                                        </span>
                                        <div>
                                            <button onClick={() => closeModal()}>
                                                <span className="material-icons-outlined text-menu">
                                                    <AiOutlineCloseCircle size={25} />
                                                </span>
                                            </button>
                                        </div>
                                    </header>
                                )}
                                <form onSubmit={handleSubmit} >
                                    {/* To Field */}
                                    <div className="mb-2 pt-4">
                                        <div className="flex justify-between pl-4 pr-4">
                                            <label htmlFor="to" className="block text-gray-600">To</label>
                                            <div className="flex justify-between">
                                                <label className="block pr-2 text-gray-600 cursor-pointer" onClick={() => setIsCCVisible(true)}>Cc</label>
                                                <label className="block text-gray-600 cursor-pointer" onClick={() => setIsBCCVisible(true)}>Bcc</label>
                                            </div>
                                        </div>
                                        <input
                                            type="email"
                                            name="to"
                                            id="to"
                                            className="w-full border-b-2 border-gray-300 focus:border-blue-500 p-2 outline-none pl-4 pr-4"
                                            required
                                            placeholder="Recipient Email"
                                        />
                                    </div>

                                    {/* CC Field */}
                                    {isCCVisible && (<div className="mb-2 pt-2">
                                        <label htmlFor="cc" className="block text-gray-600 pl-4 pr-4">Cc</label>
                                        <input
                                            type="email"
                                            name="cc"
                                            id="cc"
                                            className="w-full border-b-2 border-gray-300 focus:border-blue-500  pl-4 pr-4 outline-none"
                                            placeholder="Cc Email"
                                        />
                                    </div>)}

                                    {/* BCC Field */}
                                    {isBCCVisible && (<div className="mb-2 pt-2">
                                        <label htmlFor="cc" className="block text-gray-600 pl-4 pr-4">Bcc</label>
                                        <input
                                            type="email"
                                            name="bcc"
                                            id="bcc"
                                            className="w-full border-b-2 border-gray-300 focus:border-blue-500  pl-4 pr-4 outline-none"
                                            placeholder="Bcc Email"
                                        />
                                    </div>)}

                                    {/* Subject Field */}
                                    <div className="mb-2 pt-2">
                                        <label htmlFor="subject" className="block text-gray-600 pl-4 pr-4">Subject</label>
                                        <input
                                            type="text"
                                            name="subject"
                                            id="subject"
                                            className="w-full border-b-2 border-gray-300 focus:border-blue-500  pl-4 pr-4 outline-none"
                                            required
                                            placeholder="Subject"
                                        />
                                    </div>

                                    {/* Description Field */}
                                    <div className="mb-2 pt-2">
                                        <textarea
                                            name="description"
                                            id="description"
                                            className="w-full border-b-2 border-gray-300 focus:border-blue-500 pl-4 pr-4 outline-none"
                                            required
                                            rows="6"
                                            placeholder="Write your email here..."
                                        />
                                    </div>

                                    {/* Buttons */}
                                    <div className="flex justify-between pb-4">
                                        <div className="flex justify-between pl-4">
                                            <label htmlFor="attachment" className="block pt-1 text-gray-600">Attachment:</label>
                                            {/* Hidden File Input */}
                                            <input
                                                type="file"
                                                name="attachment"
                                                id="attachment"
                                                className="hidden"
                                                multiple
                                                onChange={fileChange}
                                            />
                                            {/* Icon that triggers the file input */}
                                            <label
                                                htmlFor="attachment"
                                                className="cursor-pointer text-blue-500 hover:text-blue-600"
                                            >
                                                <FaPaperclip className="w-6 h-6" /></label>
                                            {/* Displaying the selected files */}
                                            {selectedFiles.length > 0 && (
                                                <div className="mt-4">
                                                    <ul className="list-none flex pl-5">
                                                        {selectedFiles.map((file, index) => (
                                                            <li key={index} className="mb-2 pr-1">
                                                                {/* Display image preview if the file is an image */}
                                                                {file.type.startsWith('image') && (
                                                                    <div className="mt-2">
                                                                        <img
                                                                            src={URL.createObjectURL(file)}
                                                                            alt={file.name}
                                                                            className="max-w-full max-h-12 rounded-lg mt-2"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                        <button
                                            type="submit"
                                            className="h-[40px] bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 mr-4"
                                        >
                                            Send
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EmailComposerModal;
