import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedEvent,
  setShowEventModal,
} from "../../../../features/calendarEventSlice";
import { getFullName } from "../../../../util/common";

function Days({ events }) {
  const times = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23,
  ];
  const { daySelected } = useSelector((state) => state?.calendarEvents);
  const dispatch = useDispatch();
  const format = "DD-MM-YY";
  const todayJobs = events?.filter(
    (job) =>
      dayjs(job?.start?.dateTime)?.format(format) ===
      dayjs(daySelected).format(format)
  );
  const transformedEvents = todayJobs?.reduce((acc, event) => {
    const eventHour = new Date(event?.start?.dateTime).getHours();
    if (acc[eventHour]) {
      acc[eventHour].push(event);
    } else {
      acc[eventHour] = [event];
    }
    return acc;
  }, {});
  return (
    <div className="flex-1 w-full px-2 border border-gray-200">
      <div className="flex flex-row text-lg font-semibold items-center justify-center p-1 mt-1 rounded cursor-pointr">
        <span className="text-gray-500 font-sans text-center">
          There are {todayJobs ? todayJobs?.length : 0} events found for{" "}
          {dayjs(daySelected)?.format("ddd, MMM D, YYYY")}
        </span>
      </div>

      {times?.map((time) => {
        const formattedTime = moment().set("hours", time).format("h a");
        return (
          <div className="flex flex-row w-full">
            <div className="text-[12px] text-[#212121] text-right p-[6px] block box-border w-[10%]">
              <span>{formattedTime}</span>
            </div>
            <div className="border border-[#e0e0e0] w-full flex flex-col gap-2 h-auto min-h-[40px] p-1">
              {todayJobs &&
                transformedEvents[time] &&
                transformedEvents[time]?.map((event) => {
                  const status = event?.metaData?.status || null;
                  const statusColor =
                    status === "Pending"
                      ? "!bg-primary-100"
                      : status === "Missed"
                      ? "!bg-red-600"
                      : "!bg-[#16a34a]";
                  return (
                    <div
                      className="cursor-pointer !w-auto h-auto flex items-center rounded-sm text-black py-[2px] px-[4px] text-[12px]"
                      onClick={() => {
                        dispatch(setSelectedEvent(event));
                        dispatch(setShowEventModal(true));
                      }}
                    >
                      {event?.metaData?.status ? (
                        <div
                          className={`${statusColor} w-5 h-5 mr-2 rounded-full border border-[#c7d2fe]`}
                        />
                      ) : null}
                      <span className="bg-[#c7d2fe] border border-[#818cf8] px-1">
                        {event.summary}
                        {" | "}
                        {event?.location || ""}
                        {" | "}
                        {moment(event?.start?.dateTime).format(
                          "hh:mm a"
                        )} -{" "}
                        {moment(
                          event?.end?.dateTime || event?.start?.dateTime
                        ).format("hh:mm a")}
                      </span>
                      {event?.lead?.client_information ? (
                        <span className="bg-[#c7d2fe] border border-[#818cf8] ml-1.5 px-1">
                          {getFullName(event?.lead?.client_information)}
                        </span>
                      ) : null}
                      {event?.job_status_id ? (
                        <span className="bg-[#c7d2fe] border border-[#818cf8] ml-1.5 px-1">
                          {event?.job_status_id?.name}
                        </span>
                      ) : null}
                      {event?.description ? (
                        <span className="bg-[#c7d2fe] border border-[#818cf8] ml-1.5 px-1">
                          {event?.description}
                        </span>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default Days;
