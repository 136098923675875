import React from "react";
import { BsFillReplyFill, BsFillPersonFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { marked } from "marked";
import PageHeader from "../../components/molecules/PageHeader";
const MailDetail = () => {
  const location = useLocation();
  const selectedEmail = location?.state?.selectedEmail;
  const preprocessContent = (content) => {
    if (!content) {
      return content;
    }
    const lines = content.split("\n");
    const urlLineRegex = /\bhttps?:\/\/[^\s]+/g;
    const processedLines = lines.map((line) => {
      const match = line.match(urlLineRegex);
      if (match) {
        const url = match[0];
        const domain = url.replace(/https?:\/\/(www\.)?/, "").split("/")[0];
        return `[${domain}](${url})`;
      }
      return line;
    });
    return processedLines.join("\n");
  };

  const renderContent = (content) => {
    if (!content) {
      return "";
    }
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(content);
    if (isHTML) {
      return content
    }
    // const isMarkdown2 =
    //   /(^#|[-*_]{3,}|!\[.*\]\(.*\)|\*|\n|\[[^\]]+\]\([^\)]+\))/.test(content);
      const isMarkdown =
      /(^#|[-*_]{3,}|!\[.*\]\(.*\)|\*|\n|\[[^\]]+\]\([^)]+\))/.test(content);
    if (isMarkdown) {
      const preprocessedContent = preprocessContent(content);
      const htmlContent = marked(preprocessedContent);
      return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    }
    return <pre>{content}</pre>;
  };
  function decodeBase64UrlSafe(base64String) {
    let normalizedBase64 = base64String.replace(/-/g, "+").replace(/_/g, "/");
    while (normalizedBase64.length % 4 !== 0) {
      normalizedBase64 += "=";
    }
    try {
      const byteArray = Uint8Array.from(atob(normalizedBase64), (c) =>
        c.charCodeAt(0)
      );
      const finalResult = new TextDecoder("utf-8").decode(byteArray);
      return finalResult;
    } catch (e) {
      console.error("Invalid Base64 input:", e);
      return null;
    }
  }
  return (
    <>
      <PageHeader heading="" />
      <div className="mt-4 h-[calc(100vh-155px)] overflow-y-scroll">
        <div className="h-full p-4 bg-neutral-100">
          <div className="flex justify-between items-center">
            <h4>
              {selectedEmail && selectedEmail.subject
                ? selectedEmail.subject
                : ""}
            </h4>
            <div className="flex gap-2">
              <span>
                {" "}
                {selectedEmail && selectedEmail.time
                  ? dayjs(selectedEmail.time).format("ddd, MMM D, YYYY h:mm A")
                  : ""}
              </span>
              <button>
                <BsFillReplyFill />
              </button>
            </div>
          </div>
          <div className="flex gap-3 mt-3">
            <div className="bg-neutral-500 w-[25px] h-[25px] rounded-full text-white flex justify-center items-center">
              <BsFillPersonFill />
            </div>
            <h5>
              {" "}
              {selectedEmail && selectedEmail.from ? selectedEmail.from : ""}
            </h5>
          </div>
          <div className="p-2 mt-3">
            <div className="">
              <iframe
                srcDoc={renderContent(
                  decodeBase64UrlSafe(selectedEmail.mailcontent)
                )}
                className="w-full h-[calc(100vh-280px)] border-none"
                sandbox="allow-same-origin allow-scripts allow-popups"
                title="Email Content"
              />
              {/* <div className="email-content">
                {renderContent(decodeBase64UrlSafe(selectedEmail.mailcontent))}

                {selectedEmail && selectedEmail.mailcontent
                ? selectedEmail.mailcontent
                : ""}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MailDetail;
