import React, { useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";

const AddupdateTaskList = ({
  editingRecord,
  cancelFormHandler,
  setTaskLists,
  setIsLoading,
}) => {
  const initialValues = {
    title: "",
    ...(editingRecord && {
      id: editingRecord.id,
      title: editingRecord.title,
    }),
  };
  const [payload, setPayload] = useState(initialValues);
  const addTaskList = async () => {
    const msg = editingRecord ? "updated" : "added";
    try {
      setIsLoading(true);
      const action = editingRecord
        ? api.put(`/api/google_task/task_list/${payload?.id}`, {
            title: payload.title,
          })
        : api.post(`/api/google_task/task_list`, payload);
      const response = await action;
      if (response.status === 200 || response.status === 201) {
        setPayload(null);
        setTaskLists((prevData) => {
          const updatedTaskLists = editingRecord
            ? prevData.taskLists.map((task) =>
                task.id === editingRecord.id ? response.data : task
              ) // Replace the task with the updated one
            : [...prevData.taskLists, response.data]; // Add the new task

          return {
            ...prevData,
            taskLists: updatedTaskLists,
            totalTaskLists: editingRecord
              ? prevData.totalTaskLists // Do not increment if editing
              : prevData.totalTaskLists + 1,
          };
        });
        toast.success(`Task list ${msg} successfully`);
        cancelFormHandler();
      } else {
        toast.error(response?.data?.message || `Failed to ${msg} task list`);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || `Failed to ${msg} task list`
      );
      console.error("Error adding task list:", error);
    }
    setIsLoading(false);
  };
  return (
    <div className="absolute top-0 bottom-0 right-0 left-0 bg-black/60 z-[1000] flex justify-center items-center">
      <div className="border rounded-lg shadow-md bg-white h-48 w-72 !p-4 flex flex-col justify-between">
        <h2 className="text-lg font-semibold text-gray-700 mb-2">
          Create new list
        </h2>
        <div className="flex w-full mt-">
          <input
            type="text"
            value={payload?.title}
            onChange={(e) => setPayload({ ...payload, title: e.target.value })}
            placeholder="Enter name"
            className="flex-1 px-4 py-2 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div className="flex justify-end items-center mt-">
          <button
            onClick={cancelFormHandler}
            className="hover:bg-[#0b57d014] text-[#0b57d0] px-2 py-1 rounded-full"
          >
            Cancel
          </button>
          <button
            onClick={addTaskList}
            className="hover:bg-[#0b57d014] text-[#0b57d0] px-2 py-1 rounded-full"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddupdateTaskList;
