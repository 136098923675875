import React from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import api from "../../services/api";
import PageHeader from "../../components/molecules/PageHeader";
import { Button, DotsLoader, FormInput } from "../../components";

const GoogleMails = () => {
  const getTimezoneString = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  };
  const initalData = {
    subjectText: null,
    fromEmail: null,
    numberOfMails: null,
    emailDate: dayjs(new Date()).format("YYYY-MM-DD"),
    timeZone: getTimezoneString(),
  };
  const [isLoader, setIsLoader] = React.useState(false);
  const [emailList, setEmailList] = React.useState([]);
  const [selectedPayload, setSelectedPayload] = React.useState(initalData);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedPayload({ ...selectedPayload, [name]: value });
  };
  const get_docusign_template_list = async (filter) => {
    setIsLoader(true);
    try {
      const res = await api.post("/api/google_email/email_list", filter);
      if (res.status === 200) {
        console.log(res);
        setEmailList(Array.isArray(res.data) ? res.data : []);
      }
      setIsLoader(false);
    } catch (err) {
      console.log(err);
      setIsLoader(false);
    }
  };
  React.useEffect(() => {
    get_docusign_template_list(selectedPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inboxDetails = (data) => {
    if (data && !data.isRead) {
      const payload = {
        message_id: data.message_id,
      };
      try {
        api.post("/api/google_email/mark_email_as_read", payload);
      } catch (err) {
        console.log(err);
      }
    }
    navigate(`/google_mails/${data.message_id}`, {
      state: {
        selectedEmail: data,
      },
    });
  };
  const onReset = () => {
    setSelectedPayload(initalData);
    get_docusign_template_list(initalData);
  };
  function decodeBase64UrlSafe(base64String) {
    let normalizedBase64 = base64String.replace(/-/g, "+").replace(/_/g, "/");
    while (normalizedBase64.length % 4 !== 0) {
      normalizedBase64 += "=";
    }
    try {
      const byteArray = Uint8Array.from(atob(normalizedBase64), (c) =>
        c.charCodeAt(0)
      );
      return new TextDecoder("utf-8").decode(byteArray);
    } catch (e) {
      console.error("Invalid Base64 input:", e);
      return null;
    }
  }
  const renderContent = (content) => {
    if (!content) return "";
    content = content.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "");
    content = content.replace(/<script[^>]*>[\s\S]*?<\/script>/gi, "");
    content = content.replace(/<[^>]+>/g, "");
    return content.trim();
  };
  return (
    <>
      <PageHeader heading="" />
      {isLoader && <DotsLoader />}
      <div>
        <div className="h-ful !p-4 bg-neutral-100 overflow-x-auto">
          <table className="table-auto text-black w-full">
            <tr>
              <td colSpan="" className="font-semibold border-0 px-0">
                <div className="flex justify-between">
                  <div className="grid grid-cols-6 gap-x-2 flex items-center">
                    <FormInput
                      placeholder={"Subject Text"}
                      onChange={handleChange}
                      name="subjectText"
                      value={selectedPayload?.subjectText}
                    />
                    <FormInput
                      placeholder={"From Email"}
                      onChange={handleChange}
                      name="fromEmail"
                      value={selectedPayload?.fromEmail}
                    />
                    <FormInput
                      placeholder={"No. of Mails"}
                      onChange={handleChange}
                      name="numberOfMails"
                      value={selectedPayload?.numberOfMails}
                    />
                    <FormInput
                      placeholder={"Email Date"}
                      onChange={handleChange}
                      name="emailDate"
                      value={selectedPayload?.emailDate}
                      type={"date"}
                    />
                    <div className="col-span-2">
                      <Button
                        text={"Search"}
                        className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
                        onClick={() =>
                          get_docusign_template_list(selectedPayload)
                        }
                      />
                      <Button
                        text="Reset"
                        className="py-1.5 px-3 align-middle bg-menu text-white"
                        onClick={onReset}
                      />
                    </div>
                  </div>
                  {/* <div className="flex gap-2">
                    <button>
                      <BsFillTrashFill />
                    </button>
                    <button>
                      <BsArrowClockwise />
                    </button>
                  </div> */}
                </div>
              </td>
            </tr>
            {emailList?.length === 0 ? (
              <tr className="text-center border-0">
                <td className="text-center border-0">
                  <strong>No Record found</strong>
                </td>
              </tr>
            ) : null}
            {emailList?.map((item) => {
              return emailList?.length ? (
                <tr
                  className={`overflow-x-auto text-black flex cursor-pointer items-center hover:bg-whiten dark:hover:bg-boxdark-2 border-b border-neutral-300 shadow-sm ${
                    item?.isRead ? "bg-white" : "bg-neutral-200"
                  }`}
                  onClick={() => {
                    inboxDetails(item);
                  }}
                >
                  <td className=" py-2 pl-4 pr-4 lg:pl-10 border-0 w-fit">
                    <div className="w-[200px] flex cursor-pointer select-none items-center text-sm font-medium sm:text-base">
                      <span className="font-semibold ml-3 text-sm truncate text-black">
                        {item.from}
                      </span>
                    </div>
                  </td>
                  <td className="w-[calc(100vw-800px)] text-sm truncate flex py-2 pl-4 pr-1 lg:pl-10 border-0 text-black">
                    <span className="truncat font-medium text-nowrap mr-1">
                      {item?.subject}
                      {" - "}
                    </span>
                    <p className="truncate text-blac text-sm max">
                      {renderContent(decodeBase64UrlSafe(item?.mailcontent))}
                    </p>
                  </td>
                  <td className="min-w-[100px] py-2 pl-4 pr-4 lg:pr-10 border-0">
                    <p className="text-center text-xs xl:text-sm truncate">
                      {dayjs(item?.time).format("ddd, MMM D, YYYY h:mm A")}
                    </p>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>No Record found</td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};
export default GoogleMails;
